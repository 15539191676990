// import React from 'react';
// import styles from "./RequestForm.module.css";

// function RequestAccessPage() {
//   return (
//     <div className={styles.pageContainer}>
//       <h1 className={styles.heading}>Access Request</h1>
//       <p>If you need access to the page, please click the button below to request access.</p>
//       <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgMruMw5au6dOhqdVhNHqv1JURE9WNkZZNTk2OVRWMUJOMkxOMTBXSU9BTC4u" 
//          target="_blank" 
//          rel="noopener noreferrer">
//         <button className={styles.accessButton}>Request Access</button>
//       </a>
//     </div>
//   );
// }

// export default RequestAccessPage;

import React from 'react';
import './RequestForm.css';  // Importing regular CSS file

function RequestAccessPage() {
  return (
    <div className="requestForm-pageContainer">
      <h1 className="requestForm-heading">Access Request</h1>
      <p>If you need access to the page, please click the button below to request access.</p>
      {/* Directly use the class name */}
      <a href="https://forms.office.com/Pages/ResponsePage.aspx?id=gZqlGKjuMEyUitiCTNwlgMruMw5au6dOhqdVhNHqv1JURE9WNkZZNTk2OVRWMUJOMkxOMTBXSU9BTC4u"
         className="requestForm-accessButton"
         target="_blank"
         rel="noopener noreferrer">
        Request Access
      </a>
    </div>
  );
}
export default RequestAccessPage;

