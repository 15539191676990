import React from 'react';
import './BackToDraft.css';
import { IoIosReturnLeft } from "react-icons/io";
import { GrClose } from 'react-icons/gr';

function BackToDraft(props) {
    return (
        <div className="BackToDraft-modal-container">
            <div className="BackToDraft-modal-content">
                <div className="BackToDraft-close-button-container">
                    <button className="BackToDraft-close-button" onClick={props.onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className="BackToDraft-modal-header">
                    <h3>
                        Are you sure you want to <strong>send this conversation back to draft?</strong>
                    </h3>
                </div>
                <div className="BackToDraft-modal-body">
                    <p>
                        If you send this conversation back to draft, you will be able to make changes to the conversation again. However, this will <strong>reset the approval process</strong>. Once the necessary changes have been made, the review and approval process can be restarted. <strong>Are you sure you want to continue?</strong>
                    </p>
                    <div className="BackToDraft-buttons-container">
                        <button className="BackToDraft-button return" onClick={props.onClose}>
                            <GrClose size={22} /> Return to Conversation
                        </button>
                        <button
                            className="BackToDraft-button back-to-draft"
                            onClick={props.onSendBackToDraft}
                        >
                            <IoIosReturnLeft size={25} /> Send Back To Draft
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BackToDraft