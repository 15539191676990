import React from "react";
import "./OnDeleteElementPopUp.css";
import { GrClose } from "react-icons/gr";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaTrash } from "react-icons/fa";

function OnDeleteElementPopUp({ show, onClose, deleteElement }) {
  return (
    <div className={`custom-modal fade ${show ? "show" : ""}`}>
      <div className="custom-modal-content px-4 px-2" style={{ width: "680px", height:"310px" }}>
        <div className="btnDiv">
          <button className="close-button" onClick={onClose}>
            <GrClose size={25} />
          </button>
        </div>
        <div className="custom-modal-header mt-2">
          <div style={{fontSize:"32px"}}>
            <strong>Delete</strong> Element
          </div>
        </div>
        <div className="custom-modal-body d-flex flex-column align-items-center justify-content-center mx-4 pt-0">
          <div style={{fontSize:"24px"}} className="mt-2 mb-4">Do you want to <strong>permanently delete</strong> this element?</div>
          <div className="dltEleBtns d-flex" style={{ width: "-webkit-fill-available", justifyContent: "space-around" }}>
            <button
              className={`button returnbtn mt-2`}
              style={{ border: "2px solid #D52B1E" }}
            onClick={onClose}
            >
              <IoIosArrowRoundBack size={25} /> Return to Conversation
            </button>
            <button
              className={`button delete mt-2`}
              style={{ border: "2px solid #D52B1E" }}
            onClick={deleteElement}
            >
              <FaTrash size={18} style={{marginRight:"8px"}} /> Delete Element
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}

export default OnDeleteElementPopUp;
