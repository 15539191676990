import React, { useRef, useState, useEffect } from "react";
import "./MediaResponseNode.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { PiParagraphBold } from "react-icons/pi";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { RiLinkM } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { isNodeDraggableSlice, setIsNodeDraggable } from "../../../Redux/slicer/CreateSlicer";
import { MdUndo } from "react-icons/md";
const v4 = require('uuid').v4;


const buttonElement = ({index, elementsArray, handleElementChange, handleAddlink, handleDoubleClick, handleHoverElement, handleHoverOffElement, handleHoverOffButton, handleDeleteElement, handleToggleAddParaButton, handleUndoAction, handleAddElements, handleLinkChange}) => (
    <div key={`button_${index}`} className="media-response-element-container"
        onMouseEnter={() => handleHoverElement(index)}
        onMouseLeave={() => handleHoverOffElement(index)}
    >
        <div className="media-response-element-wrapper">
            <RxHamburgerMenu size={20} color="black" />
            <div className="media-element-button"
                onMouseEnter={() => handleHoverElement(index)}
            >
                <button className="external-link-btn">
                    <textarea rows="1" placeholder="External Link" className="media-response-button-textArea"
                        value={elementsArray[index].text} onChange={(event) => handleElementChange(event, index)} onDoubleClick={() => handleDoubleClick(index)}
                        readOnly={!elementsArray[index].doubleClicked}
                        width="auto"
                        style={{ overflow: "hidden" }}
                    ></textarea>
                </button>
            </div>
        </div>
        {(elementsArray[index].editing === true && !elementsArray[index].doubleClicked) || elementsArray[index].undoAction === true ?
            <>
                <div className="media-element-edit"
                    onMouseEnter={() => handleHoverElement(index)}
                >
                    <div className="icon-container">
                        <PiParagraphBold color="grey" />
                    </div>
                    <div className="add-container">
                        <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                        <button className={`add-btn${elementsArray[index].toggleAddButton === true ? " clicked" : ""}`} onClick={() => handleToggleAddParaButton(index)}>
                            {elementsArray[index].toggleAddButton === true ? <IoClose color="white" size={25} /> : <IoMdAdd color="white" size={25} />}
                        </button>
                        <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                        <div className="delete-container">
                            {elementsArray[index].undoAction === true ? <button className="delete-btn undo" onClick={() => handleUndoAction(index)}> <MdUndo color="white" size={25} /></button> :
                                <button className="delete-btn" onClick={() => handleDeleteElement(index)}>
                                    <MdDelete color="white" size={25} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="media-add-elements-area"
                    onMouseEnter={() => handleHoverElement(index)}
                >
                    {elementsArray[index].toggleAddButton === true && elementsArray[index].editing === true ? handleAddElements(handleHoverElement, index) : null}
                </div>
            </>
            :
            (elementsArray[index].doubleClicked === true || elementsArray[index].addingLink === true || elementsArray[index].editing === true ?
                <>
                    <div className="media-element-button-formatting"
                        onMouseEnter={() => handleHoverElement(index)}
                    >
                        <button className="btn-link-opt link-btn" onClick={() => handleAddlink(index)}
                            onMouseLeave={(() => elementsArray[index].addingLink === true ? null : handleHoverOffButton(index))}
                        > <RiLinkM color="white" /></button>
                    </div>
                    {
                        elementsArray[index].addingLink === true ?
                            <div className="media-element-link-container"
                            >
                                <div className="add-link-container">
                                    <p className="add-link-text">Link button to a website or other URL</p>
                                    <input type="text" onChange={(event) => handleLinkChange(event, index)} />
                                </div>
                                <div className="add-link-buttons">
                                    <button className="link-add-btns cancel">Cancel</button>
                                    <button className="link-add-btns apply">Apply</button>
                                </div>
                            </div>
                            : null
                    }
                </>
                : null
            )
        }
    </div>
);


export default buttonElement;