import { Handle, Position } from 'reactflow';
import endNode from '../../../Assets/Img/EndNode.png';

function EndNode({ data, isConnectable }) {

    return (
        <>
            <Handle
                type="target"
                position={Position.Top}
                style={{ background: "#D563B9", width: "10px", height: "10px",boxShadow: data?.boxShadow ? "0px 0px 20px 10px grey" : "none", }}
                isConnectable={isConnectable}
                id={`${data.elementId}-Top`}
            />
             <img id="endNode" style={{ width: '60px', height: '60px' }} src={endNode} alt="End Node" aria-hidden="true" />
        </>
    );
}

export default EndNode;
