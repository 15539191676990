import React from "react";
import "./ModalEditConversation.css";
import { GrClose } from "react-icons/gr";
import { IoIosArrowRoundBack } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { BiSolidEditAlt } from "react-icons/bi";

function ModalEditConversation(props) {
    const onClose = (event) => {
        event.preventDefault();
        props.setEditModal(false);
    }

    const onConfirmEdit = (event) => {
        event.preventDefault();
        props.toggleEditSaveBtn();
    }

    return (
        <div className="edit-convo-popup">
            <div className="edit-convo-popup-content">
                <div className="btnDiv">
                    <button className="close-button" onClick={onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className="edit-convo-popup-header">
                    <div>
                        <strong>Edit</strong> Conversation
                    </div>
                </div>
                <div className="edit-convo-popup-body">
                    <div style={{ fontSize: "24px", textAlign: "center" }}>Are you sure you want to <strong>edit this conversation</strong>?</div>
                    <div style={{ fontSize: "18px", textAlign: "center", marginTop: "12px" }}>If you edit this conversation, you will <strong>NOT</strong> be able to <strong>review</strong> or <strong>approve</strong> this conversation!</div>
                    <div className="dltEleBtns">
                        <button
                            className="button returnbtn"
                            onClick={onClose}
                        >
                            <IoIosArrowRoundBack size={25} /> Return to Conversation
                        </button>
                        <button
                            className="button delete"
                            onClick={onConfirmEdit}
                        >
                            <BiSolidEditAlt size={18} /> Edit Conversation
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ModalEditConversation;
