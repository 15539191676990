import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import './StartNode.css';
import { MdOutlinePlayCircleOutline } from "react-icons/md";

function StartNode({ data }) {
  const onChange = useCallback((evt) => {
    console.log(evt.target.value);
  }, []);

  const nodeStyle = {
    textAlign: "center",
    padding: "10px",
    width: "400px",
    height: "fit-content",
    borderRadius: "6px",
    border: data?.boxShadow ? "2px solid darkgrey" : "none", // Remove the errorStatus from here
    boxShadow: data?.boxShadow ? "0px 0px 20px 10px grey" : "none", // Light grey shadow for active node
  };

  return (
    <>
      <div className="start-tag" style={nodeStyle}  >
        <div className='play-icon'>
          <MdOutlinePlayCircleOutline color='white' size={40} />
        </div>
        <div className='convo-name'>
          <p className='convo-text'>{data.flowName}</p>
        </div>
      </div>
      <Handle type="source" position={Position.Bottom} id="a" 
        style={{ background: '#D563B9', width: '10px', height: '10px' }}
      />
    </>
  );
}

export default StartNode;
