import Dropdown from 'react-bootstrap/Dropdown';
import AccountImg from '../../Assets/Img/Account.png';
import './AccountDropdown.css';
import { msalInstance } from '../../index';

/** This function uses Bootstrap to implement the Dropdown element
 * Provides functionality to the Account Dropdown feature 
 * Visualizes the element on the screen
 */

function AccountDropdown() {

  function handleLogout() {
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: window.location.origin // Redirect back to login SSO page
    })
    .catch(error => {
      console.error(error);
    });
  }

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" id="dropdown_toggle">
        <img id='account_img' title="User Account" src={AccountImg} alt="Account Image" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item  className='items-beta'>⚙️ Account Settings</Dropdown.Item>
        <Dropdown.Item  className='items-beta'>💬 My Conversations</Dropdown.Item>
        <Dropdown.Item  className='items-beta'>🔔 Notifications</Dropdown.Item>
        <Dropdown.Item onClick={()=> handleLogout()}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default AccountDropdown;