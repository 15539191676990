import React, { useRef, useState, useEffect } from "react";
import "./MediaResponseNode.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdAdd } from "react-icons/io";
import { RxText } from "react-icons/rx";
import { LuImage } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { BsImageAlt } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { isNodeDraggableSlice, setIsNodeDraggable } from "../../../Redux/slicer/CreateSlicer";
import { PiUploadSimpleBold } from "react-icons/pi";
import { MdUndo } from "react-icons/md";
const v4 = require('uuid').v4;

const handleAddButtonName = (fileName) => {
    if(fileName === ""){
        return "Upload"
    
    } else {
        return "Replace"
    }
}


const imageElement = ({index, elementsArray, handleImageClick, handleAddImage, handleHoverElement, handleHoverOffElement, handleDeleteElement, handleToggleAddParaButton, handleUndoAction,handleHoverOffButton, handleAddElements, handleFileInputClick}) => (
    
  
    <div key={`image_${index}`} className="media-response-element-container"
        onMouseEnter={() => handleHoverElement(index)}
        onMouseLeave={() => handleHoverOffElement(index)}
    >
        <div className="media-response-element-wrapper" onClick={() => handleImageClick(index)}>
            <RxHamburgerMenu size={20} color="black" />
            <div className="media-element-image"
                onMouseEnter={() => handleHoverElement(index)}
                onMouseLeave={() => handleHoverOffElement(index)}
            >
                {elementsArray[index].imageAdded === false ? <BsImageAlt size={135} color="grey" /> : <img src={elementsArray[index].url} alt="" style={{ height: "100%", width: "100%" }}></img>}
            </div>
        </div>
        {(elementsArray[index].editing === true && !elementsArray[index].imageClicked) || elementsArray[index].undoAction === true ?
            <>
                <div className="media-element-edit"
                    onMouseEnter={() => handleHoverElement(index)}
                >
                    <div className="icon-container">
                        <LuImage color="grey" />
                    </div>
                    <div className="add-container">
                        <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                        <button className={`add-btn${elementsArray[index].toggleAddButton === true ? " clicked" : ""}`} onClick={() => handleToggleAddParaButton(index)}>
                            {elementsArray[index].toggleAddButton === true ? <IoClose color="white" size={25} /> : <IoMdAdd color="white" size={25} />}
                        </button>
                        <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                        <div className="delete-container">
                            {elementsArray[index].undoAction === true ? <button className="delete-btn undo" onClick={() => handleUndoAction(index)}> <MdUndo color="white" size={25} /></button> :
                                <button className="delete-btn" onClick={() => handleDeleteElement(index)}>
                                    <MdDelete color="white" size={25} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="media-add-elements-area"
                    onMouseEnter={() => handleHoverElement(index)}
                    onMouseLeave={() => handleHoverOffElement(index)}
                >
                    {elementsArray[index].toggleAddButton === true && elementsArray[index].editing === true ? handleAddElements(handleHoverElement, index) : null}
                </div>
            </>
            :
            <>
                {elementsArray[index].imageClicked === true ?
                    <div className="media-response-add-image-container"
                        onMouseEnter={() => handleHoverElement(index)}
                    >
                        <button className="btn-add-opt" onClick={() => handleAddImage(index)}
                            onMouseLeave={(() => elementsArray[index].addingImage && elementsArray[index].imageClicked === true ? null : handleHoverOffButton(index))}>
                            <PiUploadSimpleBold color="white" /></button>
                        <div className="image-add-text-container">
                            <p className="add-image-text">Upload image file. File types accepted: .png, .jpeg, and .gif.</p>
                        </div>
                    </div>
                    : null
                }
                {elementsArray[index].addingImage === true && elementsArray[index].imageClicked === true ?
                    <div className="media-element-link-container">
                        <div className="add-link-container">
                            <p className="add-link-text">File Uploaded</p>
                            <input className="file-input" type="text" placeholder="FileName.image" value={elementsArray[index].fileName} />
                        </div>
                        <div className="add-image-button-container">
                            <input id="file-input" type="file" placeholder="FileName.image" style={{ display: 'none' }} />
                            <button className="image-add-btns replace" onClick={() => handleFileInputClick(index)}>{handleAddButtonName(elementsArray[index].fileName)}</button>
                        </div>
                    </div>
                    : null
                }
            </>
        }
    </div>
);


export default imageElement;