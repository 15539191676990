import { v4 } from 'uuid';
import axios from 'axios';

// This function is invoked when the user clicks the "Export" button. It is imported in the canvas.jsx file.
// The purpose of this function is to update parent and child nodes for each node.
export const updateParentAndChildNodes = (edges, nodes, lastExportNodes, lastExportEdges, setLastExportEdges, setLastExportNodes, typeOfExport) => {
    nodes = nodes;
    edges = edges;

    if (!nodes || (nodes[0].id === 'ROOT' && nodes.length === 1)) {            //check if there are no nodes in the diagram. If there is not any nodes, return an alert and the nodes, stopping the function.
        alert("There are no nodes in the diagram. Please add nodes to the diagram before trying to export.");
        return nodes;
    }

    let nodesMissingParentNodes = [];           // variable to store nodes that are missing parent nodes. This is used for error handling below.

    nodes = nodes.map((node) => {           // Map through all nodes one by one

        let parentNodes = [];
        let childNodes = [];

        edges.forEach((edge) => {                       // Iterate through each edge to find the parent and child nodes for the current node
            if (edge.source === node.id) {                  // If the edge.source is the same as the current node's id, this means the node with the same id as the edge.target is a child node
                childNodes.push(edge.target);                   // add the id of the child node to the childNodes array
            }
            if (edge.target === node.id) {                  // If the edge.target is the same as the current node's id, this means the node with the same id as the edge.source is a parent node
                parentNodes.push(edge.source);                  // add the id of the parent node to the parentNodes array
            }
        });

        // If the current node has no parents, add it to the nodesMissingParentNodes array and set the errorStatus and errorMessage to true and an error message respectively
        if (parentNodes.length === 0 && node.id !== 'ROOT') {
            nodesMissingParentNodes.push(node);
            node = {
                ...node,
                data: {
                    ...node.data,
                    errorStatus: true,
                    errorMessage: "This node is not connected to a parent node. Please connect/re-connect to the parent node.",
                },
            };
        } 
        if ((node.type === 'textResponse' || node.type === 'mediaResponse') && childNodes.length > 1) {
            alert(`Node type of ${node.type} can only have one child node.`);
            return nodes; // Stop further execution and return the current state of nodes
        } else {       // If the current node has parents, set the childNodes and parentNodes to the arrays we created above
            node = {
                ...node,
                data: {
                    ...node.data,
                    parentNodes: parentNodes,
                    childNodes: childNodes,
                    errorStatus: false,
                    errorMessage: "",
                },
            };
        }
        return node;
    });

    if (nodesMissingParentNodes.length > 0) {       // If there are nodes missing parent nodes, alert the user and return the nodes, stopping the function
        setLastExportNodes(nodes);
        alert("One or more of the nodes are not connected to a parent node. Please check the diagram and resolve the errors.");
        return false;
    }

    const resultFromAssignDistance = assignMaxDistanceFromRootAndPrimaryParents(edges, nodes, lastExportNodes, lastExportEdges, setLastExportEdges, setLastExportNodes, typeOfExport);

    if (resultFromAssignDistance === false) {
        return false;
    } else if (resultFromAssignDistance === true) {
        return true;
    }
}


function assignMaxDistanceFromRootAndPrimaryParents(edges, nodes, lastExportNodes, lastExportEdges, setLastExportEdges, setLastExportNodes, typeOfExport) {
    // Initialize a queue and add the root node to it
    let queue = [{ node: nodes.find(node => node.id === 'ROOT'), distance: 0 }];

    // While the queue is not empty
    while (queue.length > 0) {
        // Dequeue a node from the queue
        let { node: currentNode, distance } = queue.shift();

        // Assign the distance to the currentNode
        currentNode.data.maximumDistanceFromRoot = distance;

        if (currentNode.data.childNodes.length === 0) {
            continue; // Skip this iteration and move to the next node in the queue
        }

        // For each child of currentNode, add it to the queue with distance + 1
        currentNode.data.childNodes.forEach(childId => {
            let childNode = nodes.find(node => node.id === childId);
            if (childNode) {
                queue.push({ node: childNode, distance: distance + 1 });
            }
        });
    }


    nodes.forEach((node) => {
        if (node.data.parentNodes.length > 1) {
            let maxDistance = null;
            let primaryParentNode = null;

            node.data.parentNodes.forEach((parentId) => {
                const parentNode = nodes.find((parent) => parent.id === parentId);
                if (parentNode && parentNode.data.maximumDistanceFromRoot > maxDistance) {
                    maxDistance = parentNode.data.maximumDistanceFromRoot;
                    primaryParentNode = parentNode;
                }
            });

            node.data.parentNodes.forEach((parentId) => {
                if (parentId !== primaryParentNode.id) {
                    const parentNode = nodes.find((parent) => parent.id === parentId);
                    if (parentNode && node.type !== 'suggestedActions') {
                        parentNode.data.secondaryNode = true;
                    }
                }
            });

            node.data.primaryParentNode = primaryParentNode;
        } else {
            node.data.primaryParentNode = nodes.find((parent) => parent.id === node.data.parentNodes[0]);
        }
    });

    const resultFromDetectNodes = detectNewNodes(edges, nodes, lastExportNodes, lastExportEdges, setLastExportEdges, setLastExportNodes, typeOfExport);

    if (resultFromDetectNodes === false) {
        return false;
    } else if (resultFromDetectNodes === true) {
        return true;
    }
}

function detectNewNodes(edges, nodes, lastExportNodes, lastExportEdges, setLastExportEdges, setLastExportNodes, typeOfExport) {
    nodes = nodes;
    edges = edges;

    nodes = nodes.map((node) => {
        edges.forEach((edge) => {
            const { source, target, sourceHandle } = edge;
            if (node.id === target) { // if an edge is connected to this node
                if (source === 'ROOT' || !target.startsWith('EN') && sourceHandle.startsWith('imBack')) {
                    const JSONNodeId = v4();
                    let condition = "";
                    // This is old code that was replaced by the if statement code below it. Keeping for testing if a revert is needed.
                    // if (sourceHandle.startsWith('imBack')) {
                    //     const parentNode = nodes.find((parent) => parent.id === node.data.primaryParentNode.id);
                    //     const index = sourceHandle.split('-')[1];
                    //     console.log(parentNode.data.suggestedActions[index].text);
                    //     condition = parentNode.data.suggestedActions[index].text;
                    // }
                    if (sourceHandle.startsWith('imBack')) {
                        const parentNode = nodes.find((parent) => parent.id === node.data.primaryParentNode.id);
                        if (parentNode && parentNode.data && Array.isArray(parentNode.data.suggestedActions)) {
                            const index = parseInt(sourceHandle.split('-')[1], 10);
                            if (!isNaN(index) && parentNode.data.suggestedActions.length > index) {
                                const suggestedAction = parentNode.data.suggestedActions[index];
                                if (suggestedAction && typeof suggestedAction.text === 'string') {
                                    console.log(suggestedAction.text);
                                    condition = suggestedAction.text;
                                }
                            }
                        }
                    }
                    node = {
                        ...node,
                        data: {
                            ...node.data,
                            condition: condition,
                            indexInNode: 0,
                            JSONNodeId: JSONNodeId,
                        },
                    };
                }

            }


        });
        return node;
    });

    const resultFromSuggestedActions = checkAndUpdateSuggestedActions(edges, nodes, lastExportNodes, lastExportEdges, setLastExportEdges, setLastExportNodes, typeOfExport);

    if (resultFromSuggestedActions === false) {
        return false;
    } else if (resultFromSuggestedActions === true) {
        return true;
    }
}

const checkAndUpdateSuggestedActions = (edges, nodes, lastExportNodes, lastExportEdges, setLastExportEdges, setLastExportNodes, typeOfExport) => {
    nodes = nodes;
    edges = edges;
    let suggestedActionsMissingEdges = [];

    nodes = nodes.map((node) => {
        if (node.type === "suggestedActions") {
            let numberOfSuggestedActions = node.data.suggestedActions.length;
            let numberOfChildren = node.data.childNodes.length;
            let newSuggestedActions = node.data.suggestedActions;

            edges.forEach((edge) => {
                if (edge.source === node.id && edge.target.startsWith('EN')) {
                    const childExitNode = nodes.find((child) => child.id === edge.target);
                    const index = edge.sourceHandle.split('-')[1];
                    newSuggestedActions[index] = {
                        ...newSuggestedActions[index],
                        flowName: childExitNode.data.externalConversation,
                    };
                }
            });

            if (numberOfSuggestedActions > numberOfChildren) {
                suggestedActionsMissingEdges.push(node);
                node = {
                    ...node,
                    data: {
                        ...node.data,
                        errorStatus: true,
                        errorMessage: "One or more suggested actions are not connected to a child node. Please check the diagram and resolve the errors.",
                        suggestedActions: newSuggestedActions,
                    },
                };
            }

        }
        return node;
    });

    if (suggestedActionsMissingEdges.length > 0) {
        alert("One or more of the suggested actions are not connected to a child node. Please check the diagram and resolve the errors.");
        setLastExportNodes(nodes);
        return false;
    } else if (typeOfExport === "check for errors") {
        return true;
    }

    updateAllIndexInNode(0, edges, nodes, typeOfExport);
};

const updateAllIndexInNode = (parentIndex, edges, nodes, typeOfExport) => {
    let count = 0;

    nodes = nodes;
    edges = edges;

    if (!nodes) {
        return nodes;
    }
    nodes = nodes.map((node) => {
        if (node.id === 'ROOT') {
            return node;
        }
        if (!node.data.parentNodes || node.data.parentNodes[0] === null) {
            return node;
        }
        const parentNode = nodes.find((parent) => parent.id === node.data.primaryParentNode.id);
        if (node.data.indexInNode === 0) {
            return node;
        } else if (parentNode && parentNode.data && parentNode.data.indexInNode === parentIndex) {
            const updatedNode = {
                ...node,
                data: {
                    ...node.data,
                    indexInNode: parentIndex + 1,
                    JSONNodeId: parentNode.data.JSONNodeId,
                },
            };
            count++;
            return updatedNode;
        } else {
            return node;
        }
    });
    if (count > 0) {
        count = 0;
        updateAllIndexInNode(parentIndex + 1, edges, nodes, typeOfExport);
    } else {
        updateParentNodeIds(edges, nodes, typeOfExport);
    }
};

const updateParentNodeIds = (edges, nodes, typeOfExport) => {
    nodes = nodes.map((node) => {
        if (node.data.indexInNode === 0) {
            let parentNodesForJSON = [];
            const nodesWithSameJSONNodeId = nodes.filter((n) => n.data.JSONNodeId === node.data.JSONNodeId);
            nodesWithSameJSONNodeId.forEach((node) => {
                if (node.type !== "suggestedActions") {
                    node.data.parentNodes.forEach((parentId) => {
                        //get the parentNode
                        const parentNode = nodes.find((parent) => parent.id === parentId);
                        if (parentNode && !parentNodesForJSON.includes(parentNode.data.JSONNodeId) && parentNode.data.JSONNodeId !== node.data.JSONNodeId) {
                            parentNodesForJSON.push(parentNode.data.JSONNodeId);
                        }
                    });
                }
            });

            // set the nodes parentNodeIds to the parentNodesForJSON
            node.data.parentNodeIds = parentNodesForJSON;
        }
        return node;
    });
    convertToCompleteJSON(edges, nodes, typeOfExport);
};

function convertToCompleteJSON(edges, nodes, typeOfExport) {
    let conversationId;
    let flowName;
    let collection;

    let JSONTemplate = {
        _id: "",
        flowName: "",
        conversationType: "",
        nodes: [],
    };
    nodes = nodes;
    edges = edges;
    const independentNodes = [];
    if (!nodes) {
        return nodes;
    }
    const allNodesHaveIndexInNode = nodes.every((node) => node.id === 'ROOT' || (node.data.indexInNode !== null && node.data.JSONNodeId !== ""));
    if (!allNodesHaveIndexInNode) {
        return nodes;
    }
    nodes.forEach((node) => {
        if (node.id === 'ROOT') {
            if (node.data.projectType === "hcp") {
                JSONTemplate._id = node.data._id;
                JSONTemplate.flowName = node.data.flowName;
                JSONTemplate.conversationType = node.data.conversationType;
                JSONTemplate.intentName = node.data.intentName;
                JSONTemplate.score = node.data.score;
                JSONTemplate.entities = node.data.entities;
                conversationId = node.data._id;
                flowName = node.data.flowName;
                collection = node.data.collection;
            } else {
                JSONTemplate._id = node.data._id;
                JSONTemplate.flowName = node.data.flowName;
                JSONTemplate.conversationType = node.data.conversationType;
                conversationId = node.data._id;
                flowName = node.data.flowName;
                collection = node.data.collection;
            }
        }
        if (node.data.indexInNode === 0) {
            const formattedNode = createFormattedNode(node);
            independentNodes.push(formattedNode);
            const index = independentNodes.findIndex((independentNode) => independentNode.nodeId === node.data.JSONNodeId);
            const nodeConvertedToJSON = handleConvertToJSON(node, nodes, conversationId, flowName, collection);
            if (Array.isArray(nodeConvertedToJSON)) {
                nodeConvertedToJSON.forEach((element) => {
                    independentNodes[index].responses.push(element);
                });
            }
            else {
                independentNodes[index].responses.push(nodeConvertedToJSON);
            }
        }
    });
    const validIndexInNodeValues = nodes
        .map((node) => node.data.indexInNode)
        .filter((indexInNode) => typeof indexInNode === 'number' && !isNaN(indexInNode));
    if (validIndexInNodeValues.length === 0) {
        return nodes;
    }
    const highestIndexInNode = Math.max(...validIndexInNodeValues);
    if (highestIndexInNode >= 1) {
        // Initialize a set to track the generated exit nodes that will lead to jumpFlow
        const processExitNodes = new Set();
        for (let i = 1; i <= highestIndexInNode; i++) {
            nodes.forEach((node) => {
                if (node.data.indexInNode === 0 || node.type === "exitNode") {
                    if(!processExitNodes.has(node.id)) {
                        node.data.parentNodes.forEach((parentId) => {
                            //get the node
                            const parentNode = nodes.find((parent) => parent.id === parentId);
                            if (parentNode.type === "textResponse" && node.type === "exitNode" && node?.data?.parentNodes?.length === 1) {
                                const index = independentNodes.findIndex((independentNode) => independentNode.nodeId === parentNode.data.JSONNodeId);
                                node.data.subProcess = true;
                                const nodeConvertedToJSON = handleConvertToJSON(node, nodes, conversationId, flowName, collection, processExitNodes);
                                independentNodes[index].responses.push(nodeConvertedToJSON);
                                processExitNodes.add(node.id);
                            }
                        });
                     }
                    if (node.type === "offboardingEndNode" || node.type === "exitNode") {
                        return;
                    }
                }
                if (node.data.indexInNode === i && node.type === "suggestedActions" && node.data.parentNodes.length > 1) {
                    node.data.parentNodes.forEach((parentId) => {
                        //get the node
                        const parentNode = nodes.find((parent) => parent.id === parentId);
                        const index = independentNodes.findIndex((independentNode) => independentNode.nodeId === parentNode.data.JSONNodeId, processExitNodes);
                        const nodeConvertedToJSON = handleConvertToJSON(node, nodes, conversationId, flowName, collection);
                        if (Array.isArray(nodeConvertedToJSON)) {
                            nodeConvertedToJSON.forEach((element) => {
                                independentNodes[index].responses.push(element);
                            });
                        }
                        else {
                            independentNodes[index].responses.push(nodeConvertedToJSON);
                        }
                    });
                } else if (node.data.indexInNode === i) {
                    const index = independentNodes.findIndex((independentNode) => independentNode.nodeId === node.data.JSONNodeId);
                    const nodeConvertedToJSON = handleConvertToJSON(node, nodes, conversationId, flowName, collection, processExitNodes);
                    if (Array.isArray(nodeConvertedToJSON)) {
                        nodeConvertedToJSON.forEach((element) => {
                            independentNodes[index].responses.push(element);
                        });
                    }
                    else {
                        independentNodes[index].responses.push(nodeConvertedToJSON);
                    }
                }
            });
        }
    }
    JSONTemplate.nodes = independentNodes;
    const jsonData = JSONTemplate;
    const nodesWithIndexZero = nodes.filter(node => node.data.indexInNode === 0).length;
    
    if (jsonData.nodes.length === nodesWithIndexZero && typeOfExport == "json download") {
        const jsonString = JSON.stringify(jsonData, null, 2);
        const blob = new Blob([jsonString], { type: 'application/json' });
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'exportedJSON.json';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } 
};

function createFormattedNode(node) {
    if (node.data.parentNodes[0] === 'ROOT') {
        return {
            nodeId: node.data.JSONNodeId,
            parentIds: ["ROOT"],
            condition: node.data.condition,
            responses: [],
        };
    } else {
        return {
            nodeId: node.data.JSONNodeId,
            parentIds: node.data.parentNodeIds,
            condition: node.data.condition,
            responses: [],
        };
    }
}

function handleConvertToJSON(selectedNode, nodes, conversationId, flowName, collection, processExitNodes) {
    if (!selectedNode) {
        console.error("Invalid or missing selectedNode data");
        return;
    }
    if (selectedNode.type === "suggestedActions") {
        const convertedJSON = {
            type: selectedNode.type,
            text: "",
            suggestedActions: selectedNode.data.suggestedActions.map((action, index) => {
                let transformedAction = {};
                if (action.flowName !== "") {
                    transformedAction = {
                        type: "messageBack",
                        title: action.text,
                        text: action.text,
                        displayText: action.text,
                        value: {
                            text: action.text,
                            payload: {
                                action: "triggerFlow",
                                value: action.flowName
                            }
                        },
                    };
                } else {
                    transformedAction = {
                        type: action.type,
                        title: action.text,
                        value: action.text,
                    };
                }

                return transformedAction;
            }),
        };
        const jsonString = JSON.stringify(convertedJSON, null, 2); // 2 is for indentation
        return convertedJSON;
    }
    else if (selectedNode.type === "textResponse") {
        let arrayOfTextResponses = [];
        selectedNode.data.textArray.forEach((option, index) => {
            const jsonMapping = {
                type: "text",
                text: option.text,
            };
            arrayOfTextResponses.push(jsonMapping);
        });

        if (selectedNode.data.feedback) {
            const condition = selectedNode.data.condition; 
            const jsonMapping = {
                type: "event",
                name: "enableFeedbackPrompt",
                value: {
                    ResponseTagId: `${flowName}-${condition}`
                }
            }
            arrayOfTextResponses.push(jsonMapping);
        }
        if (selectedNode.data.secondaryNode) {
            const childNode = nodes.find((child) => child.id === selectedNode.data.childNodes[0]);
            const jsonMapping = {
                type: "jumpFlow",
                collection: collection,
                flowId: conversationId,
                flowName: flowName,
                nodeId: childNode.data.JSONNodeId,
                responsesStartIndex: childNode.data.indexInNode
            }
            arrayOfTextResponses.push(jsonMapping);
        }
        return arrayOfTextResponses;
    }
    else if (selectedNode.type === "mediaResponse") {
        if (selectedNode.data.adaptiveCard === false) {
            const jsonMapping = {
                type: "text",
                text: selectedNode.data.mediaResponseData.text,
            };
            return jsonMapping;
        }
        else if (selectedNode.data.adaptiveCard === true) {
            const jsonMapping = {
                type: "cards",
                layout: "list",
                text: "",
                cards: [
                    selectedNode.data.mediaResponseData
                ],

                AdaptiveCardData:selectedNode.data.cardData
            };

            // code for sending the adaptiveCard element data to the database

            // const database = 'testProduct_Weave';
            // const collection = 'testProduct_Cards_Weave';

            // axios.post('/api/data', selectedNode.data.cardData, {
            //       params: {
            //         database: database,
            //         collection: collection,
            //         postType: "json"
            //       },
            //     })

            return jsonMapping;
        }
    } else if (selectedNode.data.subProcess && processExitNodes && !processExitNodes.has(selectedNode.id)) {
        const dataSource = sessionStorage.getItem('collectionData')
        const dataList = JSON.parse(dataSource)
        const subProcessData = dataList.find(ele => {
            return ele.nodes[0].data.flowName === selectedNode?.data?.externalConversation
        })
        const jsonMapping = {
            type: "jumpFlow",
            collection: subProcessData.nodes[0].data.conversationType,
            flowId: subProcessData._id,
            flowName: subProcessData.nodes[0].data.flowName,
            nodeId: selectedNode.data.JSONNodeId,
            responsesStartIndex: "0"
        }
        return jsonMapping
    } else if (selectedNode.type === "starRatingNode") {
        const jsonMapping = {
            type: "event",
            name: "showReviewPrompt",
        };
        return jsonMapping;
    }
}