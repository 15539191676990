import React from 'react';
import './ApproveConversationPopUp.css';
import { GrClose } from 'react-icons/gr';
import { AiOutlineClose } from 'react-icons/ai';
import { HiCheck} from 'react-icons/hi';

function ApproveConversationPopUp(props) {
    const handleVaultIdChange = (event) => {
        const inputText = event.target.value;
        props.setNewVeevaVaultID(inputText);
    };

    const handleCommentsChange = (event) => {
        const inputText = event.target.value;
        props.setApprovalComments(inputText);
    };

    return (
        <div className="ApproveConversation-container">
            <div className='ApproveConversation-container-Content'>
                <div className='ApproveConversation-CloseButtonContainer'>
                    <button className='ApproveConversation-Closebutton' onClick={props.onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className='ApproveConversation-container-Header'>
                    <p className="ApproveConversation-HeaderText">
                        <strong>Approve Conversation</strong>
                    </p>
                </div>
                <div className='ApproveConversation-container-Body'>
                    <p className="ApproveConversation-BodyText">
                        To approve this conversation, please verify and change if necessary the Veeva Vault ID below. An incorrect vault ID is required to complete the approval process.
                    </p>
                    <p className="ApproveConversation-BodyText">
                        You can optional add a comment to the approval, then click the <strong>Approve Conversation</strong> button to lock the conversation and move it to <strong>Approved</strong> status.
                    </p>
                    <p className="ApproveConversation-Title">
                        <strong>VeevaVault ID</strong>
                    </p>
                    <textarea
                        id="ApproveConversation-Input"
                        className="ApproveConversation-Input"
                        value={props.newVeevaVaultID}
                        onChange={handleVaultIdChange}
                        style={{ resize: 'none', height: '40px' }}
                        placeholder="Please enter the Veeva Vault ID here:"
                    />
                    <p className="ApproveConversation-Title">
                        <strong>Approval Comments</strong> (optional)
                    </p>
                    <textarea
                        id="ApproveConversation-Input"
                        className="ApproveConversation-Input"
                        value={props.approvalComments}
                        onChange={handleCommentsChange}
                        style={{ resize: 'none', height: '120px' }}
                        placeholder="Add any comments for changes here:"
                    />
                    <div className='ApproveConversation-buttons-container'>
                        <button className={`ApproveConversation-Button Return`} onClick={props.onClose}>
                            <AiOutlineClose size={25} /> Return To Conversation
                        </button>
                        <button className={`ApproveConversation-Button Approve`} onClick={props.handleApproveConversation}>
                            <HiCheck size={25} /> Approve Conversation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ApproveConversationPopUp;