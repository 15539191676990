import { createSlice } from '@reduxjs/toolkit'


export const deleteNodeSlice = createSlice({
  name: 'deleteNodeSlice',
  initialState: {
    value: false,
    nodeID: null
  },
  reducers: {
    showPopUp: (state, action) => {
      state.value = action.payload.show;
      state.nodeID = action.payload.nodeID;
    },
  },
})

// Action creators are generated for each case reducer function
export const { showPopUp } = deleteNodeSlice.actions;

export default deleteNodeSlice.reducer;
