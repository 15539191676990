import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import './StarRatingNode.css';
import { FaStar } from "react-icons/fa";

function StarRatingNode({ data }) {
  const stars = [1, 2, 3, 4, 5]
  return (
    <>
      <Handle type="target" position={Position.Top} id="a"
        style={{ background: '#D563B9', width: '10px', height: '10px' }}
      />
      <div className="start-tag">
        <div className="convo-text">
          <p>Please rate your experience with the Lilly Assist chatbot today.</p>
        </div>
          <div> 
            {stars.map((star) => (
              <label className="starRating" key = {star}>
                <FaStar color='white' size={40}/>
              </label>
            ))}
         </div>
      </div>
      <Handle type="source" position={Position.Bottom} id="b"
        style={{ background: '#D563B9', width: '10px', height: '10px' }}
      />
    </>
  );
}

export default StarRatingNode;
