import React from 'react';
import './UpdateVaultID.css';
import { GrClose } from 'react-icons/gr';
import { AiOutlineClose } from 'react-icons/ai';
import { HiCheck } from 'react-icons/hi';

function UpdateVaultID(props) {
    const handleTextChange = (event) => {
        const inputText = event.target.value;
        props.setNewVeevaVaultID(inputText);
    };

    return (
        <div className="UpdateVaultID-container">
            <div className="UpdateVaultID-container-content">
                <div className="UpdateVaultID-close-container">
                    <button className="UpdateVaultID-close-button" onClick={props.onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className="UpdateVaultID-container-header">
                    <p className="UpdateVaultID-container-header-text">
                        Update <strong>Veeva Vault ID</strong>
                    </p>
                </div>
                <div className="UpdateVaultID-container-body">
                    <p className="UpdateVaultID-container-body-text">
                        Please ensure that the below vault ID matches the vault ID in Veeva Vault for this conversation. A correct vault ID is required for the approval process.
                    </p>
                    <textarea
                        id="UpdateVaultID-input"
                        className="UpdateVaultID-input"
                        value={props.newVeevaVaultID}
                        onChange={handleTextChange}
                        placeholder="Please enter the Veeva Vault ID here:"
                    />
                    <div className="UpdateVaultID-buttons-container">
                        <button className="UpdateVaultID-button Return" onClick={props.onClose}>
                            <AiOutlineClose size={25} /> Return To Conversation
                        </button>
                        <button className="UpdateVaultID-button Add" onClick={props.handleUpdateVaultID}>
                            <HiCheck size={25} /> Add Vault ID
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UpdateVaultID;