import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import './StateDropdown.css';
import RequestReviewPopUp from './RequestReviewPopUp';
import CancelReviewPopUp from './CancelReviewPopUp';
import CompleteReviewPopUp from './CompleteReviewPopUp';
import CancelApprovalPopUp from './CancelApprovalPopUp';
import ApproveConversationPopUp from './ApproveConversationPopUp';
import UpdateVaultID from './UpdateVaultID';
import BackToDraft from './BackToDraft';
import { AiOutlineSearch } from 'react-icons/ai';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { MdOutlineCancel } from 'react-icons/md';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { useDispatch } from "react-redux";
import { config } from '../../Redux/slicer/ConfigSlicer';
import { useMsal } from "@azure/msal-react";
import { set } from 'lodash';
import { updateParentAndChildNodes } from "../../convert";

function State(props) {
  const [showRequestReviewPopUp, setShowRequestReviewPopUp] = useState(false);
  const [showCompleteReviewPopUp, setShowCompleteReviewPopUp] = useState(false);
  const [showCancelReviewPopUp, setShowCancelReviewPopUp] = useState(false);
  const [showCancelApprovalPopUp, setShowCancelApprovalPopUp] = useState(false);
  const [showVaultIdPopUp, setShowVaultIdPopUp] = useState(false);
  const [approveConversationPopUp, setApproveConversationPopUp] = useState(false);
  const [showBackToDraftPopUp, setShowBackToDraftPopUp] = useState(false);
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const [stateMessage, setStateMessage] = useState('');
  const [approvalComments, setApprovalComments] = useState('');
  const [newVeevaVaultID, setNewVeevaVaultID] = useState(props.veevaVaultId);

  const handleRequestReviewClick = async () => {
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    const canRequestReview = await dispatch(config({ functionality: 'request_Review', roles: account.idTokenClaims.roles }));

    if (canRequestReview.payload) {
      setShowRequestReviewPopUp(true);
    } else {
      alert('You do not have permission to request a review');
    }
  };

  const checkForErrors = async () => {
    const typeOfExport = "check for errors";
    setShowRequestReviewPopUp(false);
    const result = await updateParentAndChildNodes(
      props.edges,
      props.nodes,
      props.lastExportNodes,
      props.lastExportEdges,
      props.setLastExportEdges,
      props.setLastExportNodes,
      typeOfExport
    );
    return result;
  };

  const confirmRequestReview = async () => {
    // Call checkForErrors and await the result
    const errorsExist = await checkForErrors();

    // If checkForErrors returned false, return early from confirmRequestReview
    if (!errorsExist) {
      return;
    }

    // Rest of your code...
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    const canRequestReview = await dispatch(config({ functionality: 'request_Review', roles: account.idTokenClaims.roles }));
    if (canRequestReview.payload) {
      const rootNode = props.nodes.find(node => node.id === 'ROOT');
      if (rootNode) {
        const updatedNode = {
          ...rootNode,
          data: {
            ...rootNode.data,
            conversationState: 'IN REVIEW',
          }
        };
        const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
        props.setNodes(updatedNodes);
      }
      props.setConversationState('IN REVIEW');
      setShowRequestReviewPopUp(false);
    } else {
      alert('You do not have permission to request a review');
    }
  };

  const handleCancelReviewClick = async () => {
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    const canCancelReview = await dispatch(config({ functionality: 'cancel_Review', roles: account.idTokenClaims.roles }));

    if (canCancelReview.payload) {
      setShowCancelReviewPopUp(true);
    } else {
      alert('You do not have permission to request a review');
    }
  };

  const confirmCancelReview = async () => {
    const canCancelRequestReview = await dispatch(config({ functionality: 'cancel_Review', roles: account.idTokenClaims.roles }));
    if (canCancelRequestReview.payload) {
      const rootNode = props.nodes.find(node => node.id === 'ROOT');
      if (rootNode) {
        const updatedNode = {
          ...rootNode,
          data: {
            ...rootNode.data,
            conversationState: 'DRAFT',
          }
        };
        const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
        props.setNodes(updatedNodes);
      }
      props.setConversationState('DRAFT');
      setShowCancelReviewPopUp(false);
    } else {
      alert('You do not have permission to cancel a review');
    }
  };

  const handleCompleteReviewClick = async () => {
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    const canCompleteReview = await dispatch(config({ functionality: 'complete_Review', roles: account.idTokenClaims.roles }));
    const isConversationWriter = props.nodes.find(node => node.id === 'ROOT').data.conversationWriters.includes(account.idTokenClaims.name);

    if (!isConversationWriter) {
      if (canCompleteReview.payload) {
        setShowCompleteReviewPopUp(true);
      } else {
        alert('You do not have permission to complete a review');
      }
    } else {
      alert('You are not able to approve this conversation as you are have previously made changes to it. An approver is not able to approve their own conversations.');
    }
  }

  const confirmCompleteReviewEdit = async () => {
    const isConversationWriter = props.nodes.find(node => node.id === 'ROOT').data.conversationWriters.includes(account.idTokenClaims.name);
    const canCompleteReview = await dispatch(config({ functionality: 'complete_Review', roles: account.idTokenClaims.roles }));
    if (canCompleteReview.payload) {
      const rootNode = props.nodes.find(node => node.id === 'ROOT');
      const updatedNode = {
        ...rootNode,
        data: {
          ...rootNode.data,
          conversationState: 'DRAFT',
          conversationReviewers: [...rootNode.data.conversationWriters, account.idTokenClaims.name],
          stateMessage: stateMessage,
        }
      };
      const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
      props.setNodes(updatedNodes);

      props.setConversationState('DRAFT');
      setShowCompleteReviewPopUp(false);
    } else {
      alert('You do not have permission to complete a review');
    }
  }

  const confirmCompleteReviewReady = async () => {
    const isConversationWriter = props.nodes.find(node => node.id === 'ROOT').data.conversationWriters.includes(account.idTokenClaims.name);
    const canCompleteReview = await dispatch(config({ functionality: 'complete_Review', roles: account.idTokenClaims.roles }));
    if (canCompleteReview.payload) {
      if (!isConversationWriter) {
        const rootNode = props.nodes.find(node => node.id === 'ROOT');

        const updatedNode = {
          ...rootNode,
          data: {
            ...rootNode.data,
            conversationState: 'IN APPROVAL',
            conversationReviewers: [...rootNode.data.conversationWriters, account.idTokenClaims.name],
            stateMessage: stateMessage,
          }
        };
        const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
        props.setNodes(updatedNodes);
        props.setConversationState('IN APPROVAL');
        setShowCompleteReviewPopUp(false);
      } else {
        setShowCompleteReviewPopUp(false);
        alert('You are not able to complete this review as you are a writer of this conversation.');
      }
    } else {
      alert('You do not have permission to complete a review');
    }
  }

  const handleCancelApprovalClick = async () => {
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    const canCancelApproval = await dispatch(config({ functionality: 'cancel_Approval', roles: account.idTokenClaims.roles }));

    if (canCancelApproval.payload) {
      setShowCancelApprovalPopUp(true);
    } else {
      alert('You do not have permission to cancel an approval');
    }
  };

  const confirmCancelApproval = async () => {
    const canCancelApproval = await dispatch(config({ functionality: 'cancel_Approval', roles: account.idTokenClaims.roles }));
    if (canCancelApproval.payload) {
      const rootNode = props.nodes.find(node => node.id === 'ROOT');
      if (rootNode) {
        const updatedNode = {
          ...rootNode,
          data: {
            ...rootNode.data,
            conversationState: 'DRAFT',
          }
        };
        const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
        props.setNodes(updatedNodes);
      }
      props.setConversationState('DRAFT');
      setShowCancelApprovalPopUp(false);
    } else {
      alert('You do not have permission to cancel an approval');
    }
  };

  const handleAddVaultIdClick = async () => {
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    setNewVeevaVaultID(rootNode.data.veevaVaultId);
    const canAddVaultID = await dispatch(config({ functionality: 'add_Vault_ID', roles: account.idTokenClaims.roles }));

    if (canAddVaultID.payload) {
      setShowVaultIdPopUp(true);
    } else {
      alert('You do not have permission to add a vault ID');
    }
  };

  const confirmAddVaultId = async () => {
    const canAddVaultID = await dispatch(config({ functionality: 'add_Vault_ID', roles: account.idTokenClaims.roles }));
    if (canAddVaultID.payload) {
      const rootNode = props.nodes.find(node => node.id === 'ROOT');

      const updatedNode = {
        ...rootNode,
        data: {
          ...rootNode.data,
          veevaVaultId: newVeevaVaultID,
        }
      };
      const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
      props.setNodes(updatedNodes);
      props.setVeevaVaultId(newVeevaVaultID);
      setShowVaultIdPopUp(false);
    } else {
      alert('You do not have permission to add a vault ID');
    }
  }

  const handleApproveConversationClick = async () => {
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    setNewVeevaVaultID(rootNode.data.veevaVaultId);
    const canApproveConversation = await dispatch(config({ functionality: 'complete_Approval', roles: account.idTokenClaims.roles }));
    const isConversationWriter = props.nodes.find(node => node.id === 'ROOT').data.conversationWriters.includes(account.idTokenClaims.name);

    if (!isConversationWriter) {
      if (canApproveConversation.payload) {
        setApproveConversationPopUp(true);
      } else {
        alert('You do not have permission to add a vault ID');
      }
    } else {
      alert('You are not able to approve this conversation as you are have previously made changes to it. An approver is not able to approve their own conversations.');
    }
  };

  const confirmApproveConversation = async () => {
    const canApproveConversation = await dispatch(config({ functionality: 'complete_Approval', roles: account.idTokenClaims.roles }));
    if (canApproveConversation.payload) {
      const rootNode = props.nodes.find(node => node.id === 'ROOT');

      const updatedNode = {
        ...rootNode,
        data: {
          ...rootNode.data,
          veevaVaultId: newVeevaVaultID,
          conversationState: 'APPROVED',
          conversationApprovers: [...rootNode.data.conversationApprovers, account.idTokenClaims.name],
          stateMessage: approvalComments,
        }
      };
      const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
      props.setNodes(updatedNodes);
      props.setVeevaVaultId(newVeevaVaultID);
      props.setConversationState('APPROVED');
      setApproveConversationPopUp(false);
    } else {
      alert('You do not have permission to add a vault ID');
    }
  }

  const handleSendBackToDraft = async () => {
    const rootNode = props.nodes.find(node => node.id === 'ROOT');
    const canCancelApproval = await dispatch(config({ functionality: 'cancel_Approval', roles: account.idTokenClaims.roles }));

    if (canCancelApproval.payload) {
      setShowBackToDraftPopUp(true);
    } else {
      alert('You do not have permission to send this conversation back to draft.');
    }
  };

  const confirmSendBackToDraft = async () => {
    const canCancelApproval = await dispatch(config({ functionality: 'cancel_Approval', roles: account.idTokenClaims.roles }));
    if (canCancelApproval.payload) {
      const rootNode = props.nodes.find(node => node.id === 'ROOT');
      if (rootNode) {
        const updatedNode = {
          ...rootNode,
          data: {
            ...rootNode.data,
            conversationState: 'DRAFT',
          }
        };
        const updatedNodes = props.nodes.map(node => node.id === rootNode.id ? updatedNode : node);
        props.setNodes(updatedNodes);
      }
      props.setConversationState('DRAFT');
      setShowBackToDraftPopUp(false);
    } else {
      alert('You do not have permission to send this conversation back to draft.');
    }
  };

  const handleClosePopUp = (event) => {
    event.preventDefault();
    setShowRequestReviewPopUp(false);
    setShowCompleteReviewPopUp(false);
    setShowCancelReviewPopUp(false);
    setShowCancelApprovalPopUp(false);
    setShowVaultIdPopUp(false);
    setApproveConversationPopUp(false);
    setShowBackToDraftPopUp(false);
  };

  return (
    <>
      <DropdownButton className={`state-dropdown ${props.conversationState}`} title={props.conversationState}>
        {props.conversationState === 'DRAFT' ? (
          <Dropdown.Item onClick={handleRequestReviewClick}><AiOutlineSearch size={20} /> Request Review</Dropdown.Item>
        ) : null}

        {props.conversationState === 'IN REVIEW' ? (
          <>
            <Dropdown.Item onClick={handleCompleteReviewClick}><AiOutlineCheckCircle size={20} /> Complete Review</Dropdown.Item>
            <Dropdown.Item onClick={handleCancelReviewClick}><MdOutlineCancel size={20} /> Cancel Review Request</Dropdown.Item>
          </>
        ) : null}

        {props.conversationState === 'IN APPROVAL' ? (
          <>
            <Dropdown.Item onClick={handleAddVaultIdClick}><AiOutlineCheckCircle size={20} /> Update Veeva Vault ID</Dropdown.Item>
            <Dropdown.Item onClick={handleApproveConversationClick}><AiOutlineCheckCircle size={20} /> Approve Conversation</Dropdown.Item>
            <Dropdown.Item onClick={handleCancelApprovalClick}><MdOutlineCancel size={20} /> Cancel Approval</Dropdown.Item>
          </>
        ) : null}
        {props.conversationState === 'APPROVED' ? (
          <>
            <Dropdown.Item onClick={handleAddVaultIdClick}><AiOutlineCheckCircle size={20} /> Update Veeva Vault ID</Dropdown.Item>
            <Dropdown.Item onClick={handleSendBackToDraft}><MdOutlineCancel size={20} /> Send Back To Draft</Dropdown.Item>
          </>
        ) : null}
      </DropdownButton>
      {showRequestReviewPopUp === true ? (
        <RequestReviewPopUp
          show={showRequestReviewPopUp}
          onClose={handleClosePopUp}
          onRequestReview={confirmRequestReview}
        />
      ) : null}
      {showCancelReviewPopUp === true ? (
        <CancelReviewPopUp
          onClose={handleClosePopUp}
          onCancelReview={confirmCancelReview}
        />
      ) : null}
      {showCompleteReviewPopUp === true ? (
        <CompleteReviewPopUp
          onClose={handleClosePopUp}
          confirmCompleteReviewEdit={confirmCompleteReviewEdit}
          confirmCompleteReviewReady={confirmCompleteReviewReady}
          setStateMessage={setStateMessage}
          stateMessage={stateMessage}
        />
      ) : null}
      {showCancelApprovalPopUp === true ? (
        <CancelApprovalPopUp
          onClose={handleClosePopUp}
          onCancelApproval={confirmCancelApproval}
        />
      ) : null}
      {showVaultIdPopUp === true ? (
        <UpdateVaultID
          onClose={handleClosePopUp}
          handleAddVaultID={confirmAddVaultId}
          setNewVeevaVaultID={setNewVeevaVaultID}
          newVeevaVaultID={newVeevaVaultID}
        />
      ) : null}
      {approveConversationPopUp === true ? (
        <ApproveConversationPopUp
          onClose={handleClosePopUp}
          handleApproveConversation={confirmApproveConversation}
          setNewVeevaVaultID={setNewVeevaVaultID}
          newVeevaVaultID={newVeevaVaultID}
          setApprovalComments={setApprovalComments}
          approvalComments={approvalComments}
        />
      ) : null}
      {showBackToDraftPopUp === true ? (
        <BackToDraft
          onClose={handleClosePopUp}
          onSendBackToDraft={confirmSendBackToDraft}
        />
      ) : null}
    </>
  );
}

export default State;
