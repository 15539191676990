import React from 'react';
import './CancelApprovalPopUp.css';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { GrClose } from 'react-icons/gr';

function CancelApprovalPopUp(props) {
    return (
        <div className="cancelApproval-custom-modal">
            <div className='cancelApproval-custom-modal-content' style={{ maxHeight: '80vh' }}>
                <div className='cancelApproval-btnDiv'>
                    <button className='cancelApproval-close-button' onClick={props.onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className='cancelApproval-custom-modal-header'>
                    <h3>
                        Are you sure you want to <strong>cancel this approval?</strong>
                    </h3>
                </div>
                <div className='cancelApproval-custom-modal-body'>
                    <p>
                        Once you have cancelled an approval, the conversation will be unlocked and further changes can be made. This will however <strong>reset the approval process</strong> and return the conversation to Draft state. Once the necessary changes have been made, the review and approval process can be restarted.
                    </p>
                    <div className='cancelApproval-actionBtns'>
                    <button className={`cancelApproval-button return`} onClick={props.onClose}>
                        <IoIosArrowRoundBack size={25} /> Return to Conversation
                    </button>
                    <button
                        className="cancelApproval-button add"
                        onClick={props.onCancelApproval}
                    >
                        <GrClose size={22} /> Cancel Approval
                    </button>
                </div>
                </div>
            </div>
        </div>
    );
}

export default CancelApprovalPopUp;