import { useState, React } from 'react';
import './FilterComponent.css';

function FilterComponent({ filterText, onFilter, onClear }) {

	const [conversationData, setConversatioData] = useState(null);

	// creating the template for the json

	return (
		<>
			<input
				id="search"
				type="text"
				placeholder="Filter By Keyword"
				aria-label="Search Input"
				value={filterText}
				onChange={onFilter}
			/>
			<button type="button" onClick={onClear} className='clear-button'>
				X
			</button>
		</>
	);
}

export default FilterComponent;

