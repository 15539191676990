import React, { useRef, useState } from "react";
import { Handle, Position } from "reactflow";
import "../../../index.css";
import "./exitNode.css";
import { TbMapPinShare } from "react-icons/tb";
import { BiSolidCommentError } from "react-icons/bi";
import { useDispatch } from 'react-redux'
import {showPopUp} from '../../../Redux/slicer/deleteNodeSlicer';
import { MdDeleteOutline } from "react-icons/md";

const ExitNode = ({ data, isConnectable, id }) => {
    const [enableDelete, setEnableDelete] = useState(false);
    const dispatch = useDispatch();

    const exitNodeContainerStyle = {
        textAlign: "center",
        background: "#9E75F4",
        width: "260px",
        height: "90px",
        borderRadius: "6px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: data.errorStatus ? "2px solid red" : "none", // Add a red border if data.errorStatus is true
        boxShadow: data?.boxShadow ? "0px 0px 20px 10px grey" : "none", // Light grey shadow for active node
    }


    const toggleHover = () => {
        setEnableDelete(prevState => !prevState);
    }

    //function to delete the node
    const deleteElement = () => {
        dispatch(showPopUp({show: true, nodeID: id}));
    }

    return (
        <>
        <div onMouseEnter={toggleHover} onMouseLeave={toggleHover} >
            <Handle
                type="target"
                position={Position.Top}
                style={{ background: "#D563B9", width: "8px", height: "8px" }}
                isConnectable={isConnectable}
                id={`${data.elementId}-Top`}
            />
            {data.errorStatus && (
                <div className="errorContainerExitNode">
                    <BiSolidCommentError className="errorIcon" />
                    <span className="errorMessage">{data.errorMessage}</span>
                </div>
            )}
            {!data.externalConversation && (
                <div className="errorContainerExitNode">
                    <BiSolidCommentError className="errorIcon" />
                    <span className="errorMessage">This node is not connected to an external conversation.</span>
                </div>
            )}
            <div style={exitNodeContainerStyle}>
                <div className="exitNodeIcon">
                    <TbMapPinShare />
                    <p className="exitNodeText">{`{ ${data.externalConversation || "No Conversation Selected"} }`}</p>            </div>
            </div>
            {enableDelete && (
                    <button className="display-on-hover" onClick={deleteElement}>
                    <MdDeleteOutline />
                    </button>
                )}
        </div>
        </>
    );
};

export default ExitNode;