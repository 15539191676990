import { Handle, Position } from 'reactflow';
import './OnboardingStartNode.css';
import { FaRegCircleDot } from "react-icons/fa6";

function OnboardingStartNode({ data }) {
  const nodeStyle = {
    textAlign: "center",
    width: "fit-content",
    height: "fit-content",
    borderRadius: "100px",
    border: data?.boxShadow ? "2px solid darkgrey" : "none", // Remove the errorStatus from here
    boxShadow: data?.boxShadow ? "0px 0px 20px 10px grey" : "none", // Light grey shadow for active node
  };

  return (
    <>
      <div>
        <FaRegCircleDot color='black' size={80} style={nodeStyle}/>
      </div>
      <Handle type="source" position={Position.Bottom} id="a" 
        style={{ background: '#D563B9', width: '10px', height: '10px' }}
      />
    </>
  );
}

export default OnboardingStartNode;