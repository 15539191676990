import { LogLevel } from "@azure/msal-browser";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
// const ua = window.navigator.userAgent;
// const msie = ua.indexOf("MSIE ");
// const msie11 = ua.indexOf("Trident/");
// const msedge = ua.indexOf("Edge/");
// const firefox = ua.indexOf("Firefox");
// const isIE = msie > 0 || msie11 > 0;
// const isEdge = msedge > 0;
// const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId: 'abbdd706-d174-4a44-b445-0d288eebfd0d',
        authority: "https://login.microsoftonline.com/18a59a81-eea8-4c30-948a-d8824cdc2580",
        redirectUri: "/",
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
        // storeAuthStateInCookie: isIE || isEdge || isFirefox,
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", "email"],
};