import React, { useRef, useState, useCallback, useEffect } from "react";
import { v4 } from "uuid";
import TextUpdaterNode from "./Components/NodeTypes/TextResponseComponent/TextResponseNode";
import exitNode from "./Components/NodeTypes/ExitNodeComponent/exitNode.jsx";
import "reactflow/dist/style.css";
import StartNode from "./Components/NodeTypes/StartNodeComponent/StartNode";
import ConversationMenu from "./Components/ConversationMenu/ConversationMenu";
import ExportPopUp from "./Components/StateDropdownComponent/ExportPopUp";
import {
  SUGGESTED_ACTIONS,
  TEXT_RESPONSE,
  USER_OPTION_NODE_TYPE,
} from "./constant";
import Toolbox from "./Components/ToolboxComponent/Toolbox";
import { fetchData, getMap } from "./services/dataModel";
import "./Canvas.css";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
  MiniMap,
  Controls,
  ReactFlowProvider,
  Background,
  Panel,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import SuggestedActions from "./Components/NodeTypes/SuggestedActionsComponent/SuggestedActions";
import ConvoInfo from "./Components/NodeTypes/StartNodeComponent/ConversationInformation";
import SuggestedActionElementInfo from "./Components/NodeTypes/SuggestedActionsComponent/SuggestedActionElementInfo";
import TextResponseElementInfo from "./Components/NodeTypes/TextResponseComponent/TextResponseElementInfo";
import ExitNodeElementInfo from "./Components/NodeTypes/ExitNodeComponent/ExitNodeElementInfo";
import MediaResponseNode from "./Components/NodeTypes/MediaResponseComponent/MediaResponseNode";
import StarRatingNode from "./Components/NodeTypes/StarRatingComponent/StarRatingNode";
import { updateParentAndChildNodes } from "./convert";
import axios from "axios";
import offboardingEndNode from "./Components/NodeTypes/OffboardingEndNode/OffboardingEndNode.jsx";
import onboardingStartNode from "./Components/NodeTypes/OnboardingStartNodeComponent/OnboardingStartNode.jsx";
import ExportPDF from "./Components/ExportPDFComponent/ExportPDF";
import { useSelector, useDispatch } from "react-redux";
import OnDeleteElementPopUp from "./Components/StateDropdownComponent/OnDeleteElementPopUp.jsx";
import { showPopUp } from './Redux/slicer/deleteNodeSlicer.js';
import { setIsNodeDraggable } from './Redux/slicer/CreateSlicer';
import { config } from './Redux/slicer/ConfigSlicer';
import { useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import ModalEditConversation from "./Components/ConversationMenu/ModalEditConversation.jsx";
import { set } from "lodash";


// Declaring default suggested actions to be given to suggested actions when initially dropped on the canvas
const defaultSuggestedActions = [
  { text: "Action 1", type: "imBack", flowName: "" },
  { text: "Action 2", type: "imBack", flowName: "" },
  { text: "Action 3", type: "imBack", flowName: "" },
];

const defaultViewport = { x: 0, y: 0, zoom: 1 };

// Declaring default text to be given to text response when initially dropped on the canvas
const defaultTextResponses = [{ text: "New Text" }];

// Variables to keep track of the suggested actions and text response node IDs so that they increment
let suggestedActionsId = 0;
let textResponseId = 0;
let exitNodeId = 0;
let mediaResponseId = 0;
const getSuggestedActionId = () => `SANode_${suggestedActionsId++}`;
const getTextResponseId = () => `TRNode_${textResponseId++}`;
const getExitNodeId = () => `ENNode_${exitNodeId++}`;
const getMediaResponseId = () => `MRNode_${mediaResponseId++}`;

const nodeTypes = {
  textResponse: TextUpdaterNode,
  startNode: StartNode,
  suggestedActions: SuggestedActions,
  exitNode: exitNode,
  mediaResponse: MediaResponseNode,
  offboardingEndNode: offboardingEndNode,
  onboardingStartNode: onboardingStartNode,
  starRatingNode: StarRatingNode
};

function Canvas(props) {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes] = useNodesState(props.nodes, props.setNodes);
  const [edges, setEdges] = useEdgesState(props.edges, props.setEdges);
  const { setViewport, setCenter, setZoom } = useReactFlow();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  );
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [show, setShow] = useState(false);
  const [selectedSuggestedActionsNodeId, setSelectedSuggestedActionsNodeId] =
    useState(null);
  const [
    selectedSuggestedActionsNodeData,
    setSelectedSuggestedActionsNodeData,
  ] = useState("");
  const [
    selectedSuggestedActionsNodeTitle,
    setSelectedSuggestedActionsNodeTitle,
  ] = useState("");
  const [
    selectedSuggestedActionsNodeType,
    setSelectedSuggestedActionsNodeType,
  ] = useState("");
  const [selectedTRNodeId, setSelectedTRNodeId] = useState(null);
  const [textReponseNodeData, setTextResponseNodeData] = useState("");
  const [selectedTRNodeTitle, setTRNodeTitle] = useState("");
  const [feedbackPrompt, setFeedbackPrompt] = useState(false);
  const [selectedStartNode, setSelectedStartNode] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selectedExitNode, setSelectedExitNode] = useState(false);
  const [selectedExitNodeId, setSelectedExitNodeId] = useState(null);
  const [selectedExitNodeTitle, setSelectedExitNodeTitle] = useState("");
  const [
    selectedExitNodeExternalConversation,
    setSelectedExitNodeExternalConversation,
  ] = useState("");
  const [selectedMediaResponseNodeId, setSelectedMediaResponseNodeId] =
    useState(null);
  const [initialViewport, setInitialViewport] = useState(null);
  const [pastElements, setPastElements] = useState([]);
  const [currentElements, setCurrentElements] = useState({
    nodes: [],
    edges: [],
  });
  const [futureElements, setFutureElements] = useState([]);
  const [isUndo, setIsUndo] = useState(false);
  const [isRedo, setIsRedo] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [pastElement, setPastElement] = useState({ nodes: [], edges: [] });
  const [selectedNode, setSelectedNode] = useState(null);
  const [exportModal, setExportModal] = useState(false);
  const [canExportJSON, setCanExportJSON] = useState(false);

  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [deleteNodeID, setDeleteNodeID] = useState(null);

  // get state redux store
  const deleteNode = useSelector((state) => state.deleteNode);

  useEffect(() => {
    if (deleteNode.value) {
      setShowDeletePopUp(true);
      setDeleteNodeID(deleteNode.nodeID);
    } else {
      setShowDeletePopUp(false);
    }

  }, [deleteNode]);

  const onCloseDeletePopUp = () => {
    setShowDeletePopUp(!showDeletePopUp);
    dispatch(showPopUp({ show: false, nodeID: null }));
  };

  const handleNodeSelect = () => {
    setSelectedNode();
  };
  const [editModal, setEditModal] = useState(false);

  const ref = useRef(null);

  const dispatch = useDispatch();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  useEffect(() => {
    //get the id from local storage
    const id = sessionStorage.getItem("Last Opened Conversation:");
    const collection = sessionStorage.getItem("Last Opened Collection:");
    const database = sessionStorage.getItem("Last Opened Database:");
    // Fetch data from the server using Axios
    axios
      .get(`/api/data/id`, {
        params: {
          database: database,
          collection: collection,
          id: id,
        },
      })
      .then((response) => {
        if (response.data && typeof response.data.body === "string") {
          // Parse the body property to convert it into an array
          const data = JSON.parse(response.data.body);
          setNodes(data.nodes);
          setEdges(data.edges);
          props.setNodes(data.nodes);
          props.setEdges(data.edges);

          data.nodes.forEach((node) => {
            //get the id value from node.id
            const id = parseInt(node.id.split("_")[1]);
            if (node.type === "suggestedActions") {
              if (id >= suggestedActionsId) {
                suggestedActionsId = id + 1;
              }
            } else if (node.type === "textResponse") {
              if (id >= textResponseId) {
                textResponseId = id + 1;
              }
            } else if (node.type === "exitNode") {
              if (id >= exitNodeId) {
                exitNodeId = id + 1;
              }
            } else if (node.type === "mediaResponse") {
              if (id >= mediaResponseId) {
                mediaResponseId = id + 1;
              }
            }

            if (node.id === "ROOT" && node.data.conversationWriters) {
              props.setIsConversationWriter(node.data.conversationWriters.includes(account.idTokenClaims.name))
            }
          });

          const x = data.viewport.x;
          const y = data.viewport.y;
          const zoom = data.viewport.zoom;
          setCurrentElements({ nodes: data.nodes, edges: data.edges });
          setViewport({ x, y, zoom });
          setDataAfterFetch(data);
          setIsLoading(false);
          setPastElement({ nodes: data.nodes, edges: data.edges });
        } else {
          console.error(
            "Expected a stringified array but received:",
            response.data
          );
        }
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const setDataAfterFetch = (data) => {
    props.setFlowName(data.nodes[0].data.flowName);
    props.setId(data._id);
    props.setConversationType(data.nodes[0].data.conversationType);
    props.setIntentName(data.nodes[0].data.intentName);
    props.setScore(data.nodes[0].data.score);
    props.setEntities(data.nodes[0].data.entities);
    props.setConversationState(data.nodes[0].data.conversationState);
    props.setVeevaVaultId(data.nodes[0].data.veevaVaultId);
    props.setProduct(data.nodes[0].data.product);
    props.setSavedVersion(data.nodes[0].data.savedVersion);
    props.setProjectType(data.nodes[0].data.projectType);
  };

  //function to allow nodes to be dragged from toolbox
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  // Function to allow nodes to be added when dropped onto the canvas
  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      // Gets the type of the node being dropped, if the type is undefined or null, return
      const type = event.dataTransfer.getData("application/reactflow");
      if (typeof type === "undefined" || !type) {
        return;
      }

      // If the type is suggestedActions, create a new node with a new id, type, position, and default data.
      // Then set the nodes state to the previous nodes state plus the new node
      // And set state variables to allow the suggested actions element info panel to be displayed
      if (type === "suggestedActions") {
        const position = reactFlowInstance.project({
          x:
            event.clientX -
            reactFlowWrapper.current.getBoundingClientRect().left,
          y:
            event.clientY -
            reactFlowWrapper.current.getBoundingClientRect().top,
        });

        const suggestedActionId = getSuggestedActionId();

        const newNode = {
          uuid: v4(),
          id: suggestedActionId,
          type,
          position,
          data: {
            condition: "",
            parentNodeIds: [],
            parentNodes: [],
            JSONNodeId: "",
            indexInNode: null,
            label: `${type} node`,
            elementType: "Suggested Actions",
            elementTitle: "default title",
            elementId: suggestedActionId,
            suggestedActions: [...defaultSuggestedActions],
            errorStatus: false,
            errorMessage: "",
          },
        };

        setNodes((nds) => nds.concat(newNode));
        setSelectedSuggestedActionsNodeId(newNode.id);
        setSelectedSuggestedActionsNodeData(newNode.data.suggestedActions);
        setSelectedSuggestedActionsNodeTitle(newNode.data.elementTitle);
        setSelectedSuggestedActionsNodeType(newNode.data.elementType);

        // here we must set the text response node to null so that the text response element info panel is not displayed at the same time
        setSelectedTRNodeId(null);
        setSelectedExitNode(false);
        setSelectedStartNode(false);
        addNewPastElement();
      }

      // If the type is textResponse, create a new node with a new id, type, position, and default data.
      // Then set the nodes state to the previous nodes state plus the new node
      // And set state variables to allow the text response element info panel to be displayed
      if (type === "textResponse") {
        const position = reactFlowInstance.project({
          x:
            event.clientX -
            reactFlowWrapper.current.getBoundingClientRect().left,
          y:
            event.clientY -
            reactFlowWrapper.current.getBoundingClientRect().top,
        });

        const textResponseId = getTextResponseId();

        const newNode = {
          uuid: v4(),
          id: textResponseId,
          type,
          position,
          data: {
            condition: "",
            parentNodeIds: [],
            parentNodes: [],
            JSONNodeId: "",
            indexInNode: null,
            label: `${type} node`,
            elementType: "Text Response",
            elementTitle: "default title",
            textArray: [...defaultTextResponses],
            elementId: textResponseId,
            errorStatus: false,
            errorMessage: "",
            feedback: false,
          },
        };

        setNodes((nds) => nds.concat(newNode));
        setSelectedTRNodeId(newNode.id);
        setTextResponseNodeData(newNode.data.textArray);
        setTRNodeTitle(newNode.data.elementTitle);
        setFeedbackPrompt(newNode.data.feedback);

        // here we must set the suggested actions node to null so that the suggested actions element info panel is not displayed at the same time
        setSelectedSuggestedActionsNodeId(null);
        setSelectedExitNode(false);
        setSelectedStartNode(false);
        addNewPastElement();
      }

      if (type === "exitNode") {
        const position = reactFlowInstance.project({
          x:
            event.clientX -
            reactFlowWrapper.current.getBoundingClientRect().left,
          y:
            event.clientY -
            reactFlowWrapper.current.getBoundingClientRect().top,
        });

        const exitNodeId = getExitNodeId();

        const newNode = {
          uuid: v4(),
          id: exitNodeId,
          type,
          position,
          data: {
            condition: "",
            parentNodeIds: [],
            parentNodes: [],
            JSONNodeId: "",
            indexInNode: null,
            label: `${type} node`,
            elementType: "exit Node",
            elementTitle: "exit Node",
            errorStatus: false,
            errorMessage: "",
            externalConversation: "",
          },
        };

        setNodes((nds) => nds.concat(newNode));
        setSelectedExitNode(true);
        setSelectedExitNodeId(newNode.id);
        setSelectedExitNodeTitle(newNode.data.elementTitle);
        setSelectedExitNodeExternalConversation(
          newNode.data.externalConversation
        );

        // here we must set the suggested actions node to null so that the suggested actions element info panel is not displayed at the same time
        setSelectedSuggestedActionsNodeId(null);
        setSelectedTRNodeId(null);
        setSelectedStartNode(false);
        addNewPastElement();
      }

      if (type === "mediaResponse") {
        const position = reactFlowInstance.project({
          x:
            event.clientX -
            reactFlowWrapper.current.getBoundingClientRect().left,
          y:
            event.clientY -
            reactFlowWrapper.current.getBoundingClientRect().top,
        });

        const mediaResponseId = getMediaResponseId();

        const newNode = {
          uuid: v4(),
          id: mediaResponseId,
          type,
          position,
          data: {
            condition: "",
            parentNodeIds: [],
            parentNodes: [],
            JSONNodeId: "",
            indexInNode: null,
            label: `${type} node`,
            elementType: "Media Response",
            elementTitle: "Media Response",
            elementsArray: [
              {
                id: 1,
                type: "title",
                text: "",
                editing: false,
                toggleAddButton: false,
                doubleClicked: false,
                undoAction: false,
                undoTimer: 5,
              },
              {
                id: 2,
                type: "paragraph",
                text: "",
                editing: false,
                toggleAddButton: false,
                undoAction: false,
                undoTimer: null,
              },
              {
                id: 3,
                type: "image",
                url: "",
                editing: false,
                toggleAddButton: false,
                imageClicked: false,
                undoAction: false,
                addingImage: false,
                fileName: "",
                imageAdded: false,
                undoTimer: null,
              },
              {
                id: 4,
                type: "button",
                text: "",
                url: "",
                editing: false,
                addingLink: false,
                toggleAddButton: false,
                undoAction: false,
                undoTimer: null,
              },
            ],
            adaptiveCard: false,
            cardData: {
              _id: "",
              flowName: "",
              type: "AdaptiveCard",
              schema: "http://adaptivecards.io/schemas/adaptive-card.json",
              version: "1.3",
              body: [],
            },
            mediaResponseData: [],
            errorStatus: false,
            errorMessage: "",
          },
        };

        setNodes((nds) => nds.concat(newNode));
        setSelectedMediaResponseNodeId(newNode.id);

        // here we must set the suggested actions node to null so that the suggested actions element info panel is not displayed at the same time
        setSelectedSuggestedActionsNodeId(null);
        setSelectedTRNodeId(null);
        setSelectedStartNode(false);
        setSelectedExitNode(false);
        addNewPastElement();
      }
    },
    [
      reactFlowInstance,
      reactFlowWrapper,
      setNodes,
      setSelectedSuggestedActionsNodeId,
      setSelectedSuggestedActionsNodeData,
      setSelectedSuggestedActionsNodeTitle,
      setSelectedSuggestedActionsNodeType,
      setSelectedTRNodeId,
      setTextResponseNodeData,
      setTRNodeTitle,
      setFeedbackPrompt,
      setSelectedExitNodeExternalConversation,
      setSelectedMediaResponseNodeId,
    ]
  );

  // Callback function to be used in element info panels to update the nodes text when the text is changed
  // Depending on the node type, the text is updated in different ways
  const handleElementTextChange = useCallback(
    (updatedOptions) => {
      setIsTyping(true);
      // Find the node in the state and update the text in the respective option
      const updatedNodes = nodes
        ? nodes.map((node) => {
          if (node.id === selectedSuggestedActionsNodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                suggestedActions: updatedOptions,
              },
            };
          } else if (node.id === selectedTRNodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                textArray: updatedOptions,
              },
            };
          }
          return node;
        })
        : [];

      setNodes(updatedNodes);
    },
    [nodes, selectedSuggestedActionsNodeId, setNodes, selectedTRNodeId]
  );

  // Callback function to be used in element info panels to update the nodes title when the title is changed
  // This function works the same for all nodes that have a title
  const handleElementTitleChange = useCallback(
    (updatedTitle) => {
      // Find the node in the state and update the title
      const updatedNodes = nodes
        ? nodes.map((node) => {
          if (
            node.id === selectedSuggestedActionsNodeId ||
            node.id === selectedTRNodeId
          ) {
            const updatedNode = {
              ...node,
              data: {
                ...node.data,
                elementTitle: updatedTitle,
              },
            };
            return updatedNode;
          }
          return node;
        })
        : [];
      setNodes(updatedNodes);
    },
    [nodes, selectedSuggestedActionsNodeId, setNodes, selectedTRNodeId]
  );

  const handleExternalConversationChange = useCallback(
    (newText) => {
      const updatedNodes = nodes
        ? nodes.map((node) => {
          if (node.id === selectedExitNodeId) {
            const updatedNode = {
              ...node,
              data: {
                ...node.data,
                externalConversation: newText,
              },
            };
            return updatedNode;
          }
          return node;
        })
        : [];
      setNodes(updatedNodes);
      addNewPastElement(pastElement);
    },
    [nodes, selectedExitNodeId, setNodes]
  );

  const handleFeedbackChange = useCallback(
    (updatedFeedback) => {
      // Find the node in the state and update the title
      const updatedNodes = nodes
        ? nodes.map((node) => {
          if (
            node.id === selectedSuggestedActionsNodeId ||
            node.id === selectedTRNodeId
          ) {
            const updatedNode = {
              ...node,
              data: {
                ...node.data,
                feedback: updatedFeedback,
              },
            };
            return updatedNode;
          }
          return node;
        })
        : [];
      addNewPastElement(pastElement);
      setNodes(updatedNodes);
    },
    [nodes, selectedSuggestedActionsNodeId, setNodes, selectedTRNodeId]
  );

  // Callback function to be used in element info panels to delete one of the options in the array (for example, a suggested action in a suggested actions)
  // Depending on the node type, the function deletes the respective option
  const handleDeleteOption = useCallback(
    (index) => {
      // Find the node in the state and delete the specific option
      const updatedNodes = nodes
        ? nodes.map((node) => {
          if (node.id === selectedSuggestedActionsNodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                suggestedActions: node.data.suggestedActions.filter(
                  (option, i) => i !== index
                ),
              },
            };
          } else if (node.id === selectedTRNodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                textArray: node.data.textArray.filter(
                  (option, i) => i !== index
                ),
              },
            };
          }
          return node;
        })
        : [];
      addNewPastElement(pastElement);
      setNodes(updatedNodes);
    },
    [nodes, selectedSuggestedActionsNodeId, setNodes, selectedTRNodeId]
  );

  // Callback function to be used in element info panels to add a new option to the array (for example, a suggested action in a suggested actions)
  // Depending on the node type, the function adds the respective option
  const handleAddOption = useCallback(() => {
    // Find the node in the state and add a new option to the array
    const updatedNodes = nodes
      ? nodes.map((node) => {
        if (node.id === selectedSuggestedActionsNodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              suggestedActions: [
                ...node.data.suggestedActions,
                {
                  text: "New Suggested Action",
                  type: "imBack",
                  flowName: "",
                },
              ],
            },
          };
        } else if (node.id === selectedTRNodeId) {
          return {
            ...node,
            data: {
              ...node.data,
              textArray: [...node.data.textArray, { text: "New Text" }],
            },
          };
        }
        return node;
      })
      : [];
    setNodes(updatedNodes);
    addNewPastElement(pastElement);
  });

  // Implement the deletion whole conversation logic
  const handleDeleteConversation = () => {
    const id = sessionStorage.getItem("Last Opened Conversation:");
    const database = sessionStorage.getItem("Last Opened Database:");
    const collection = sessionStorage.getItem("Last Opened Collection:");

    axios
      .delete(`/api/data`, {
        params: {
          database,
          collection,
          id
        }
      })
      .then((response) => {
        console.log("Conversation deleted successfully:", response.data);
        if (response?.data?.statusCode === 200) {
          navigate("/home"); // Navigate to '/home' after deletion
        }
      })
      .catch((error) => {
        // Handle any errors during the API call
        console.error("Failed to delete the conversation:", error);
      });
  };

  // Callback function to be used in element info panels to delete the element (the same function is used by all node types)
  const handleDeleteElement = useCallback(
    (elementId) => {
      // Find the node in the state and delete it
      const updatedNodes = nodes
        ? nodes.filter((node) => node.id !== elementId)
        : [];
      setNodes(updatedNodes);
      setEdges((edges) =>
        edges.filter(
          (edge) => edge.source !== elementId && edge.target !== elementId
        )
      );

      // Reset all state variables tracking the selected node to null, as the element has been deleted
      setSelectedSuggestedActionsNodeId(null);
      setSelectedTRNodeId(null);
      setSelectedExitNodeId(null);
      addNewPastElement();
      dispatch(showPopUp({ show: false, nodeID: null }));
    },
    [nodes, setNodes]
  );

  // Callback function to be used in element info panels to rearrange the options in their array (for example, to change the order of
  // suggested actions)
  const onMoveOption = useCallback(
    (oldIndex, newIndex) => {
      // Create a deep copy of the nodes array
      const updatedNodes = nodes ? JSON.parse(JSON.stringify(nodes)) : [];

      // Find the node in the state and update the order of the options
      updatedNodes.map((node) => {
        if (node.id === selectedSuggestedActionsNodeId) {
          node.data.suggestedActions = arrayMove(
            node.data.suggestedActions,
            oldIndex,
            newIndex
          );
        }
        return node;
      });

      setNodes(updatedNodes);
      addNewPastElement();
    },
    [nodes, setNodes]
  );

  // array move function used in onMoveOption, to swap indexes of elements in an array
  function arrayMove(arr, oldIndex, newIndex) {
    const element = arr[oldIndex];
    arr.splice(oldIndex, 1);
    arr.splice(newIndex, 0, element);
    return arr;
  }

  // Callback function which sets the values of all state variables of the respective node to the values of the node
  // This function is called when a node is right clicked, and will cause the element info panel to be displayed,
  // Which is conditionally rendered based on the state variables value
  const onNodeContextMenu = useCallback((event, node) => {
    // Prevent native context menu from showing
    event.preventDefault();
    onNodeClick('', node)
    if (node.type === "suggestedActions") {
      setSelectedSuggestedActionsNodeId(node.id);
      setSelectedSuggestedActionsNodeData(node.data.suggestedActions);
      setSelectedSuggestedActionsNodeTitle(node.data.elementTitle);
      setSelectedSuggestedActionsNodeType(node.data.elementType);
      setSelectedTRNodeId(null);
      setTextResponseNodeData(null);
      setTRNodeTitle(null);
      setSelectedStartNode(false);
      setSelectedExitNode(false);
      setSelectedExitNodeId(null);
      setSelectedExitNodeTitle(null);
      setSelectedExitNodeExternalConversation(null);
    } else if (node.type === "textResponse") {
      setSelectedTRNodeId(node.id);
      setTextResponseNodeData(node.data.textArray);
      setTRNodeTitle(node.data.elementTitle);
      setFeedbackPrompt(node.data.feedback);
      setSelectedSuggestedActionsNodeId(null);
      setSelectedSuggestedActionsNodeData(null);
      setSelectedSuggestedActionsNodeTitle(null);
      setSelectedSuggestedActionsNodeType(null);
      setSelectedStartNode(false);
      setSelectedExitNode(false);
      setSelectedExitNodeId(null);
      setSelectedExitNodeTitle(null);
      setSelectedExitNodeExternalConversation(null);
    } else if (node.type === "startNode") {
      setSelectedStartNode(true);
      setSelectedSuggestedActionsNodeId(null);
      setSelectedSuggestedActionsNodeData(null);
      setSelectedSuggestedActionsNodeTitle(null);
      setSelectedSuggestedActionsNodeType(null);
      setSelectedTRNodeId(null);
      setTextResponseNodeData(null);
      setTRNodeTitle(null);
      setSelectedExitNode(false);
      setSelectedExitNodeId(null);
      setSelectedExitNodeTitle(null);
      setSelectedExitNodeExternalConversation(null);
    } else if (node.type === "exitNode") {
      setSelectedExitNodeId(node.id);
      setSelectedExitNodeTitle(node.data.elementTitle);
      setSelectedExitNodeExternalConversation(node.data.externalConversation);
      setSelectedExitNode(true);
      setSelectedStartNode(false);
      setSelectedSuggestedActionsNodeId(null);
      setSelectedSuggestedActionsNodeData(null);
      setSelectedSuggestedActionsNodeTitle(null);
      setSelectedSuggestedActionsNodeType(null);
      setSelectedTRNodeId(null);
      setTextResponseNodeData(null);
      setTRNodeTitle(null);
    } else if (node.type === "mediaResponse") {
      setSelectedMediaResponseNodeId(node.id);
      setSelectedStartNode(false);
      setSelectedSuggestedActionsNodeId(null);
      setSelectedSuggestedActionsNodeData(null);
      setSelectedSuggestedActionsNodeTitle(null);
      setSelectedSuggestedActionsNodeType(null);
      setSelectedTRNodeId(null);
      setTextResponseNodeData(null);
      setTRNodeTitle(null);
      setSelectedExitNode(false);
      setSelectedExitNodeId(null);
      setSelectedExitNodeTitle(null);
      setSelectedExitNodeExternalConversation(null);
    }
  }, [nodes]);

  // Callback function which sets all state variables to null when the pane is clicked,
  // So that an element info panel is no longer displayed when the focus is not on a node
  const onPaneClick = useCallback(() => {
    setSelectedSuggestedActionsNodeId(null);
    setSelectedTRNodeId(null);
    setSelectedStartNode(false);
    setSelectedExitNode(false);
    setIsTyping(false);
    onNodeClick('', '')
  }, [
    setSelectedSuggestedActionsNodeId,
    setSelectedTRNodeId,
    setSelectedStartNode,
    setSelectedExitNode,
    setIsTyping,
    nodes
  ]);

  const onConnect = useCallback(
    (params) => {
      setEdges((edges) => {
        let isSuggestedAction =
          params.source && params.source.startsWith("SANode");
        let counterpartHandleId = null;
        params.type = "smoothstep";
        params.style = { stroke: "black", strokeWidth: 1.5 };
        if (isSuggestedAction) {
          //find if a source already exists with the same sourceHandle
          const isSourceHandleConnected = edges.some(
            (edge) => edge.sourceHandle === params.sourceHandle
          );

          if (params.sourceHandle.endsWith("-R")) {
            counterpartHandleId = params.sourceHandle.replace("-R", "-L");
          } else {
            counterpartHandleId = params.sourceHandle.replace("-L", "-R");
          }

          const isCounterpartHandleConnected = edges.some(
            (edge) => edge.sourceHandle === counterpartHandleId
          );

          if (isCounterpartHandleConnected || isSourceHandleConnected) {
            return edges;
          }
        }

        const newEdges = addEdge(params, edges);

        return newEdges;
      });
      addNewPastElement();
    },
    [setEdges]
  );

  const onEdgesDelete = useCallback((edge) => {
    setEdges((edges) => edges.filter((e) => e.id !== edge.id));
    addNewPastElement();
  }, []);

  const onToggleInteractivity = () => {
    setIsEditable(!isEditable);
  };

  const exportJson = (event) => {
    addNewPastElement(pastElement);
    event.preventDefault();
    setExportModal(false);
    const typeOfExport = "json download"
    updateParentAndChildNodes(
      edges,
      nodes,
      props.lastExportNodes,
      props.lastExportEdges,
      props.setLastExportEdges,
      props.setLastExportNodes,
      typeOfExport
    );
  };

  const onClose = () => {
    setExportModal(false);
  };

  const onExport = async () => {
    const canExportJSON = await dispatch(config({ functionality: 'export_JSON', roles: account.idTokenClaims.roles }));

    if (canExportJSON) {
      setCanExportJSON(true);
    }
    let isDownload = true
    nodes.forEach(ele => {
      const { elementType, parentNodeIds, parentNodes } = ele?.data || {}
      if (elementType === 'exit Node' && !parentNodeIds?.length && !parentNodes.length) {
        isDownload = false
      }
    })
    // if (!isDownload) {
    //   setIsDownloadTip(true)
    //   return
    // }
    setExportModal(true);
  };

  useEffect(() => {
    if (!nodes) {
      return;
    } else {
      const updatedNodes = nodes.map((node) => {
        const matchingNode = props.lastExportNodes.find(
          (lastExportNode) => lastExportNode.id === node.id
        );
        if (matchingNode) {
          return {
            ...node,
            data: {
              ...node.data,
              errorStatus: matchingNode.data.errorStatus,
              errorMessage: matchingNode.data.errorMessage,
            },
          };
        } else {
          return node;
        }
      });
      setNodes(updatedNodes);
    }
  }, [props.lastExportNodes]);

  useEffect(() => {
    if (!nodes) {
      return;
    } else {
      nodes.forEach((node) => {
        if (
          node.data.errorMessage ===
          "This node is not connected to a parent node. Please connect/re-connect to the parent node."
        ) {
          const parentNodeFound = edges.find((edge) => edge.target === node.id);
          if (parentNodeFound) {
            node.data.errorStatus = false;
            node.data.errorMessage = "";
            return node;
          }
        } else if (
          node.data.errorMessage ===
          "One or more suggested actions are not connected to a child node. Please check the diagram and resolve the errors."
        ) {
          let countOfConnectedChildren = 0;
          edges.forEach((edge) => {
            if (edge.source === node.id) {
              countOfConnectedChildren++;
            }
          });

          if (countOfConnectedChildren === node.data.suggestedActions.length) {
            node.data.errorStatus = false;
            node.data.errorMessage = "";
            return node;
          }
        }
      });
      setNodes(nodes);
    }
  }, [edges]);

  useEffect(() => {
    if (!nodes) {
      return;
    } else {
      //update the root node to include the latest conversation information
      let updatedNodes = nodes.map((node) => {
        if (node.id === "ROOT") {
          return {
            ...node,
            data: {
              ...node.data,
              _id: props.id,
              flowName: props.flowName,
              conversationType: props.conversationType,
              intentName: props.intentName,
              score: props.score,
              entities: props.entities,
              veevaVaultId: props.veevaVaultId,
              product: props.product,
              savedVersion: props.savedVersion,
              projectType: props.projectType,
            },
          };
        }
        return node;
      });
      setNodes(updatedNodes);
    }
  }, [props.flowName, props.product, props.savedVersion]);

  const onSave = useCallback(() => {
    addNewPastElement(pastElement);
    const _id = sessionStorage.getItem("Last Opened Conversation:");
    const database = sessionStorage.getItem("Last Opened Database:");
    const collection = sessionStorage.getItem("Last Opened Collection:");

    const flowData = {
      ...reactFlowInstance.toObject(),
    };

    const nodes = flowData.nodes;
    const edges = flowData.edges;
    const x = flowData.viewport.x;
    const y = flowData.viewport.y;
    const zoom = flowData.viewport.zoom;

    const conversationData = {
      _id: _id,
      nodes: nodes,
      edges: edges,
      viewport: {
        x: x,
        y: y,
        zoom: zoom,
      },
    };

    axios
      .post("/api/data", conversationData, {
        params: {
          database: database,
          collection: collection,
          postType: "save",
        },
      })
      .then((res) => {
        sessionStorage.setItem("Last Opened Conversation:", _id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [reactFlowInstance, nodes, props.product, props.projectType]);

  const nodeMoved = useRef(false);

  const onNodeDrag = useCallback((event, node) => {
    nodeMoved.current = true;
  }, []);

  const onNodeDragStart = useCallback((event, node) => { }, []);

  const onNodeDragStop = useCallback(
    (event, node) => {
      if (nodeMoved.current) {
        addNewPastElement(pastElement);
        nodeMoved.current = false;
      }
    },
    [pastElement, nodes, edges, addNewPastElement, setPastElement]
  );

  const onNodesDelete = useCallback(
    (nodesToDelete) => {
      setNodes((nodes) =>
        nodes.filter((node) => !nodesToDelete.includes(node.id))
      );
      setEdges((edges) =>
        edges.filter(
          (edge) =>
            edge.source !== nodesToDelete[0] && edge.target !== nodesToDelete[0]
        )
      );
      addNewPastElement();
    },
    [setNodes, setEdges]
  );

  const undoRedoOperation = useRef(0);

  function onUndo() {
    setSelectedSuggestedActionsNodeId(null);
    setSelectedTRNodeId(null);
    setSelectedStartNode(false);
    setSelectedExitNode(false);
    if (pastElements.length > 0) {
      setElementsAfterUndoRedo("undo");
    } else {
      alert("No more undos available");
    }
  }

  function onRedo() {
    setSelectedSuggestedActionsNodeId(null);
    setSelectedTRNodeId(null);
    setSelectedStartNode(false);
    setSelectedExitNode(false);
    if (futureElements.length > 0) {
      setElementsAfterUndoRedo("redo");
    } else {
      alert("No more redos available");
    }
  }

  function setElementsAfterUndoRedo(undoOrRedo) {
    if (undoOrRedo === "undo") {
      const lastElement = pastElements[pastElements.length - 1];
      setFutureElements([...futureElements, currentElements]);
      setPastElements(pastElements.slice(0, pastElements.length - 1));
      setIsUndo(true);
      setCurrentElements(lastElement);
      setPastElement(lastElement);
      setNodes(lastElement.nodes);
      setEdges(lastElement.edges);
    } else if (undoOrRedo === "redo") {
      const nextElement = futureElements[futureElements.length - 1];
      setPastElements([...pastElements, currentElements]);
      setFutureElements(futureElements.slice(0, futureElements.length - 1));
      setIsRedo(true);
      setCurrentElements(nextElement);
      setPastElement(nextElement);
      setNodes(nextElement.nodes);
      setEdges(nextElement.edges);
    }
  }

  useEffect(() => {
    props.setNodes(nodes);
    props.setEdges(edges);
  }, [nodes, edges]);

  const addNewPastElementInProgress = useRef(false);

  function addNewPastElement() {
    if (isTyping) {
      setPastElements((pastElements) => [...pastElements, pastElement]);
      setCurrentElements({ nodes: nodes, edges: edges });
      setPastElement({ nodes: nodes, edges: edges });
      setFutureElements([]);
      setIsTyping(false);
      handlingClickAfterTyping.current = false;
    } else {
      addNewPastElementInProgress.current = true;
    }
  }

  const handlingClickAfterTyping = useRef(false);

  useEffect(() => {
    if (addNewPastElementInProgress.current) {
      setPastElements((pastElements) => [...pastElements, pastElement]);
      setCurrentElements({ nodes: nodes, edges: edges });
      setPastElement({ nodes: nodes, edges: edges });
      setFutureElements([]);
      addNewPastElementInProgress.current = false;
    }
  }, [nodes, edges]);

  function handleCanvasClick() {
    if (isTyping) {
      handlingClickAfterTyping.current = true;
      addNewPastElement();
    }
  }

  const [showPDF, setShowPDF] = useState(false);

  const exportPDF = () => {
    setShowPDF(true);
  };

  const reDownload = () => {
    setShowPDF(false);
  };

  const resetOhterPannl = (id) => {
    if (id === selectedExitNodeId) {
      setSelectedExitNode(true)
      setSelectedSuggestedActionsNodeId(null)
      setSelectedTRNodeId(null)
      setSelectedStartNode(false)
    } else if (id === selectedSuggestedActionsNodeId) {
      setSelectedSuggestedActionsNodeId(selectedSuggestedActionsNodeId)
      setSelectedExitNode(true)
      setSelectedTRNodeId(null)
      setSelectedStartNode(false)
    } else if (id === selectedTRNodeId) {
      setSelectedTRNodeId(selectedTRNodeId)
      setSelectedExitNode(false)
      setSelectedSuggestedActionsNodeId(null)
      setSelectedStartNode(false)
    } else if (id === 'ROOT') {
      setSelectedStartNode(true)
      setSelectedExitNode(false)
      setSelectedSuggestedActionsNodeId(null)
      setSelectedTRNodeId(null)
    } else {
      setSelectedExitNode(false)
      setSelectedSuggestedActionsNodeId(null)
      setSelectedTRNodeId(null)
      setSelectedStartNode(false)
    }
  }

  // Node Click
  const onNodeClick = (event, element) => {
    let updatedNodes = nodes.map((node) => {
      return {
        ...node,
        data: {
          ...node.data,
          boxShadow: node.id === element?.id,
        },
      };
    });
    setNodes(updatedNodes);
    resetOhterPannl(element?.id)
  };

  const openConversation = () => {
    if (selectedExitNode) setSelectedExitNode(false)
    if (selectedSuggestedActionsNodeId) setSelectedSuggestedActionsNodeId(false)
    if (selectedTRNodeId) setSelectedTRNodeId(false)
    setSelectedStartNode(true)
  }

  const toggleEditSaveBtn = async () => {
    const result = await dispatch(config({ functionality: 'edit_Button', roles: account.idTokenClaims.roles }));
    if (result.payload) {
      if (isEditable) {
        onSave();
      }
      setEditModal(false);
      onToggleInteractivity();
      const rootNode = nodes.find(node => node.id === 'ROOT');
      if (rootNode && !rootNode.data.conversationWriters.includes(account.idTokenClaims.name)) {
        const updatedNode = {
          ...rootNode,
          data: {
            ...rootNode.data,
            conversationWriters: [...rootNode.data.conversationWriters, account.idTokenClaims.name]
          }
        };
        const updatedNodes = nodes.map(node => node.id === rootNode.id ? updatedNode : node);
        setNodes(updatedNodes);
        onSave();
      }
    } else {
      setEditModal(false);
      alert('You do not have permission to edit this conversation.');
    }
  };

  useEffect(() => {
    let isCancelled = false;

    if (!reactFlowInstance) {
      return;
    } else {
      const newRootNode = props.nodes.find(node => node.id === 'ROOT');
      const updatedNodes = nodes.map(node => node.id === newRootNode.id ? newRootNode : node);
      setNodes(updatedNodes);

      setTimeout(() => {
        if (!isCancelled && isEditable) {
          onToggleInteractivity();
          onSave();
        } else if (!isCancelled) {
          onSave();
        }
      }, 100);
    }

    return () => {
      isCancelled = true;
    };
  }, [props.conversationState]);

  return (
    <>
      {isLoading && "loading..."}
      {!isLoading && (
        <div className="canvas-container" onClick={handleCanvasClick}>
          <div className="dndflow">
            <Panel className="toolbox-panel">
              <Toolbox isConnectable={true} isEditable={isEditable} />
            </Panel>
            <Panel>
              <ConversationMenu
                openConversation={openConversation}
                isEditable={isEditable}
                onExport={onExport}
                onUndo={onUndo}
                onRedo={onRedo}
                addNewPastElement={addNewPastElement}
                setEditModal={setEditModal}
                toggleEditSaveBtn={toggleEditSaveBtn}
                isConversationWriter={props.isConversationWriter}
                conversationState={props.conversationState}
                onDeleteConversation={handleDeleteConversation}
                onSave={onSave}
                onToggleInteractivity={onToggleInteractivity}
              />
            </Panel>
            <Panel className="exitNode-panel">
              {selectedExitNode ? (
                <ExitNodeElementInfo
                  elementId={selectedExitNodeId}
                  elementType={"Exit Node"}
                  elementTitle={selectedExitNodeTitle}
                  externalConversation={selectedExitNodeExternalConversation}
                  onExternalConversationChange={
                    handleExternalConversationChange
                  }
                  onDeleteElement={handleDeleteElement}
                  isTyping={isTyping}
                  isEditable={isEditable}
                  setIsTyping={setIsTyping}
                  addNewPastElement={addNewPastElement}
                  instance={props.instance}
                />
              ) : null}
            </Panel>
            <Panel className="suggestedAction-panel">
              {selectedSuggestedActionsNodeId ? (
                <SuggestedActionElementInfo
                  elementId={selectedSuggestedActionsNodeId}
                  elementType={selectedSuggestedActionsNodeType}
                  elementTitle={selectedSuggestedActionsNodeTitle}
                  suggestedActions={selectedSuggestedActionsNodeData}
                  onOptionTextChange={handleElementTextChange}
                  onTitleTextChange={handleElementTitleChange}
                  onDeleteOption={handleDeleteOption}
                  onAddOption={handleAddOption}
                  onDeleteElement={handleDeleteElement}
                  onMoveOption={onMoveOption}
                  isEditable={isEditable}
                  isTyping={isTyping}
                  setIsTyping={setIsTyping}
                  addNewPastElement={addNewPastElement}
                />
              ) : null}
            </Panel>
            <Panel className="textResponse-panel">
              {selectedTRNodeId ? (
                <TextResponseElementInfo
                  elementId={selectedTRNodeId}
                  elementType={"Text Response"}
                  elementTitle={selectedTRNodeTitle}
                  textArray={textReponseNodeData}
                  onTitleTextChange={handleElementTitleChange}
                  onDeleteElement={handleDeleteElement}
                  onOptionTextChange={handleElementTextChange}
                  onDeleteOption={handleDeleteOption}
                  onAddOption={handleAddOption}
                  feedbackChange={handleFeedbackChange}
                  feedback={feedbackPrompt}
                  isEditable={isEditable}
                  isTyping={isTyping}
                  setIsTyping={setIsTyping}
                  addNewPastElement={addNewPastElement}
                  onNodeSelect={handleNodeSelect}
                />
              ) : null}
            </Panel>
            <Panel className="convoInfo-panel">
              {selectedStartNode ? (
                <ConvoInfo
                  id={props.id}
                  conversationType={props.conversationType}
                  conversationState={props.conversationState}
                  veevaVaultId={props.veevaVaultId}
                  flowName={props.flowName}
                  setFlowName={props.setFlowName}
                  product={props.product}
                  setProduct={props.setProduct}
                  savedVersion={props.savedVersion}
                  setSavedVersion={props.setSavedVersion}
                  isEditable={isEditable}
                  isTyping={isTyping}
                  setIsTyping={setIsTyping}
                  addNewPastElement={addNewPastElement}
                  onDeleteConversation={handleDeleteConversation}
                />
              ) : null}
            </Panel>
            <Background
              className="canvas-background"
              color="#ccc"
              variant={"lines"}
            />
            <div className="reactflow-wrapper" ref={reactFlowWrapper}>
              <ReactFlow
                isInter
                ref={ref}
                nodes={nodes}
                onNodesChange={onNodesChange}
                edges={edges}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onInit={setReactFlowInstance}
                nodeTypes={nodeTypes}
                fitView={false}
                onDrop={onDrop}
                onDragOver={onDragOver}
                elementsSelectable={true}
                onNodeContextMenu={onNodeContextMenu}
                onPaneClick={onPaneClick}
                nodesConnectable={isEditable}
                nodesDraggable={isEditable}
                minZoom={0.1}
                onNodeDrag={onNodeDrag}
                onNodeDragStart={onNodeDragStart}
                onNodeDragStop={onNodeDragStop}
                onNodesDelete={onNodesDelete}
                onEdgesDelete={onEdgesDelete}
                onNodeClick={onNodeClick}
                connectionLineStyle={{ stroke: 'black', strokeWidth: 1.5 }}
                connectionLineType="smoothstep"
              >
                <Controls />
                <MiniMap
                  nodeStrokeColor={(n) => {
                    if (n.style?.background) return n.style.background;
                    if (n.type === "startNode") return "#263f6a";
                    if (n.type === "textResponse") return "rgb(93, 184, 231)";
                    if (n.type === "suggestedActions")
                      return "rgb(255, 147, 82)";

                    return "rgb(158, 117, 244)";
                  }}
                  nodeColor={(n) => {
                    if (n.style?.background) return n.style.background;
                    if (n.type === "startNode") return "#263f6a";
                    if (n.type === "textResponse") return "rgb(93, 184, 231)";
                    if (n.type === "suggestedActions")
                      return "rgb(255, 147, 82)";

                    return "rgb(158, 117, 244)";
                  }}
                  nodeBorderRadius={3}
                  position="bottom-left"
                  style={{ marginLeft: "45px" }}
                  ariaLabel={"Conversation builder"}
                  zoomable
                  pannable
                />
              </ReactFlow>
              {showPDF && (
                <ExportPDF reDownload={reDownload} flowName={props.flowName} />
              )}
            </div>
          </div>
          {exportModal ? (
            <ExportPopUp onClose={onClose} onExport={onExport} exportPDF={exportPDF} exportJson={exportJson} canExportJSON={canExportJSON} flowName={props.flowName} />
          ) : null}
          {editModal ? (
            <ModalEditConversation setEditModal={setEditModal} toggleEditSaveBtn={toggleEditSaveBtn} />
          ) : null}
        </div>
      )}
      <OnDeleteElementPopUp show={showDeletePopUp} elementId={deleteNodeID} deleteElement={() => handleDeleteElement(deleteNodeID)} onClose={onCloseDeletePopUp}></OnDeleteElementPopUp>
    </>
  );
}

export default (props) => (
  <ReactFlowProvider>
    <Canvas
      id={props.id}
      flowName={props.flowName}
      conversationType={props.conversationType}
      intentName={props.intentName}
      score={props.score}
      entities={props.entities}
      conversationState={props.conversationState}
      veevaVaultId={props.veevaVaultId}
      product={props.product}
      savedVersion={props.savedVersion}
      projectType={props.projectType}
      setId={props.setId}
      setFlowName={props.setFlowName}
      setConversationType={props.setConversationType}
      setIntentName={props.setIntentName}
      setScore={props.setScore}
      setEntities={props.setEntities}
      setConversationState={props.setConversationState}
      setVeevaVaultId={props.setVeevaVaultId}
      setProduct={props.setProduct}
      setSavedVersion={props.setSavedVersion}
      setProjectType={props.setProjectType}
      nodes={props.nodes}
      setNodes={props.setNodes}
      edges={props.edges}
      setEdges={props.setEdges}
      instance={props.instance}
      isConversationWriter={props.isConversationWriter}
      setIsConversationWriter={props.setIsConversationWriter}
      lastExportEdges={props.lastExportEdges}
      lastExportNodes={props.lastExportNodes}
      setLastExportEdges={props.setLastExportEdges}
      setLastExportNodes={props.setLastExportNodes}
    />
  </ReactFlowProvider>
);