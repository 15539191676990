import React, { useRef, useState, useEffect } from "react";
import "./MediaResponseNode.css";
import { RxHamburgerMenu } from "react-icons/rx";
import { PiParagraphBold } from "react-icons/pi";
import { IoMdAdd } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { GoBold } from "react-icons/go";
import { FaItalic } from "react-icons/fa6";
import { MdFormatUnderlined } from "react-icons/md";
import { RiLinkM } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { isNodeDraggableSlice, setIsNodeDraggable } from "../../../Redux/slicer/CreateSlicer";
import { MdUndo } from "react-icons/md";
const v4 = require('uuid').v4;


const paragraphElement = ({index, elementsArray, handleElementChange, handleElementBlur, handleDoubleClick, handleHoverElement, handleHoverOffElement, paragraphTextareaRef, handleDeleteElement, handleToggleAddParaButton, handleUndoAction, handleAddElements}) => (
    <div key={`paragraph_${index}`} className="media-response-element-container" draggable="true"
        onMouseEnter={() => handleHoverElement(index)}
        onMouseLeave={() => handleHoverOffElement(index)}
    >
        <div className="media-response-element-wrapper"
        >
            <RxHamburgerMenu size={20} color="black" />
            <div className={`media-element`}
                onMouseEnter={() => handleHoverElement(index)}
                onMouseLeave={() => handleHoverOffElement(index)}
                width="80%"
            >
                <textarea className="media-response-element paragraph" placeholder="This is some text that may be used to describe the images, videos, or why the user should click the button one card."
                    value={elementsArray[index].text} onChange={(event) => handleElementChange(event, index)} onDoubleClick={() => handleDoubleClick(index)}
                    readOnly={!elementsArray[index].doubleClicked} onBlur={() => handleElementBlur(index)}
                    ref={paragraphTextareaRef}
                    rows={1}
                    width="80%"
                    style={{ overflow: "hidden", opacity: elementsArray[index].undoAction === true ? "0.25" : "1"}}
                />
            </div>

        </div>
        {(elementsArray[index].editing === true && !elementsArray[index].doubleClicked) || elementsArray[index].undoAction === true ?
            <>
                <div className="media-element-edit"
                    onMouseEnter={() => handleHoverElement(index)}
                >
                    <div className="icon-container">
                        <PiParagraphBold color="grey" />
                    </div>
                    <div className="add-container">
                        <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                        <button className={`add-btn${elementsArray[index].toggleAddButton === true ? " clicked" : ""}`} onClick={() => handleToggleAddParaButton(index)}>
                            {elementsArray[index].toggleAddButton === true ? <IoClose color="white" size={25} /> : <IoMdAdd color="white" size={25} />}
                        </button>
                        <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                        <div className="delete-container">
                            {elementsArray[index].undoAction === true ? <button className="delete-btn undo" onClick={() => handleUndoAction(index)}> <MdUndo color="white" size={25} /></button> :
                                <button className="delete-btn" onClick={() => handleDeleteElement(index)}>
                                    <MdDelete color="white" size={25} />
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="media-add-elements-area"
                    onMouseEnter={() => handleHoverElement(index)}
                    onMouseLeave={() => handleHoverOffElement(index)}
                >
                    {elementsArray[index].toggleAddButton === true && elementsArray[index].editing === true ? handleAddElements(handleHoverElement, index) : null}
                </div>
            </>
            :
            elementsArray[index].editing === true ?
                <div className="media-element-paragrap-formatting">
                    <button className="format-options bold-btn"> <GoBold color="white" /></button>
                    <button className="format-options"> <FaItalic color="white" /></button>
                    <button className="format-options"><MdFormatUnderlined color="white" /></button>
                    <button className="format-options link-btn" > <RiLinkM color="white" /></button>
                </div>
                :
                null
        }
    </div>
);

export default paragraphElement;