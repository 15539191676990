import { configureStore } from '@reduxjs/toolkit';
import isNodeDraggableReducer from './slicer/CreateSlicer';
import  {configReducer}  from './slicer/ConfigSlicer';
import deleteNode from "./slicer/deleteNodeSlicer";
// import modifiedDateReducer from './reducers'

export const store = configureStore({
  reducer: {
    isNodeDraggable: isNodeDraggableReducer,
    config: configReducer,
    deleteNode: deleteNode
  },
  // modifiedDateReducer
});
