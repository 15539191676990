import React, { useRef, useState, useEffect } from "react";
import "./MediaResponseNode.css";
import { PiParagraphBold } from "react-icons/pi";
import { IoMdAdd } from "react-icons/io";
import { RxText } from "react-icons/rx";
import { LuImage } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { hover } from "@testing-library/user-event/dist/hover";
import { GoBold } from "react-icons/go";
import { FaItalic } from "react-icons/fa6";
import { MdFormatUnderlined } from "react-icons/md";
import { RiLinkM } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { isNodeDraggableSlice, setIsNodeDraggable } from "../../../Redux/slicer/CreateSlicer";
import { MdOutlinePlayCircle } from "react-icons/md";
import { PiRadioButtonLight } from "react-icons/pi";
import { MdUndo } from "react-icons/md";
const v4 = require('uuid').v4;



const TitleElement = ({ index, elementsArray, handleTitleChange, handleElementBlur, handleDoubleClick, handleHoverElement, handleHoverOffElement, titleTextareaRef, handleDeleteElement, handleToggleAddParaButton, handleUndoAction, handleAddElement }) => (
    <div key={`title_${index}`} className="media-response-element-title-container" draggable="true" id="title1">
        <textarea className="media-response-element title" placeholder="This is the Media Card Title"
            id={`input-${index}`}
            value={elementsArray[index].text} onChange={(event) => handleTitleChange(event, index)} onDoubleClick={() => { handleDoubleClick(index) }}
            readOnly={!elementsArray[index].doubleClicked} onBlur={() => handleElementBlur(index)}
            ref={titleTextareaRef}
            rows={1}
            onMouseEnter={() => handleHoverElement(index)}
            onMouseLeave={() => handleHoverOffElement(index)}
            width="80%"
            style={{ overflow: "hidden" }}

        />
        {
            (elementsArray[index].editing === true && !elementsArray[index].doubleClicked) || elementsArray[index].undoAction === true ?
                <>
                    <div className="media-element-title-edit"
                        onMouseEnter={() => handleHoverElement(index)}
                    >
                        <div className="icon-container">
                            <RxText color="grey" />
                        </div>
                        <div className="add-container">
                            <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                            <button className={`add-btn${elementsArray[index].toggleAddButton === true ? " clicked" : ""}`} onClick={() => handleToggleAddParaButton(index)}>
                                {elementsArray[index].toggleAddButton === true ? <IoClose color="white" size={25} /> : <IoMdAdd color="white" size={25} />}
                            </button>
                            <hr style={{ border: 'none', borderTop: '4px dotted #5DB8E7', width: '20%', margin: '0 10px', }} />
                            <div className="delete-container">
                                {elementsArray[index].undoAction === true ? (
                                    <>
                                        {/* <div className="timer-container">
                                            <p className="timer">{elementsArray[index].undoTimer}s</p>
                                        </div> */}
                                        <button className="delete-btn undo" onClick={() => handleUndoAction(index)}>
                                            <MdUndo color="white" size={25} />
                                        </button>
                                    </>
                                ) :
                                    <button className="delete-btn" onClick={() => handleDeleteElement(index)}>
                                        <MdDelete color="white" size={25} />
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="media-add-elements-area"
                        onMouseEnter={() => handleHoverElement(index)}
                        onMouseLeave={() => handleHoverOffElement(index)}
                    >
                        {elementsArray[index].toggleAddButton === true && elementsArray[index].editing === true ?
                            <div className="title-add-elements-container"
                                onMouseEnter={() => handleHoverElement(index)}
                            >
                                <button className="format-options bold-btn" onClick={() => handleAddElement("title", index)}> <RxText color="white" /></button>
                                <button className="format-options" onClick={() => handleAddElement("paragraph", index)}> <PiParagraphBold color="white" /></button>
                                <button className="format-options" onClick={() => handleAddElement("image", index)}><LuImage color="white" /></button>
                                <button className="format-options" > <MdOutlinePlayCircle color="white" /></button>
                                <button className="format-options link-btn" onClick={() => handleAddElement("button", index)}> <PiRadioButtonLight color="white" /></button>
                            </div> : null}
                    </div>
                </>
                :
                elementsArray[index].doubleClicked === true ?
                    <div className="media-element-title-formatting">
                        <button className="format-options bold-btn"> <GoBold color="white" /></button>
                        <button className="format-options"> <FaItalic color="white" /></button>
                        <button className="format-options"><MdFormatUnderlined color="white" /></button>
                        <button className="format-options link-btn" > <RiLinkM color="white" /></button>
                    </div>
                    :
                    null
        }
    </div>
);

export default TitleElement;
