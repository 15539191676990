import React, { useRef, useState, useEffect } from "react";
import { Background, Handle, Position } from "reactflow";
import "../../../index.css";
import "./MediaResponseNode.css";
import { MdHeight, MdOutlineImage } from "react-icons/md";
import { PiParagraphBold } from "react-icons/pi";
import { RxText } from "react-icons/rx";
import { LuImage } from "react-icons/lu";
import { useDispatch, useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import { isNodeDraggableSlice, setIsNodeDraggable } from "../../../Redux/slicer/CreateSlicer";
import { MdOutlinePlayCircle } from "react-icons/md";
import { PiRadioButtonLight } from "react-icons/pi";
import TitleElement from "./TitleElement";
import ParagraphElement from "./ParagraphElement";
import ImageElement from "./ImageElement";
import ButtonElement from "./ButtonElement";
import axios from "axios";
import {showPopUp} from '../../../Redux/slicer/deleteNodeSlicer';
import { MdDeleteOutline } from "react-icons/md";
const v4 = require('uuid').v4;


const MediaResponseNode = ({ data, isConnectable, id }) => {

    const [enableDelete, setEnableDelete] = useState(false);
    const dispatch = useDispatch();

    const [elementsArray, setElementsArray] = useState(() => {
        return data.elementsArray || [];
    })

    const undoActionRef = useRef({});


    const [mediaResponseData, setMediaResponseData] = useState({
        type: "text",
        text: data.mediaResponseData.text
    });

     // getting the S3 file names from the backend 
     const getFiles = async () => {
        try {
            const response = await axios.get('/api/files');
            return response.data;
        } catch (error) {
            console.error("Error getting files", error);
            throw error; // Ensure errors are propagated correctly
        }
    }


    const titleTextareaRef = useRef(null);
    const paragraphTextareaRef = useRef(null);

    // const dispatch = useDispatch();

    const handleHoverElement = (index) => {
        const updateElement = {
            ...elementsArray[index],
            editing: true
        }

        setElementsArray([
            ...elementsArray.slice(0, index),
            updateElement,
            ...elementsArray.slice(index + 1)
        ]);
    }

    const handleHoverOffElement = (index) => {
        const updateElement = {
            ...elementsArray[index],
            editing: false,
            toggleAddButton: false
        }

        setElementsArray([
            ...elementsArray.slice(0, index),
            updateElement,
            ...elementsArray.slice(index + 1)
        ]);
    }

    const handleImageClick = (index) => {
        const updatedElement = {
            ...elementsArray[index],
            imageClicked: !elementsArray[index].imageClicked
        };

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }

    const handleAddImage = (index) => {
        const updatedElement = {
            ...elementsArray[index],
            addingImage: !elementsArray[index].addingImage
        };

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }


    const handleDoubleClick = (index) => {
        const updatedElement = {
            ...elementsArray[index],
            doubleClicked: true,
            editing: true,
            undoAction: false
        };


        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }


    const handleElementBlur = (index) => {
        const updatedElement = {
            ...elementsArray[index],
            doubleClicked: false
        };

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }

    const handleTitleChange = (event, index) => {
        const updatedElement = {
            ...elementsArray[index],
            text: event.target.value
        };

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }

    const handleElementChange = (event, index) => {
        const updatedElement = {
            ...elementsArray[index],
            text: event.target.value
        };


        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }

    const handleToggleAddParaButton = (index) => {
        const updatedElement = {
            ...elementsArray[index],
            toggleAddButton: !elementsArray[index].toggleAddButton
        };

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }


    const handleAddElement = (elementType, index) => {
        const newElement = {
            id: elementsArray.length + 1,
            type: elementType,
            text: "",
            editing: false,
        };

        const newElementsArray = [
            ...elementsArray.slice(0, index + 1),
            newElement,
            ...elementsArray.slice(index + 1)
        ];

        setElementsArray(newElementsArray);
    }

    const handleDeleteElement = (index) => {
        const updatedElementsArray = [...elementsArray];
        const element = updatedElementsArray[index];

        if (!element.undoAction) {
            updatedElementsArray[index] = { ...element, undoAction: true };
            setElementsArray(updatedElementsArray);
            // if (elementsArray[index].type !== "image") {
            //     document.getElementById(`input-${index}`).style.opacity = "0.25";
            // }
            // Set the undo action ref to true
            undoActionRef.current[index] = true;

            setTimeout(() => {
                if (undoActionRef.current[index]) {
                    setElementsArray([
                        ...elementsArray.slice(0, index),
                        ...elementsArray.slice(index + 1)
                    ]);
                }
            }, 5000);
        } else {
            updatedElementsArray[index] = { ...element, undoAction: false };
            setElementsArray(updatedElementsArray);
            // if (elementsArray[index].type !== "image") {
            //     document.getElementById(`input-${index}`).style.opacity = "1";
            // }
        }
    };

    const handleUndoAction = (index) => {
        const updatedElement = {
            ...elementsArray[index],
            undoAction: false
        };

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);

        // if (elementsArray[index].type !== "image") {
        //     document.getElementById(`input-${index}`).style.opacity = "1";
        // }

        // Set the undo action ref to false
        undoActionRef.current[index] = false;
    };


    const handleAddlink = (index) => {
        handleDoubleClick(index);
        const updatedElement = {
            ...elementsArray[index],
            addingLink: !elementsArray[index].addingLink,
            doubleClicked: true,
            editing: true
        };

        handleDoubleClick(index);

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);
    }

    const handleLinkChange = (event, index) => {
        const updatedElement = {
            ...elementsArray[index],
            url: event.target.value
        };

        setElementsArray([
            ...elementsArray.slice(0, index),
            updatedElement,
            ...elementsArray.slice(index + 1)
        ]);

    }



    const handleHoverOffButton = (index) => {
        if (elementsArray[index].type === "button") {
            const updatedElement = {
                ...elementsArray[index],
                doubleClicked: false,
                addingLink: false
            };

            setElementsArray([
                ...elementsArray.slice(0, index),
                updatedElement,
                ...elementsArray.slice(index + 1)
            ]);
        }
        else if (elementsArray[index].type === "image") {
            const updatedElement = {
                ...elementsArray[index],
                doubleClicked: false,
                imageClicked: false,
                addingImage: false
            };

            setElementsArray([
                ...elementsArray.slice(0, index),
                updatedElement,
                ...elementsArray.slice(index + 1)
            ]);
        }
    }

    const handleFileInputClick = (index) => {
        // Trigger the file input dialog
        document.getElementById("file-input").click();

        // Get the file input element
        const fileInput = document.getElementById("file-input");

        // Event listener for when a file is selected
        fileInput.addEventListener('change', async () => {
            // Get the selected file
            const file = fileInput.files[0];

            // Read the file as binary data
            const reader = new FileReader();
            reader.onloadend = async () => {
                const updatedElement = {
                    ...elementsArray[index],
                    url: reader.result,
                    fileName: file.name,
                    imageAdded: true
                };

                // Update the elements array state
                setElementsArray([
                    ...elementsArray.slice(0, index),
                    updatedElement,
                    ...elementsArray.slice(index + 1)
                ]);
                try {
                    // Send the file as binary data to the backend
                    const response = await axios.post('/api/upload', {
                        fileName: file.name,
                        fileData: reader.result
                    });

                    if (response.status === 200) {
                        console.log("File uploaded successfully");
                    } else {
                        console.log("File upload failed");
                    }
                } catch (error) {
                    console.error("Error uploading file", error);
                }
            };

            // Read the file as binary data
            if (file) {
                reader.readAsDataURL(file);
            }
        });
    }


    // Function to handle the adaptive card elements

    const handleAdaptiveCardElements = (element) => {
        data.adaptiveCard = true;
        console.log("Format element function statement 1 is running");
        if (element.type === "title") {
            return {
                type: "TextBlock",
                text: element.text,
                wrap: true,
                horizontalAlignment: "Center",
                size: "Large"
            };
        }
        else if (element.type === "paragraph") {
            return {
                type: "TextBlock",
                text: element.text,
                wrap: true,
                horizontalAlignment: "Center",
                size: "Medium"
            };
        }
        else if (element.type === "button") {
            return {
                type: "ActionSet",
                actions: [
                    {
                        type: "Action.OpenUrl",
                        title: element.text,
                        url: element.url
                    }
                ]
            };
        }
        else if (element.type === "image") {
            return {
                type: "Image",
                url: `@{image:${element.fileName}}`,
                size: "Large",
                horizontalAlignment: "Center"
            };
        }
    }



    // Function to format each element type to markdown
    const formatElement = (element) => {
        if (elementsArray.some(element => element.type === "button")) {
            data.adaptiveCard = true;
            const cardId = `card${v4()}`;
            data.cardData._id = cardId;
            console.log("Format element function statement 1 is running");
        }
        else if (elementsArray.every(element => element.type !== "button")) {
            data.adaptiveCard = false;
            if (element.type === "title") {
                if (element.text !== "") {
                    return `## ${element.text}`;
                }
                else {
                    return "";
                }
            } else if (element.type === "paragraph") {
                if (element.text !== "") {
                    return `${element.text}`;
                }
                else {
                    return "";
                }
            } else if (element.type === "image") {
                if (element.url !== "") {
                    return `![${element.fileName}](@{image:${element.fileName}})`;
                }
                else {
                    return "";
                }
            } else if (element.type === "button") {
                return "";
            } else {
                return "";
            }
        }
    };


    // creating a function that will take in the type of the element and the text or url of the element and update the mediaResponseData
    useEffect(() => {
        // Appending info from the elementsArray to the mediaResponseData
        const formattedElements = elementsArray.map(formatElement);
        const joinedText = formattedElements.filter(Boolean).join("\n\n ");
        setMediaResponseData({ type: "text", text: joinedText });
        console.log("Media Response Data", mediaResponseData);
    
        // For the adaptive Cards
        if (data.adaptiveCard === true) {
            const adaptiveCardElements = elementsArray.map(handleAdaptiveCardElements);
            data.cardData.body = adaptiveCardElements;  // Assign the array directly
            setMediaResponseData({ type: "AdaptiveCard", card: data.cardData._id });
            console.log("adaptiveCardData", data.cardData);
        }
    
        data.elementsArray = elementsArray;
        data.mediaResponseData = mediaResponseData;
    
        console.log("Data", data);
    
    }, [elementsArray]);

    // useEffect for checking if the image exists in the S3 bucket
    useEffect(() => {
        if (elementsArray.some(element => element.type === "image")) {    
            // You need to use .then() or await to handle the asynchronous call
            getFiles().then(existingFiles => {
                // Mapping through the Images in the elementsArray and checking if the file names are in the S3 bucket,
                // if yes then do nothing but if no then removing the url from image type element of the elementsArray
                const updatedElementsArray = elementsArray.map((element) => {
                    if (element.type === "image") {
                        if (existingFiles.some(file => file === `PUBLIC/${element.fileName}`)) { 
                            return element;
                        } else {
                            // If file doesn't exist in S3, remove the URL
                            return { ...element, url: "", fileName: "", imageAdded: false };
                        }
                    } else {
                        return element;
                    }
                });
                setElementsArray(updatedElementsArray);

            }).catch(error => {
                console.error("Error handling files", error);
            });
        }
    }, []);
    


    const handleAddElements = (handleHoverOnElement, index) => {
        return (
            <div className="media-add-elements-container"
                onMouseEnter={() => handleHoverOnElement(index)}
            >
                <button className="format-options bold-btn" onClick={() => handleAddElement("title", index)}> <RxText color="white" /></button>
                <button className="format-options" onClick={() => handleAddElement("paragraph", index)}> <PiParagraphBold color="white" /></button>
                <button className="format-options" onClick={() => handleAddElement("image", index)}><LuImage color="white" /></button>
                {/* <button className="format-options" > <MdOutlinePlayCircle color="white" /></button> */}
                <button className="format-options link-btn" onClick={() => handleAddElement("button", index)}> <PiRadioButtonLight color="white" /></button>
            </div>
        )
    }

    const nodeStyle = {
        boxShadow: data?.boxShadow ? "0px 0px 20px 10px grey" : "none", // Light grey shadow for active node
    };

    const toggleHover = () => {
        setEnableDelete(prevState => !prevState);
    }

    //function to delete the node
    const deleteElement = () => {
        dispatch(showPopUp({show: true, nodeID: id}));
    }

    return (
        <>
            <div className="media-response-node-background" onMouseEnter={toggleHover} onMouseLeave={toggleHover} style={nodeStyle}>
                <Handle
                    type="target"
                    position={Position.Top}
                    style={{ background: "#D563B9", width: "10px", height: "10px" }}
                    isConnectable={isConnectable}
                    id={`${data.elementId}-Top`}
                />
                <div className="media-response-logo-container">
                    <div className="media-response-logo-circle">
                        <MdOutlineImage size={50} color="white" />
                    </div>
                </div>
                <div className="media-response-elements-area">
                    <div className="media-response-elements">
                        {elementsArray.map((element, index) => {
                            if (element.type === "title") {
                                return <TitleElement
                                    index={index}
                                    elementsArray={elementsArray}
                                    handleTitleChange={handleTitleChange} handleElementBlur={handleElementBlur}
                                    handleDoubleClick={handleDoubleClick} handleHoverElement={handleHoverElement}
                                    handleHoverOffElement={handleHoverOffElement} titleTextareaRef={titleTextareaRef}
                                    handleDeleteElement={handleDeleteElement} handleToggleAddParaButton={handleToggleAddParaButton}
                                    handleUndoAction={handleUndoAction} handleAddElement={handleAddElement}
                                />
                            } else if (element.type === "paragraph") {
                                return <ParagraphElement
                                    index={index}
                                    elementsArray={elementsArray}
                                    handleElementChange={handleElementChange}
                                    handleElementBlur={handleElementBlur} handleDoubleClick={handleDoubleClick}
                                    handleHoverElement={handleHoverElement} handleHoverOffElement={handleHoverOffElement}
                                    paragraphTextareaRef={paragraphTextareaRef} handleDeleteElement={handleDeleteElement}
                                    handleToggleAddParaButton={handleToggleAddParaButton} handleUndoAction={handleUndoAction}
                                    handleAddElements={handleAddElements}
                                />
                            }
                            else if (element.type === "image") {
                                return <ImageElement index={index}
                                    elementsArray={elementsArray}
                                    handleImageClick={handleImageClick}
                                    handleAddImage={handleAddImage} handleHoverElement={handleHoverElement}
                                    handleHoverOffElement={handleHoverOffElement} handleDoubleClick={handleDoubleClick}
                                    handleDeleteElement={handleDeleteElement} handleToggleAddParaButton={handleToggleAddParaButton}
                                    handleUndoAction={handleUndoAction} handleAddElements={handleAddElements}
                                    handleHoverOffButton={handleHoverOffButton} handleFileInputClick={handleFileInputClick}
                                />
                            } else if (element.type === "button") {
                                return <ButtonElement
                                    index={index}
                                    elementsArray={elementsArray}
                                    handleElementChange={handleElementChange} handleElementBlur={handleElementBlur}
                                    handleDoubleClick={handleDoubleClick} handleHoverElement={handleHoverElement}
                                    handleHoverOffElement={handleHoverOffElement} handleDeleteElement={handleDeleteElement}
                                    handleToggleAddParaButton={handleToggleAddParaButton} handleUndoAction={handleUndoAction}
                                    handleAddlink={handleAddlink} handleLinkChange={handleLinkChange} handleAddElements={handleAddElements}
                                    handleHoverOffButton={handleHoverOffButton} />
                            }
                        })}
                    </div>
                </div>
                <Handle
                    type="source"
                    position={Position.Bottom}
                    style={{ background: "#D563B9", width: "10px", height: "10px" }}
                    id={`${data.elementId}-Bottom`}
                />
                 {enableDelete && (
                    <button className="display-on-hover" onClick={deleteElement}>
                    <MdDeleteOutline />
                    </button>
                )}
            </div>
        </>
    );
}

export default MediaResponseNode;
