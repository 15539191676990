import { Routes, Route, Navigate } from "react-router-dom";

// MSAL imports
import { MsalProvider, useMsal } from "@azure/msal-react";
// import { CustomNavigationClient } from "./utils/NavigationClient";

// Page imports
import App from "./App";
import Welcome from "./Webpages/Welcome";
import RequestForm from "./RequestForm";

function Routing({ pca }) {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    // const navigate = useNavigate();
    // const navigationClient = new CustomNavigationClient(navigate);
    // pca.setNavigationClient(navigationClient);


    return (
        <MsalProvider instance={pca}>
            <Pages />
        </MsalProvider>
    );
}

function Pages() {
    const { instance } = useMsal();

    const isLoggedIn = !!instance.getActiveAccount();
    return (
        <Routes>
            <Route path="/home" element={<App page={'home'} />} />
            <Route path="/canvas" element={<App page={'canvas'} />} />
            {/* <Route path="/" element={<Welcome />} /> */}
            {isLoggedIn === false ? (
                <Route path="/" element={<Welcome />} />
            ) : (
                <Route path="/" element={<App page={'home'} />} />
            )}
            <Route path="/requestAccess" element={<RequestForm/>} />
        </Routes>
    );
}

export default Routing;