import React, { useState } from 'react';
import './RequestReviewPopUp.css';
import { RiAddLine } from 'react-icons/ri';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { GrClose } from 'react-icons/gr';
import { AiFillCaretDown } from 'react-icons/ai';

function RequestReviewPopUp(props) {
    // const options = [
    //     'John Smith',
    //     'Leonardo',
    //     'Kelly',
    //     'James',
    //     'Andy',
    //     'Ian',
    //     'Jake',
    //     'Kevin'
    // ];

    // const [selectedOptions, setSelectedOptions] = useState([]);
    // const [isActive, setIsActive] = useState(false);

    // const toggleOption = (option) => {
    //     if (selectedOptions.includes(option)) {
    //         setSelectedOptions(selectedOptions.filter((item) => item !== option));
    //     } else {
    //         setSelectedOptions([...selectedOptions, option]);
    //     }
    // };

    // const handleAddReviewers = () => {
    //     setStatus('inReview');
    //     setDropDownTitle('IN REVIEW');
    //     onClose();
    // }

    // const Dropdown = () => {
    //     return (
    //         <div className='optionsDropdown'>
    //             <div
    //                 className="dropdown-btn"
    //                 onClick={() => setIsActive(!isActive)}
    //             >
    //                 Select Reviewer(s) <AiFillCaretDown size={15} />
    //             </div>
    //             {isActive && (
    //                 <div className='dropdown-content'>
    //                     {options.map((option) => (
    //                         <div
    //                             key={option}
    //                             className={`dropdown-item ${selectedOptions.includes(option) ? 'selected' : ''
    //                                 }`}
    //                             onClick={() => toggleOption(option)}
    //                         >
    //                             {option}
    //                         </div>
    //                     ))}
    //                 </div>
    //             )}
    //         </div>
    //     );
    // };

    return (
        <div className={`custom-modal ${props.show ? 'show' : ''}`}>
            <div className='custom-modal-content' style={{ maxHeight: '80vh' }}>
                <div className='btnDiv'>
                    <button className='close-button' onClick={props.onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className='custom-modal-header'>
                    <h3>
                        Are you sure you want to <strong>request a review?</strong>
                    </h3>
                </div>
                <div className='custom-modal-body'>
                    <p>
                        Once you have requested a review, the conversation will be locked and no further changes can be made until the review is complete.
                    </p>
                    {/* <p>Select one or more to add to this review:</p> */}
                    {/* <div className='dropdown-box'>
                        <Dropdown />
                    </div> */}
                    {/* <br />
                    <br />
                    <br /> */}
                    {/* <div className='selected-options-container'>
                        <div className='selected-options-label'>
                            Selected Reviewer(s):  {selectedOptions.length > 0 ? null : 'No Reviewers selected'}
                        </div>
                        {selectedOptions.map((option) => (
                            <div key={option} className='selected-option'>
                                {option}
                                <button
                                    className='remove-button'
                                    onClick={() => toggleOption(option)}
                                >
                                    &#x2715;
                                </button>
                            </div>
                        ))}
                    </div> */}
                    <div className='actionBtns'>
                        <button className={`button return`} onClick={props.onClose}>
                            <IoIosArrowRoundBack size={25} /> Return to Conversation
                        </button>
                        <button
                            className="button add"
                            // disabled={selectedOptions.length === 0} // Disable the button if no options are selected
                            onClick={props.onRequestReview}
                        >
                            <RiAddLine size={25} /> Request Review
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequestReviewPopUp;