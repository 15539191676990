import React, { useEffect, useState } from "react";
import "./ExportPopUp.css";
import { GrClose } from "react-icons/gr";
import { AiOutlineExport } from "react-icons/ai";
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { config } from '../../Redux/slicer/ConfigSlicer';
import { useMsal } from "@azure/msal-react";

function ExportPopUp(props) {
  const dispatch = useDispatch();
  const { instance } = useMsal();
  const account = instance.getActiveAccount();
  const [canExportJSON, setCanExportJSON] = useState(false);

  useEffect(() => {
    handleExportOpen();    
  }, []);

  const handleExportOpen = async () => {
    const canJSON = await dispatch(config({ functionality: 'export_JSON', roles: account.idTokenClaims.roles }));
    setCanExportJSON(canJSON.payload);
  }

  return (
    <div className="ExportPopUp-custom-modal">
      <div className="ExportPopUp-custom-modal-content px-4 px-2" style={{ maxHeight: "80vh" }}>
        <div className="ExportPopUp-btnDiv">
          <button className="ExportPopUp-close-button" onClick={props.onClose}>
            <GrClose size={25} />
          </button>
          <div className="ExportPopUp-custom-modal-header">
            <h3>
              <strong>Export</strong> Conversation
            </h3>
          </div>
          <div className="ExportPopUp-custom-modal-body d-flex flex-column align-items-center justify-content-center mx-4 pt-0">
            <h5 className="mt-3 mb-3">
              Click the link to download your export.
            </h5>
            <h5>
              <button onClick={props.exportPDF} className="exportPopUp-container">
                {props.flowName}.pdf <AiOutlineExport />
              </button>
            </h5>
            {canExportJSON ? (
              <>
                <h5>
                  <button onClick={props.exportJson} className="exportPopUp-container">
                    {props.flowName}.json <AiOutlineExport />
                  </button>
                </h5>
                <h5>
                  <button
                    onClick={() => { }}
                    className="exportPopUp-container"
                  >
                    {props.flowName} Database Export <AiOutlineExport />
                  </button>
                </h5>
              </>
            ) : null}
            <button
              className={`ExportPopUp-button returnbtn mt-3`}
              style={{ border: "2px solid #D52B1E" }}
              onClick={props.onClose}
            >
              <IoIosArrowRoundBack size={25} /> Return to Conversation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportPopUp;
