import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export const config = createAsyncThunk('config/config', async ({ functionality, roles }) => {
  try {
    const response = await axios.get('/api/config', {
      params: {
        functionality: functionality,
        roles: roles,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
});

const configSlice = createSlice({
  name: 'config',
  initialState: {
    value: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(config.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(config.fulfilled, (state, action) => {
        state.loading = false;
        state.value = action.payload;
      })
      .addCase(config.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const configReducer = configSlice.reducer;
