import TSettingsIcon from "../../Assets/Img/settings_icon.png";
import TSuggestedActionsIcon from "../../Assets/Img/user.png";
import TTextResponseIcon from "../../Assets/Img/text_response.png";
import TMediaCardIcon from "../../Assets/Img/media_card.png";
import "./Toolbox.css";
import React, { useState } from "react";
import TExitIcon from "../../Assets/Img/to_Conversation.png";
import { FaRegStar } from "react-icons/fa";
import endNode from "../../Assets/Img/EndNode.png";

function Toolbox(props) {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      {props.isEditable && (
        <div className="TContainer">
          <div>
            <div className="THeaderContainer">
              <img
                className="TSettingsIcon"
                src={TSettingsIcon}
                alt="Settings Icon"
              />
              <div className="TTitleText">Toolbox</div>
            </div>
            <div className="TSubtitleText">User</div>
            <div
              className="TIconContainer"
              onDragStart={(event) => {
                const nodeType = "suggestedActions"; // Set the type to suggestedActions
                onDragStart(event, nodeType);
              }}
              draggable
            >
              <img
                className="TSuggestedActionsIcon"
                src={TSuggestedActionsIcon}
                alt="Suggested Action Icon"
              />
            </div>
            <div className="TSubtitleText">Chatbot</div>
            <div
              className="TIconContainer"
              onDragStart={(event) => {
                const nodeType = "textResponse"; // Set the type to textResponse
                onDragStart(event, nodeType);
              }}
              draggable
            >
              <img
                className="TTextResponseIcon"
                src={TTextResponseIcon}
                alt="Text Response Icon"
              />
            </div>
            <div
              className="TIconContainer"
              onDragStart={(event) => {
                const nodeType = "mediaResponse" // Temporarily set to textResponse until the media node is implemented
                onDragStart(event, nodeType);
              }}
              draggable
            >
              <img
                className="TMediaCardIcon"
                src={TMediaCardIcon}
                alt="Media Icon"
              />
            </div>
            <div
              className="TIconContainer"
              onDragStart={(event) => {
                const nodeType = "exitNode"; // Temporarily set to textResponse until the exit node is implemented
                onDragStart(event, nodeType);
              }}
              draggable
            >
              <img className="TExitIcon" src={TExitIcon} alt="Exit Icon" />
            </div>
            <div className="TFooterContainer">
              <div className="TFooter"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Toolbox;
