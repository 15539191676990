import {React, useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import Logo from '../Assets/Img/LillyLogo.png'
import Button from 'react-bootstrap/Button';
import './Welcome.css'
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

// Simple version of sign in page

function Welcome() {
  const { instance, inProgress } = useMsal();

  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const account = instance.getActiveAccount();
      if (!account) {
        instance.acquireTokenRedirect({
          ...loginRequest,
          account: account
        });
      }
    }
  }, [inProgress, instance]);


  return (
    <>
    <div id='imgContainer'>
    <img id='lillyLogo' src={Logo} alt="Lilly Logo"/>
    </div>
    <div id='textContainer'>
      <h1 id='title'>Chatbot Conversation Builder</h1>
    </div>
    <div id='buttonContainer'>
      <Link id='signOnButton' to="/home"><Button variant="danger" as="input" type="button" value="Login with SSO" /></Link>
    </div>
    </>
  )
}

export default Welcome