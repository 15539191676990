import { memo, useEffect, useMemo } from 'react';
import React, { useRef, useState } from "react";
import { Handle, Position, getConnectedEdges, useNodeId, useStore } from "reactflow";
import "../../../index.css";
import "./TextResponseNode.css";
import { IoMdChatboxes } from "react-icons/io";
import { MdOutlineThumbsUpDown } from "react-icons/md";
import { BiSolidCommentError } from "react-icons/bi";
import { useDispatch } from 'react-redux'
import { showPopUp } from '../../../Redux/slicer/deleteNodeSlicer';
import { MdDeleteOutline } from "react-icons/md";

const useInternalNodes = (textArray) => {
  const handleRef = useRef([]);
  const internalNodes = textArray.map((node, index) => {
    // internalNodes is an array of divs that will be displayed inside the node component, the textArray is an array of objects that contain the text to be displayed (array of text responses)
    return (
      <div
        ref={(ref) => (handleRef.current[index] = ref)}
        style={{
          background: "#F5F5F5",
          border: "1px solid #9E9E9E",
          borderRadius: "4px",
          padding: "4px",
          marginTop: "8px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <div>
          <div style={{ color: "#212121" }}>
            <div style={{ color: "#212121", minHeight: "40px" }}>
              {node.text}
            </div>
          </div>
        </div>
      </div>
    );
  });

  return internalNodes;
};


const selector = (s) => ({
  nodeInternals: s.nodeInternals,
  edges: s.edges,
});


const TextUpdaterNode = ({ data, isConnectable }) => {
  const [enableDelete, setEnableDelete] = useState(false);
  const dispatch = useDispatch();

  const [countConnect, setCountConnect] = useState(0);
  var count = 0;
  const [isError, setIsError] = useState(false);

  const { nodeInternals, edges } = useStore(selector);
  const nodeId = useNodeId();


  useEffect(() => {
    console.log("isConnectable", isConnectable, edges)
    const node = nodeInternals.get(nodeId);
    const connectedEdges = getConnectedEdges([node], edges);
    var cc = 0;
    connectedEdges.forEach((edge) => {
      if (edge.source === nodeId) {
        cc++;
      }
    });
    console.log(connectedEdges, cc, nodeId);
    if (cc > 1) {
      setIsError(true);

    }else{
      setIsError(false);
    }

  }, [isConnectable, edges])


  const nodeStyle = {
    textAlign: "center",
    background: "#5DB8E7",
    padding: "10px",
    width: "400px",
    height: "fit-content",
    borderRadius: "6px",
    border: data?.boxShadow ? "2px solid darkgrey" : "none", // Remove the errorStatus from here
    boxShadow: data?.boxShadow ? "0px 0px 20px 10px grey" : "none", // Light grey shadow for active node
  };

  // Title style should not repeat the nodeStyle properties
  const titleStyle = {
    position: "absolute",
    top: -22,
    left: 6,
    color: "black",
    fontSize: "16px",
  };

  const toggleHover = () => {
    setEnableDelete(prevState => !prevState);
  }

  //function to delete the node
  const deleteElement = () => {
    dispatch(showPopUp({ show: true, nodeID: data.elementId }));
  }

  return (
    <div style={nodeStyle} onMouseEnter={toggleHover} onMouseLeave={toggleHover} className={data?.boxShadow === data.elementId ? "activeNodeBorder" : ""} >
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: "#D563B9", width: "10px", height: "10px" }}
        isConnectable={isConnectable}
        id={`${data.elementId}-Top`}
        onEdgesChange={(connection) => { console.log("CHANGE", connection) }}
        onConnect={(connection) => {

          setCountConnect(countConnect => countConnect + 1)
          count++;
          console.log("countConnect", countConnect, count)
        }}
        onElementsRemove={(connection) => {
          setCountConnect(countConnect => countConnect - 1)
          count--;
          console.log("countConnect", countConnect, count)
        }}
      />
      {isError && (
        <div className="errorContainer">
          <BiSolidCommentError className="errorIcon" />
          <span className="errorMessage">{'A text node can only have one child node!'}</span>
        </div>
      )}
      {data.errorStatus && (
        <div className="errorContainer">
          <BiSolidCommentError className="errorIcon" />
          <span className="errorMessage">{data.errorMessage}</span>
        </div>
      )}
      <div style={titleStyle}>
        {data.elementTitle}
      </div>

      <IoMdChatboxes size={40} style={{ color: "white" }} />
      {useInternalNodes(data.textArray)}

      {data.feedback && (
        <div className="FeedbackContainer">
          <MdOutlineThumbsUpDown size={13} style={{ marginRight: "3px" }} />
          Was this content useful?
        </div>
      )}
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: "#D563B9", width: "10px", height: "10px" }}
        id={`${data.elementId}-Bottom`}
      />
      {enableDelete && (
        <button className="display-on-hover" onClick={deleteElement}>
          <MdDeleteOutline />
        </button>
      )}
    </div>
  );
};

export default memo(TextUpdaterNode);