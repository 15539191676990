import { BsGlobe } from "react-icons/bs";
import './ConversationInformation.css';
import { FaTrash } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import DeleteCoversationPopUp from '../../ConversationMenu/DeleteCoversationPopUp'

function ConvoInfo(props) {
  const [products] = useState(["Zepbound", "Trulicity", "Mounjaro", "Emgality", "Kisunla", "Omvoh"]);
  const [savedVersions] = useState(["Version 4.0", "Version 3.0", "Version 2.0", "Version 1.0"]);
  const [showDeleteCoversationPopUp, setShowDeleteCoversationPopUp] = useState(false);

  const nodeStyle = {
    textAlign: "center",
    // background: "#FF9352",
    padding: "10px",
    // width: "320px",
    height: "fit-content",
    borderRadius: "6px",
    border: props?.data?.boxShadow ? "2px solid darkgrey" : "none",
    boxShadow: props?.data?.boxShadow ? "0px 0px 20px 10px grey" : "none",
  };

  const handleProductChange = (newText) => {
    props.setProduct(newText);
    props.addNewPastElement();
  }

  const handleTitleTextChange = (newText) => {
    props.setFlowName(newText);
    props.addNewPastElement();
  }

  const handlesavedVersionChange = (newText) => {
    props.setSavedVersion(newText);
    props.addNewPastElement();
  }

  const onDeleteCoversation = () => { 
    setShowDeleteCoversationPopUp(false)
    props.onDeleteConversation()
  }

  return (
    <>
      <div style={nodeStyle} className={props.data?.boxShadow ? "activeNodeBorder" : ""} >
        <div className="CIContainer">
          {!props.isEditable && (
            <div className="NotEditable" style={{ cursor: "not-allowed" }}></div>
          )}
          <div className="CIContainerContent">
            <div className="CITopTitleSection">
              <p className="CIGlobeIcon">
                <BsGlobe />
              </p>
              <p className="CIConInfoTitle">Conversation Information</p>
            </div>
            <p className="CISectionTitle">General</p>
            <div className="CIInfoSectionContainer">
              <div className="CIInfoSection">
                <div className="CITitle">
                  Conversation ID:
                  <div className="ConversationIdText">{props.id}</div>
                </div>
                <div className="CITitle">
                  Conversation Type:
                  <div className="CITitleText">{props.conversationType}</div>
                </div>
                <div className="CITitle">
                  State:
                  <div className="CITitleText">{props.conversationState}</div>
                </div>
                <div className="CITitle">
                  Veeva Vault ID:
                  <div className="CITitleText">{props.veevaVaultId}</div>
                </div>
                <div className="CISelectTitle">
                  Conversation Title:
                  <input
                    type="text"
                    value={props.flowName}
                    onChange={(e) => handleTitleTextChange(e.target.value)}
                    className="CITitleInput"
                  />
                </div>
                <div className="CISelectTitle">
                  Product (Generic Name):
                  <select className="CITitleInput" value={props.product} onChange={(e) => handleProductChange(e.target.value)}>
                    <option value="" disabled hidden>Select Product</option>
                    {products.map((product, index) => (
                      <option key={index} value={product}>
                        {product}
                      </option>
                    ))}
                  </select>
                  <IoMdArrowDropdown className="Chevron" />
                </div>
              </div>
            </div>
            <p className="CISectionTitle">Version History</p>
            <div className="CIInfoSectionContainer">
              <div className="CIVersionSection">
                <div className="CISelectTitle">
                  Saved Versions:
                  <select className="CITitleInput" value={props.savedVersion} onChange={(e) => handlesavedVersionChange(e.target.value)}>
                    {savedVersions.map((savedVersion, index) => (
                      <option key={index} value={savedVersion}>
                        {savedVersion}
                      </option>
                    ))}
                  </select>
                  <IoMdArrowDropdown className="Chevron" />
                </div>
              </div>
              <p className="CISectionTitle">Manage Conversation</p>
              <div className="CIDeleteContainer">
                <div className="CIDelete">
                  <button
                    onClick={() => setShowDeleteCoversationPopUp(true)}
                    className="CIDeleteButton"
                  >
                    <FaTrash /> Delete Conversation
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DeleteCoversationPopUp show={showDeleteCoversationPopUp} onDeleteCoversation={onDeleteCoversation} onClose={() => setShowDeleteCoversationPopUp(false)} />
    </>
  );
}

export default ConvoInfo;
