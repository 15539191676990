import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Export from "../../Assets/Img/Export.png";
import Redo from "../../Assets/Img/Redo.png";
import Info from "../../Assets/Img/Info.png";
import Nglobe from "../../Assets/Img/Nglobe.png";
import { BiSolidEditAlt } from "react-icons/bi";
import { IoMdSave } from "react-icons/io";
import { IoMdUndo } from "react-icons/io";
import "./ConversationMenu.css";
import ExportPopUp from "../StateDropdownComponent/ExportPopUp";
import DeleteCoversationPopUp from './DeleteCoversationPopUp'
import { GrLinkPrevious } from "react-icons/gr";
import { AiOutlineExport } from "react-icons/ai";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { config } from '../../Redux/slicer/ConfigSlicer';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash } from "react-icons/fa"; // Import the trash icon
import { useMsal } from "@azure/msal-react";

function ConversationMenu(props) {
  const dispatch = useDispatch();
  const configValue = useSelector((state) => state.config.value);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteCoversationPopUp, setShowDeleteCoversationPopUp] = useState(false);
  const { instance } = useMsal();
  const account = instance.getActiveAccount();

  const toggleEditSaveBtn = () => {
    if (props.isEditable) {
      props.onSave();
    }
    props.onToggleInteractivity();
    dispatch(config());
  };

  const handleExportClick = (event) => {
    event.preventDefault();
    props.onExport();
  };

  const handleOpenConversationMenu = (event) => {
    event.preventDefault();
    props.openConversation();
  };

  const handleUndoClick = (event) => {
    event.preventDefault();
    props.onUndo();
  }

  const handleRedoClick = (event) => {
    event.preventDefault();
    props.onRedo();
  }

  const handleEditClick = () => {
    if(props.isConversationWriter && props.conversationState === "DRAFT") {
      props.toggleEditSaveBtn();
    } else if (props.conversationState === "DRAFT"){
      props.setEditModal(true);
    } else {
      alert("You cannot edit a conversation that is not in draft state. If you need to make changes, you must first cancel the review/approval process.");
    }
  }

  const handleDelete = (event) => {
    event.preventDefault();
    setShowDeleteCoversationPopUp(true)
  };

  const onDeleteCoversation = () => { 
    setShowDeleteCoversationPopUp(false)
    props.onDeleteConversation()
  }

  return (
    <div className="CMenuContainer">
      {!props.isEditable ? (
        <button
          onClick={handleEditClick}
          className="Editbtn"
        >
          <BiSolidEditAlt
            className="BiSolidEditbtn"
            size="16px"
          />
          Edit
        </button>
      ) : (
        <button
          onClick={() => {toggleEditSaveBtn();}}
          className="Savebtn"
        >
          <IoMdSave
            className="IoMdSavebtn"
            size="16px"
          />
          Save
        </button>
      )}
      <ButtonGroup className="button_group">
        <Button
          id="UndoBtn"
          className="Iconbtn"
          href=""
          variant=""
          aria-label="Export"
          onClick={handleExportClick}
        >
          <img
            className="BtnGroupImg"
            title="Export"
            src={Export}
            alt="Export"
            aria-hidden="true"
          />
        </Button>
        <Button
          id="UndoBtn"
          className="Iconbtn"
          href=""
          variant=""
          aria-label="Undo"
          onClick={handleUndoClick}
        >
          <IoMdUndo size="24px" />
        </Button>
        <Button
          id="UndoBtn"
          className="Iconbtn"
          href=""
          variant=""
          aria-label="Redo"
          onClick={handleRedoClick}
        >
          <img
            className="BtnGroupImg"
            title="Redo"
            src={Redo}
            alt="Redo"
            aria-hidden="true"
          />
        </Button>
        <Button
          id="UndoBtn"
          className="Iconbtn"
          href=""
          variant=""
          aria-label="Delete"
          onClick={handleDelete}
        >
          <FaTrash size="18px" />
        </Button>
        <Button
          id="UndoBtn"
          className="Iconbtn"
          href=""
          variant=""
          aria-label="Globe"
          onClick={handleOpenConversationMenu}
        >
          <img
            className="BtnGroupImg"
            title="Language"
            src={Nglobe}
            alt="Navbar Globe"
            aria-hidden="true"
          />
        </Button>
      </ButtonGroup>
      <Modal show={showDeleteConfirmation} onHide={() => setShowDeleteConfirmation(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Conversation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this conversation? This action is irreversible.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteConfirmation(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}> {/* Call handleConfirmDelete here */}
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteCoversationPopUp show={showDeleteCoversationPopUp}  onDeleteCoversation={onDeleteCoversation} onClose={() =>  setShowDeleteCoversationPopUp(false)} />
    </div>
  );
}

export default ConversationMenu;
