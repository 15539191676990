import React from 'react';
import { Panel, useReactFlow, getTransformForBounds, getRectOfNodes } from 'reactflow';
import * as htmlToImage from 'html-to-image';
import { PDFDocument } from 'pdf-lib';

const ExportPDF = (props) => {
    const { getNodes } = useReactFlow();

    const nodes = getNodes();
    if (nodes.length === 0) return;

    const nodesBounds = getRectOfNodes(getNodes());
    const imageWidth = nodesBounds.width;
    const imageHeight = nodesBounds.height;
    // Capture canvas design using class name
    const viewportElement = document.querySelector('.react-flow__viewport');
    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);

    if (!viewportElement) {
        console.error('Viewport element not found');
        return;
    }

    // Get all edges of canvas design
    const convertToPDF = async () => {
        const dataUrl = await htmlToImage.toPng(viewportElement, {
            backgroundColor: 'rgba(0,0,0,0.0)',
            width: imageWidth,
            height: imageHeight,
            style: {
                width: imageWidth,
                height: imageHeight,
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            }
        });

        const pdfDoc = await PDFDocument.create();
        const pngImage = await pdfDoc.embedPng(dataUrl);

        const page = pdfDoc.addPage([imageWidth, imageHeight]);

        // Add the title to the PDF
        const titleText = props.flowName;
        const titleFontSize = 50;
        const titleX = 50;
        const titleY = imageHeight - 50;
        page.drawText(titleText, {
            x: titleX,
            y: titleY,
            size: titleFontSize
        });

        // Add the canvas to the PDF
        page.drawImage(pngImage, {
            x: 0,
            y: 0,
            width: imageWidth,
            height: imageHeight,
        });

        // Converting into blob and save in PDF
        const pdfBytes = await pdfDoc.save();
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);

        // Download the PDF
        const a = document.createElement('a');
        a.href = url;
        a.download = 'reactflow.pdf';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);

        props.reDownload();
    };

    convertToPDF();

    return (
        <Panel position="top-right">
            <p>Downloading PDF...</p>
        </Panel>
    );
}

export default ExportPDF;
