import LillyLogo from '../../Assets/Img/Red.png';
import { IoHome } from "react-icons/io5";
import Account from '../AccountDropdown/AccountDropdown';
import State from '../StateDropdownComponent/state';
import './Header.css';
import { useState } from 'react';
import { Link } from "react-router-dom";

function Header(props) {
  return (
    <>
      <div className="headerContainer">
        <div className="leftNav">
          <img className='LillyLogo' src={LillyLogo} alt="Logo" />
          <span id="bar">|</span>
          <span id='weave-text'>Weave</span>
          {props.page !== 'home' && (
             <Link id='home' to="/home" style={{ textDecoration: 'none' }} >
            <button className="HomeButton">
              <IoHome className='HomeIcon' />
              HOME
            </button>
            </Link>
          )}
          <div className='StateDropdownbtn'>
            {props.page !== 'home' && 
              <State 
                id={props.id}
                flowName={props.flowName}
                conversationType={props.conversationType}
                intentName={props.intentName}
                score={props.score}
                entities={props.entities}
                conversationState={props.conversationState}
                veevaVaultId={props.veevaVaultId}
                product={props.product}
                savedVersion={props.savedVersion}
                projectType={props.projectType}
                setId={props.setId}
                setFlowName={props.setFlowName}
                setConversationType={props.setConversationType}
                setIntentName={props.setIntentName}
                setScore={props.setScore}
                setEntities={props.setEntities}
                setConversationState={props.setConversationState}
                setVeevaVaultId={props.setVeevaVaultId}
                setProduct={props.setProduct}
                setSavedVersion={props.setSavedVersion}
                setProjectType={props.setProjectType}
                instance={props.instance}
                nodes={props.nodes}
                setNodes={props.setNodes}
                edges={props.edges}
                setEdges={props.setEdges}
                lastExportEdges={props.lastExportEdges}
                lastExportNodes={props.lastExportNodes}
                setLastExportEdges={props.setLastExportEdges}
                setLastExportNodes={props.setLastExportNodes}
              />
            }
          </div>
        </div>
        {props.page !== 'home' && (
          <div className="centerNav">
            <p className='text-title' title={props.flowName}>{props.flowName}</p>
          </div>
        )}
        <div className="rightnav">
          <Account />
        </div>
      </div>
    </>
  );
}

export default Header;
