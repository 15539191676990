import React, { useState, useEffect, useRef } from "react";
import { IoMdChatboxes } from "react-icons/io";
import { FaTrash } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { BiTransfer } from "react-icons/bi";
import "./TextResponseElementInfo.css";
import OnDeleteElementPopUp from "../../StateDropdownComponent/OnDeleteElementPopUp";

function TextResponseElementInfo({
  id,
  top,
  left,
  right,
  bottom,
  isEditable,
  ...props
}) {
  const [textResponses, setTextResponses] = useState([...props.textArray]); // textResponses is an array of objects that contain the text to be displayed (array of text responses)
  const [elementId, setElementId] = useState(props.elementId);
  const [elementType, setElementType] = useState(props.elementType);
  const [elementTitle, setElementTitle] = useState(props.elementTitle);
  const [isChecked, setChecked] = useState(props.feedback);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  // Update text responses and title when props change
  useEffect(() => {
    setTextResponses([...props.textArray]);
    setChecked(props.feedback);
    setElementTitle((prevTitle) => {
      if (prevTitle !== props.elementTitle) {
        return props.elementTitle;
      }
      return prevTitle;
    });
  }, [props.textArray, props.elementTitle]);

  const handleCheckboxChange = () => {
    setChecked(!isChecked); // Toggle the checkbox status
    props.feedbackChange(!isChecked);
  };

  const handleOptionTextChange = (index, newText) => {
    props.setIsTyping(true);
    setTextResponses((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = { ...updatedOptions[index], text: newText };
      props.onOptionTextChange(updatedOptions); // Use updatedOptions here

      return updatedOptions;
    });
  };

  const handleTitleTextChange = (newText) => {
    props.setIsTyping(true);
    setElementTitle(newText);
    props.onTitleTextChange(newText);
  };

  const onClose = () => {
    setShowDeletePopUp(!showDeletePopUp);
  };

  const handleDeleteElement = () => {
    setShowDeletePopUp(!showDeletePopUp);
  };

  //function to delete the node
  const deleteElement = () => {
    // Call the onDeleteElement function from props
    props.onDeleteElement(props.elementId);
  }

  // Json mapping for text response
  const handleConvertToJSON = () => {
    props.onConvertToJSON();
  };

  const renderOptions = () => {
    return textResponses.map((option, index) => (
      <div
        key={option.id}
        style={{ marginBottom: "10px" }}
        className="TROptionContent"
      >
        <div className="TROptionTopContent">
          <textarea
            type="text"
            value={option.text}
            onChange={(e) => handleOptionTextChange(index, e.target.value)}
            className="TROptionText"
          />
        </div>
      </div>
    ));
  };

  function handleContainerClick() {
    if (props.isTyping) {
      props.addNewPastElement();
      props.setIsTyping(false);
    }
  }

  return (
    <>
      <div className="TRContainer">
        {!isEditable && (
          <div className="NotEditable" style={{ cursor: "not-allowed" }}>
            {" "}
          </div>
        )}
        <div className="TRContainerContent" onClick={handleContainerClick}>
          <div className="TRTopTitleSection">
            <p className="TRIcon">
              <IoMdChatboxes />
            </p>
            <p className="TRMainTitle">Text Response</p>
          </div>
          <p className="TRSectionTitle">General</p>
          <div className="TRInfoSectionContainer">
            <div className="TRInfoSection">
              <div className="TRTitle">
                Element ID:
                <div className="TRTitleText">{props.elementId}</div>
              </div>
              <div className="TRTitle">
                Element Type:
                <div className="TRTitleText">{props.elementType}</div>
              </div>
              <div className="TRTitle">
                Element Title (optional):
                <input
                  type="text"
                  value={elementTitle}
                  onChange={(e) => handleTitleTextChange(e.target.value)}
                  className="TRTitleInput"
                />
              </div>
            </div>
          </div>
          <p className="TRSectionTitle">Text Menu</p>

          <div className="TROptionSectionContainer">
            <div className="TROptionSection">
              <div className="Feedback_Container">
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <p>Enable customer feedback?</p>
              </div>
              {renderOptions()}
            </div>
          </div>
          <p className="TRSectionTitle">Manage Element</p>
          <div className="TRDeleteAndJSONContainer">
            <div className="TRDeleteAndJSON">
              <button onClick={handleDeleteElement} className="TRDeleteButton">
                <FaTrash /> Delete Element
              </button>
            </div>
          </div>
        </div>
      </div>
      <OnDeleteElementPopUp show={showDeletePopUp} elementId={elementId} deleteElement={deleteElement} onClose={onClose}></OnDeleteElementPopUp>
    </>
  );
}

export default TextResponseElementInfo;
