import React, { useState, useEffect } from 'react';
import Canvas from './Canvas';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ListPage from './ListPage';
import Header from './Components/HeaderComponent/Header';
import axios from 'axios';
import { store } from './Redux/store.js'
import { Provider } from 'react-redux'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

function AppContent({ page }) {
  const [id, setId] = useState("");
  const [flowName, setFlowName] = useState("");
  const [conversationType, setConversationType] = useState("");
  const [intentName, setIntentName] = useState("");
  const [score, setScore] = useState(0.4);
  const [entities, setEntities] = useState("");
  const [conversationState, setConversationState] = useState("");
  const [veevaVaultId, setVeevaVaultId] = useState("");
  const [product, setProduct] = useState("");
  const [savedVersion, setSavedVersion] = useState("");
  const [projectType, setProjectType] = useState("");
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [lastExportNodes, setLastExportNodes] = useState([]);
  const [lastExportEdges, setLastExportEdges] = useState([]);
  const [isConversationWriter, setIsConversationWriter] = useState(false);
  const { instance, inProgress } = useMsal();

  const [userHaveAccess, setUserHaveAccess] = useState(false);


  useEffect(() => {
    if (inProgress === InteractionStatus.None) {
      const account = instance.getActiveAccount();
      if (!account) {
        instance.acquireTokenRedirect({
          ...loginRequest,
          account: account
        });
      }
    }
  }, [inProgress, instance]);

  const getAccountInformation = () => {
    const account = instance.getActiveAccount();
    if (!account) {
      instance.acquireTokenRedirect({
        ...loginRequest,
        account: account
      });
    } else if (account) {
      return account;
    }
  }

  useEffect(() => {
    const account = getAccountInformation();
    const roles = account?.idTokenClaims?.roles;
    if (roles === undefined) {
      setUserHaveAccess(false);
      window.location.href = "/requestAccess";
    }
    else {
      const hasWeaveRole = roles.includes("Weave");
      setUserHaveAccess(hasWeaveRole);
      if (!hasWeaveRole) {
        setUserHaveAccess(false);
        window.location.href = "/requestAccess";
      }
    }
  }, []);


  return (
    <>
      {userHaveAccess ?
        (
          <Provider store={store}>
            <div>
              <div>
                <header id='header'>
                  <Header
                    page={page}
                    id={id}
                    flowName={flowName}
                    conversationType={conversationType}
                    intentName={intentName}
                    score={score}
                    entities={entities}
                    conversationState={conversationState}
                    veevaVaultId={veevaVaultId}
                    product={product}
                    savedVersion={savedVersion}
                    projectType={projectType}
                    setId={setId}
                    setFlowName={setFlowName}
                    setConversationType={setConversationType}
                    setIntentName={setIntentName}
                    setScore={setScore}
                    setEntities={setEntities}
                    setConversationState={setConversationState}
                    setVeevaVaultId={setVeevaVaultId}
                    setProduct={setProduct}
                    setSavedVersion={setSavedVersion}
                    setProjectType={setProjectType}
                    nodes={nodes}
                    setNodes={setNodes}
                    edges={edges}
                    setEdges={setEdges}
                    instance={instance}
                    isConversationWriter={isConversationWriter}
                    setIsConversationWriter={setIsConversationWriter}
                    lastExportEdges={lastExportEdges}
                    lastExportNodes={lastExportNodes}
                    setLastExportEdges={setLastExportEdges}
                    setLastExportNodes={setLastExportNodes}
                  />
                </header>
                {page === 'home' &&
                  <ListPage
                    id={id}
                    flowName={flowName}
                    conversationType={conversationType}
                    intentName={intentName}
                    score={score}
                    entities={entities}
                    conversationState={conversationState}
                    veevaVaultId={veevaVaultId}
                    product={product}
                    savedVersion={savedVersion}
                    projectType={projectType}
                    setId={setId}
                    setFlowName={setFlowName}
                    setConversationType={setConversationType}
                    setIntentName={setIntentName}
                    setScore={setScore}
                    setEntities={setEntities}
                    setConversationState={setConversationState}
                    setVeevaVaultId={setVeevaVaultId}
                    setProduct={setProduct}
                    setSavedVersion={setSavedVersion}
                    setProjectType={setProjectType}
                    nodes={nodes}
                    setNodes={setNodes}
                    edges={edges}
                    setEdges={setEdges}
                    instance={instance}
                    getAccountInformation={getAccountInformation}
                    isConversationWriter={isConversationWriter}
                    setIsConversationWriter={setIsConversationWriter}
                  />
                }
                {page === 'canvas' && 
                  <Canvas
                    id={id}
                    flowName={flowName}
                    conversationType={conversationType}
                    intentName={intentName}
                    score={score}
                    entities={entities}
                    conversationState={conversationState}
                    veevaVaultId={veevaVaultId}
                    product={product}
                    savedVersion={savedVersion}
                    projectType={projectType}
                    setId={setId}
                    setFlowName={setFlowName}
                    setConversationType={setConversationType}
                    setIntentName={setIntentName}
                    setScore={setScore}
                    setEntities={setEntities}
                    setConversationState={setConversationState}
                    setVeevaVaultId={setVeevaVaultId}
                    setProduct={setProduct}
                    setSavedVersion={setSavedVersion}
                    setProjectType={setProjectType}
                    nodes={nodes}
                    setNodes={setNodes}
                    edges={edges}
                    setEdges={setEdges}
                    instance={instance}
                    isConversationWriter={isConversationWriter}
                    setIsConversationWriter={setIsConversationWriter}
                    lastExportEdges={lastExportEdges}
                    lastExportNodes={lastExportNodes}
                    setLastExportEdges={setLastExportEdges}
                    setLastExportNodes={setLastExportNodes}
                  />
                }
              </div>
            </div>
          </Provider>
        ) : (null)
      }
    </>
  );
}

function App({ page }) {
  const authRequest = {
    ...loginRequest
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <AppContent page={page} />
    </MsalAuthenticationTemplate>
  )
};

export default App;