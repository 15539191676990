import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios'; // Import Axios
import DataTable from 'react-data-table-component';
import FilterComponent from './Components/ListPageComponent/FilterComponent';
import { FaPlus } from "react-icons/fa";
import { RxCross1 } from "react-icons/rx";
import { IoMdArrowDropdown } from "react-icons/io";
import { FiClock } from "react-icons/fi";
import { IoDocumentsOutline } from "react-icons/io5";
import { BiConversation } from "react-icons/bi";
import { BiSolidEditAlt } from "react-icons/bi";
import { format } from 'date-fns';
import './ListPage.css';
import { v4 } from 'uuid';

const columns = [
  {
    name: 'Document',
    selector: row => <BiConversation />,
    maxWidth: 'fit-content',
    style: {
      display: 'flex',
      justifyContent: 'center',
    }
  },
  {
    name: 'Title',
    selector: row => row.nodes[0].data.flowName,
    cell: row => <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', margin: '0 16px' }}>
      <div>{row.nodes[0].data.flowName}</div>
      <button className='data_table_open_btn' onClick={() => onOpenConversation(row._id, row.nodes[0].data.projectType, row.nodes[0].data.product)}>Open</button>
    </div>,
    minWidth: '500px',
  },
  {
    name: 'Tactic',
    selector: row => row.nodes[0].data.tactic,
    minWidth: 'fit-content',
    style: {
      margin: '0 16px'
    }
  },
  {
    name: 'Class',
    selector: row => row.nodes[0].data.product,
    minWidth: 'fit-content'
  },
  {
    name: 'Group',
    selector: row => row.nodes[0].data.projectType,
    minWidth: 'fit-content'
  },
  {
    name: 'Authors',
    selector: row => row.nodes[0].data.conversationWriters,
    minWidth: '100px'
  },
  {
    name: 'Status',
    selector: row => row.nodes[0].data.conversationState,
    minWidth: '100px',
    cell: row => (
      <button
        style={{
          cursor: "default",
          backgroundColor: row.nodes[0].data.conversationState === 'DRAFT' ? 'lightgray' : '#FFDB58',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: "10px",
          color: "black",
          fontStyle: "italic",
          fontSize: "14px",
        }}
        disabled
      >
        {row.nodes[0].data.conversationState}
      </button>
    )
  },
  {
    name: 'Access',
    selector: row => <div><BiSolidEditAlt /></div>,
    maxWidth: 'fit-content',
    style: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
];

const customDataTableStyle = {
  rows: {
    style: {
      minHeight: '72px',
      fontSize: 400,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },
  columns: {
    style: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
    }
  },
  headCells: {
    style: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '24px',
      borderBottomWidth: '2px',
      borderBottomColor: '#8553F1',
      borderBottomStyle: 'solid',
      padding: 'none'
    }
  }
}


const onOpenConversation = (id, userType, product) => {
  sessionStorage.setItem('Last Opened Conversation:', id);
  sessionStorage.setItem('Last Opened Collection:', userType === "testProjectType" ? "testProjectType_Weave" : userType.toLowerCase() + '_Weave');
  sessionStorage.setItem('Last Opened Database:', product === "testProduct" ? "testProduct_Weave" : product.toLowerCase() + '_Weave');
  window.location.href = `/canvas`;
}


function ListPage(props) {
  const [pageData, setPageData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [displayedData, setDisplayedData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [documentOptions, setDocumentOptions] = useState(['Conversation']);
  const [tacticOptions, setTacticOptions] = useState(['Brand']);
  const [classOptions, setClassOptions] = useState(['Verzenio', 'testProduct', 'uxteam']);
  const [groupOptions, setGroupOptions] = useState(['Consumer', 'testProjectType', 'review']);
  const [categoryOptions, setCategoryOptions] = useState(['Medical', 'Non-Medical', 'Generic']);
  const [activeSidebarOption, setActiveSidebarOption] = useState(0);
  const [myDocument, setMyDocument] = useState('');
  const [myTactic, setMyTactic] = useState('');
  const [myClass, setMyClass] = useState('');
  const [myGroup, setMyGroup] = useState('');
  const [myCategory, setMyCategory] = useState('');
  const [myFlowName, setMyFlowName] = useState('');
  const [currentModifiedDate, setCurrentModifiedDate] = useState('');

  const udpateModifiedDate = () => {
    const now = new Date();
    const formattedModifiedDate = format(now, 'MMM-dd yyyy h:mm a')
    setCurrentModifiedDate(formattedModifiedDate);
    console.log(currentModifiedDate);
  }

  // have different handles for each field in the pop up
  const handleDocumentChange = (newText) => {
    setMyDocument(newText);
  }

  const handleTacticChange = (newText) => {
    setMyTactic(newText);
  }

  const handleClassChange = (newText) => {
    setMyClass(newText);
  }

  const handleGroupChange = (newText) => {
    setMyGroup(newText);
  }

  const handleCategoryChange = (newText) => {
    setMyCategory(newText);
  }

  const handleFlowName = (newText) => {
    setMyFlowName(newText);
  }

  useEffect(() => {
    const account = props.getAccountInformation();
    const projectArray = [];
    let count = 0;

    account.idTokenClaims.roles.forEach(role => {
      count++;
      const isProject = role.split('_')[2] === 'Weave';
      if (isProject) {
        const product = role.split('_')[0];
        const projectType = role.split('_')[1];
        const projectName = product + "_" + projectType;
        projectArray.push(projectName);
        if (count === account.idTokenClaims.roles.length) {
          setProjects([...projects, ...projectArray]);
        }
      } else if (count === account.idTokenClaims.roles.length) {
        setProjects([...projects, ...projectArray]);
      }
    });
  }, []);

  useEffect(() => {
    if (projects.length === 0) {
      return;
    } else {
      let pageDataArray = [];
      let count = 0;
      projects.forEach(project => {
        count++;

        const database = project.split('_')[0] + '_Weave';
        const collection = project.split('_')[1] + '_Weave';

        axios.get(`/api/data/collection`, {
          params: {
            database: database,
            collection: collection,
          },
        })
          .then(response => {
            console.log('response.data:', response.data); // Debug line
            if (response.data && typeof response.data.body === 'string') {
              // Parse the body property to convert it into an array
              const data = JSON.parse(response.data.body);
              if (Array.isArray(data)) {
                // If the parsed data is an array, we can safely update the state
                pageDataArray = pageDataArray.concat(data);
                if (count === projects.length) {
                  setPageData(pageDataArray);
                  setDisplayedData(pageDataArray);
                  setIsLoading(false);
                  sessionStorage.setItem('collectionData', JSON.stringify(pageDataArray)); //pageDataArray:
                }
              } else {
                console.error('Expected an array but received:', data);
              }
            } else {
              console.error('Expected a stringified array but received:', response.data);
            }
          })
          .catch(error => console.error('Error fetching data:', error));
      });
    }
  }, [projects]);

  const onFilter = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setFilterText(value);
    // Filtering
    if (!pageData) {
      return;
    }
    const filteredItems = pageData.filter(
      item => {
        // Define a list of property names
        const propertyNames = ['flowName', 'product', 'conversationWriters', 'projectType', 'conversationType','conversationState', 'tactic', '_id', 'status'];
        const obj = item.nodes[0].data;
        // Checks an object's properties against a list of property names
        for (let i = 0; i < propertyNames.length; i++) {
          const propertyName = propertyNames[i];
          if (obj.hasOwnProperty(propertyName)) {
            if (checkData(obj[propertyName], value)) {
              return true;
            }
          }
        }
        return false;
      }
    );
    setDisplayedData(filteredItems);
  }

  const checkData = (data, value) => {
    const isString = typeof data === 'string';
    const isArray = Array.isArray(data)

    if (isString) {
      if (data.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }
    } else if (isArray) {
      if (data.length > 0) {
        if (data[0].toLowerCase().includes(value.toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  }

  const onClear = (e) => {
    e.preventDefault();
    setFilterText('');
    setDisplayedData(pageData);
  }

  const onShowModal = () => {
    setShowModal(true);
  }

  const onModalClose = () => {
    setShowModal(false);
  }

  const onConfirmModal = useCallback(() => {
    setShowModal(false);

    const id = v4();
    const intentName = "";
    const score = 0.4;
    const entities = "";
    const veevaVaultId = "";
    const savedVersion = "Version 1.0";

    const flowName = myFlowName;
    const conversationType = myDocument;
    const productType = myClass === "testProduct" ? myClass : myClass.toLowerCase();
    const product = `${productType}_Weave`;
    const userType = myGroup === "testProjectType" ? myGroup : myGroup.toLowerCase();
    const projectType = `${userType}_Weave`;

    // Add the values that are missing in the columns of the Table design to the initialNodes (tactic, documentType)
    const initialNodes = [
      {
        id: "ROOT",
        data: {
          label: "Start Node",
          _id: id,
          flowName: flowName, // title
          conversationType: myDocument, // document
          intentName: intentName,
          score: score,
          entities: entities,
          veevaVaultId: veevaVaultId,
          product: myClass, // class
          savedVersion: savedVersion,
          tactic: myTactic, // tactic
          projectType: myGroup, // group
          conversationState: "DRAFT",
          conversationWriters: [], // Authors
          conversationReviewers: [],
          conversationApprovers: [],
          stateMessage: "",
        },
        position: { x: 250, y: 16 }, // Centered horizontally, 16px from the top
        className: "StartNode",
        type: "startNode",
        draggable: false,
      },
    ];

    const conversationData = {
      _id: id,
      nodes: initialNodes,
      edges: [],
      viewport: {
        x: 0,
        y: 0,
        zoom: 1
      },
    };

    const database = product;
    const collection = projectType;
    axios
      .post('/api/data', conversationData, {
        params: {
          database: database,
          collection: collection,
          postType: "save",
        },
      })
      .then((res) => {
        sessionStorage.setItem('Last Opened Conversation:', id);
        sessionStorage.setItem('Last Opened Collection:', projectType);
        sessionStorage.setItem('Last Opened Database:', product);
        window.location.href = `/canvas`;
      })
      .catch((err) => {
        console.log(err);
      });
  });

  const validationFields = [myDocument, myTactic, myClass, myGroup, myCategory, myFlowName];
  const complementaryFieldNames = ['Document Type', 'Tactic', 'Class', 'Group', 'Category', 'Title'];
  const errorFields = [];
  const mappedFields = [];

  const validateForm = () => {
    for (const [name, value] of Object.entries(validationFields)) {
      if (!value.trim()) {
        errorFields.push(name);
      }
    }
    if (errorFields.length === 0) {
      return true;
    } else {
      return errorFields;
    }
  };

  const handleSubmit = () => {
    let isFormValid = validateForm();
    if (isFormValid === true) {
      onConfirmModal();
      udpateModifiedDate();
    }
    else {
      for (let i = 0; i < isFormValid.length; i++) {
        const index = isFormValid[i];
        if (index >= 0 && index < complementaryFieldNames.length) {
          mappedFields.push(complementaryFieldNames[index])
        } else {
          mappedFields.push('Index out of bounds')
        }
      }
      let alertMessage = `Please fill in the following fields: ${mappedFields.join(', ')}`;
      alert(alertMessage);
    }
  };

  return (
    <>
      <h2>Conversation List</h2>
      <div>
        {isLoading && 'loading...'}
        {!isLoading &&
          <>
            <button type="button" className='sidebarCreateButton' onClick={onShowModal}>
              <FaPlus id='createButtonPlusIcon' />
              Create
            </button>
            {showModal && (
              <div className="convo_bkg">
                <div className="createConversationPopUp">
                  <div id='titleSection' className="titleSection">
                    <div>
                      <FaPlus id='titlePlusIcon' className='plusIcon' />
                      <p>Create New Document</p>
                    </div>
                    <button type="button" id="cancelButton" onClick={onModalClose}>
                      <RxCross1 id='cancelButtonIcon' className='plusIcon' />
                    </button>
                  </div>

                  <div id='docTypeContainer' className="fields">
                    <label>Document Type:</label>
                    <select value={props.documentOptions} onChange={(e) => handleDocumentChange(e.target.value)} required>
                      <option value="" disabled selected>Select Document Type</option>
                      {documentOptions.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                    <IoMdArrowDropdown className="chevron" />
                  </div>
                  <div id='tacticContainer' className="fields">
                    <label>Tactic:</label>
                    <select value={props.tacticOptions} onChange={(e) => handleTacticChange(e.target.value)} required>
                      <option value="" disabled selected>Select Document Tactic</option>
                      {tacticOptions.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                    <IoMdArrowDropdown className="chevron" />
                  </div>
                  <div id='classContainer' className="fields">
                    <label>Class:</label>
                    <select value={props.classOptions} onChange={(e) => handleClassChange(e.target.value)} required>
                      <option value="" disabled selected>Select Document Class</option>
                      {classOptions.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                    <IoMdArrowDropdown className="chevron" />
                  </div>
                  <div id='groupContainer' className="fields">
                    <label>Group:</label>
                    <select value={props.groupOptions} onChange={(e) => handleGroupChange(e.target.value)} required>
                      <option value="" disabled selected>Select Document Group</option>
                      {groupOptions.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                    <IoMdArrowDropdown className="chevron" />
                  </div>
                  <div id='categoryContainer' className="fields">
                    <label>Category:</label>
                    <select value={props.categoryOptions} onChange={(e) => handleCategoryChange(e.target.value)} required>
                      <option value="" disabled selected>Select Document Category</option>
                      {categoryOptions.map((product, index) => (
                        <option key={index} value={product}>
                          {product}
                        </option>
                      ))}
                    </select>
                    <IoMdArrowDropdown className="chevron" />
                  </div>

                  <div id='titleContainer' className='fields'>
                    <label>Title:</label>
                    <input type="text" placeholder='Enter Document Title' onChange={(e) => handleFlowName(e.target.value)} required />
                  </div>
                  <div id='vaultIDContainer' className='fields'>
                    <label>Vault ID:</label>
                    <input type="text" id="vaultID" placeholder='Enter Veeva Vault ID (optional)' />
                  </div>

                  <button type="submit" id='createButton' onClick={handleSubmit}>
                    <FaPlus id='createButtonPlusIcon' />
                    Create
                  </button>

                  <div className='bottomBar'>
                    <div className='bottomBarPurpleSection'></div>
                  </div>
                </div>
              </div>
            )}
            <DataTable
              className='tableContainer'
              customStyles={customDataTableStyle}
              columns={columns}
              data={displayedData}
              pagination
              subHeader
              highlightOnHover
              highlightOnHoverStyle={{
                backgroundColor: '#EEEEEE',
                cursor: 'pointer'
              }}
              subHeaderComponent={
                <FilterComponent
                  filterText={filterText}
                  onFilter={onFilter}
                  onClear={onClear}
                  conversationData={props.conversationData}
                />
              }
            />
          </>
        }
      </div>
    </>
  );
}

export default ListPage;