import React, { useState } from 'react';
import './CancelReviewPopUp.css';
import { RiAddLine } from 'react-icons/ri';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { GrClose } from 'react-icons/gr';
import { AiFillCaretDown } from 'react-icons/ai';

function CancelReviewPopUp(props) {
    return (
        <div className="cancelReview-custom-modal">
            <div className='cancelReview-custom-modal-content' style={{ maxHeight: '80vh' }}>
                <div className='cancelReview-btnDiv'>
                    <button className='cancelReview-close-button' onClick={props.onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className='cancelReview-custom-modal-header'>
                    <h3>
                        Are you sure you want to <strong>cancel this review?</strong>
                    </h3>
                </div>
                <div className='cancelReview-custom-modal-body'>
                    <p>
                        Once you have cancelled a review, the conversation will be unlocked and further changes can be made. A new request for review will have to be made once the changes are complete.
                    </p>
                    <div className='cancelReview-actionBtns'>
                    <button className={`cancelReview-button return`} onClick={props.onClose}>
                        <IoIosArrowRoundBack size={25} /> Return to Conversation
                    </button>
                    <button
                        className="cancelReview-button cancel"
                        onClick={props.onCancelReview}
                    >
                        <GrClose size={22} /> Cancel Review
                    </button>
                </div>
                </div>
            </div>
        </div>
    );
}

export default CancelReviewPopUp;