import React, { memo, useRef, useEffect, useLayoutEffect, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { BsPersonFill } from 'react-icons/bs';
import { BiSolidCommentError } from "react-icons/bi";
import './SuggestedActions.css';
import "../../../index.css";
import { useDispatch } from 'react-redux'
import {showPopUp} from '../../../Redux/slicer/deleteNodeSlicer';
import { MdDeleteOutline } from "react-icons/md";

const useInternalNodes = (suggestedActions, nodeData) => {
  const [nodeHeights, setNodeHeights] = useState([]);
  const prevData = useRef({ suggestedActions, nodeData });

  const handleRef = useRef([]);

  // useLayoutEffect for synchronous layout measurement
  useLayoutEffect(() => {
    const heights = handleRef.current.map((nodeRef) => nodeRef?.offsetHeight || 0);
    setNodeHeights(heights);
  }, [suggestedActions]);

  useEffect(() => {
    if (
      suggestedActions !== prevData.current.suggestedActions ||
      nodeData !== prevData.current.nodeData
    ) {
      // Update previous data
      prevData.current = { suggestedActions, nodeData };
    }
  }, [suggestedActions, nodeData]);

  const internalNodes = suggestedActions.map((node, index) => {
    const precedingHeights = nodeHeights.slice(0, index);
    const precedingHeightSum = precedingHeights.reduce((sum, height) => sum + height, 0);

    const top = 49.5 + precedingHeightSum + nodeHeights[index] +
      ((index + 1) * 8) - (nodeHeights[index] / 2);

    const nodeKey = `${node.type}_${nodeData.elementId}-${index}`;
    const leftHandleId = `${node.type}_${nodeData.elementId}-${index}-L`;
    const rightHandleId = `${node.type}_${nodeData.elementId}-${index}-R`;

    return (
      <div
        key={nodeKey}
        ref={(ref) => (handleRef.current[index] = ref)}
        style={{
          background: '#F5F5F5',
          border: '1px solid #9E9E9E',
          borderRadius: '4px',
          padding: '4px',
          marginTop: '8px',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          justifyContent: 'center',
        }}
      >
        <div>
          <Handle
            type="source"
            position={Position.Left}
            id={leftHandleId}
            style={{
              background: '#F1F1F1',
              border: '1px solid #00A1DE',
              borderRadius: '5px',
              width: '10px',
              height: '10px',
              top: `${top}px`,
              left: '6px',
            }}
          />
          <div style={{ color: '#212121' }}>
            <div style={{ color: '#212121', minHeight: '26px' }}>{node.text || ``}</div>
          </div>
          <Handle
            type="source"
            position={Position.Right}
            id={rightHandleId}
            style={{
              background: '#F1F1F1',
              border: '1px solid #00A1DE',
              borderRadius: '5px',
              width: '10px',
              height: '10px',
              top: `${top}px`,
              right: '6px',
            }}
          />
        </div>
      </div>
    );
  });

  return internalNodes;
};

const SuggestedActions = ({ data, isConnectable, onNodeSelect }) => {

  const [enableDelete, setEnableDelete] = useState(false);
  const dispatch = useDispatch();
  
  const nodeStyle = {
    textAlign: "center",
    background: "#FF9352",
    padding: "10px",
    width: "400px",
    height: "fit-content",
    borderRadius: "6px",
    border: data?.boxShadow ? "2px solid darkgrey" : "none", // Remove the errorStatus from here
    boxShadow: data?.boxShadow ? "0px 0px 20px 10px grey" : "none", // Light grey shadow for active node
  };

  const toggleHover = () => {
    setEnableDelete(prevState => !prevState);
  }

  //function to delete the node
  const deleteElement = () => {
    dispatch(showPopUp({show: true, nodeID: data.elementId}));
  }

  return (
    <div style={nodeStyle} onMouseEnter={toggleHover} onMouseLeave={toggleHover} className={data?.boxShadow ? "activeNodeBorder" : ""} >
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#D563B9', width: '10px', height: '10px' }}
        isConnectable={isConnectable}
        id={`${data.elementId}-top`}
      />
      {data.errorStatus && (
        <div className="errorContainer">
          <BiSolidCommentError className="errorIcon" />
          <span className="errorMessage">{data.errorMessage}</span>
        </div>
      )}
      <div>
        {/* Position elementTitle at the top-left */}
        <div style={{ position: 'absolute', top: -22, left: 6, color: 'black', fontSize: '16px' }}>
          {data.elementTitle}
        </div>
        <BsPersonFill size={40} style={{ color: 'white' }} />
        {useInternalNodes(data.suggestedActions, data)}
      </div>
      {enableDelete && (
        <button className="display-on-hover" onClick={deleteElement}>
         <MdDeleteOutline />
        </button>
      )}
    </div>
  );
};

export default SuggestedActions;