import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function DeleteCoversationPopUp({ show, onClose, onDeleteCoversation }) {
  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Conversation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this conversation? This action is irreversible.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose(false)}>
          Cancel
        </Button>
        <Button variant="danger" onClick={onDeleteCoversation}> {/* Call handleConfirmDelete here */}
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteCoversationPopUp;
