import React, { useState } from 'react';
import './CompleteReviewPopUp.css';
import { RiAddLine } from 'react-icons/ri';
import { IoIosArrowRoundBack } from 'react-icons/io';
import { GrClose } from 'react-icons/gr';
import { AiFillCaretDown } from 'react-icons/ai';
import { AiOutlineClose } from 'react-icons/ai';
import { HiCheck} from 'react-icons/hi';

function CompleteReviewPopUp(props) {
    const [continueEditing, setContinueEditing] = useState(false);
    const [readyToApprove, setReadyToApprove] = useState(false);
    const maxCharacterCount = 1000;

    const handleTextChange = (event) => {
        const inputText = event.target.value;

        if (inputText.length <= maxCharacterCount) {
            props.setStateMessage(inputText);
        }
    };

    const handleContinueEditing = () => {
        setContinueEditing(true);
    }

    const handleReadyToApprove = () => {
        setReadyToApprove(true);
    }


    const handleGoBackContinueEditing = () => {
        setContinueEditing(false);
    }

    const handleGoBackReadyToApprove = () => {
        setReadyToApprove(false);
    }
    
    return (
        <div className="CRPopUpContainer">
            <div className='CRPopUpContainer-Content'>
                <div className='CRCloseButtonContainer'>
                    <button className='CRClosebutton' onClick={props.onClose}>
                        <GrClose size={25} />
                    </button>
                </div>
                <div className='CRPopUpContainer-Header'>
                    <p className="CRHeaderText">
                        Provide your decision to <strong>complete this Review</strong>.
                    </p>
                </div>
                <div className='CRPopUpContainer-Body'>
                    <p className="CRBodyText">
                        Enter any desired feedback, then complete the review by confirming if the conversation
                        needs additional work or is ready for approval. Once the conversation receives unanimous
                        reviews to move to approval, it will be locked and moved to <strong>In Approval </strong>
                        status, then it can be exported to start the external review process.
                    </p>
                    <div className="yourReviewContainer">
                        <p className="yourReviewTitle">
                            <strong>Your Review</strong> (optional)
                        </p>
                        <div className="reviewTextAndCharacterContainer">
                            <p className="yourReviewText">
                                Provide feedback that will be visible to the Author of the conversation.
                            </p>
                            <div id="characterCount" className="characterCountBox">
                                {props.stateMessage.length}/{maxCharacterCount}
                            </div>
                        </div>
                        <textarea
                            id="yourReviewTextArea"
                            className="yourReviewTextArea"
                            value={props.stateMessage}
                            onChange={handleTextChange}
                            maxLength={maxCharacterCount}
                            style={{ resize: 'none', height: '200px' }}
                            placeholder="About this conversation..."
                        />
                        <button className={`CRButton CRButtonReturn`} onClick={props.onClose}>
                            <IoIosArrowRoundBack size={25} /> Return to Conversation
                        </button>
                        <div className='continueReadyButtonsContainer'>
                            <button className={`CRButton CRButtonContinue`} onClick={handleContinueEditing}>
                                <AiOutlineClose size={25} /> Continue Editing
                            </button>
                            <button className={`CRButton CRButtonReady`} onClick={handleReadyToApprove}>
                                <HiCheck size={25} /> Ready to Approve
                            </button>
                        </div>
                        {continueEditing && (
                            <div className='backConfirmButtonsContainer'>
                                <button className={`CRButton CRButtonBack`} onClick={handleGoBackContinueEditing}>
                                    Go Back
                                </button>
                                <button className={`CRButton CRButtonConfirm`} onClick={props.confirmCompleteReviewEdit}>
                                    Confirm
                                </button>
                            </div>
                        )}
                        {readyToApprove && (
                        <div className='backConfirmButtonsContainer'>
                            <button className={`CRButton CRButtonBack`} onClick={handleGoBackReadyToApprove}>
                                Go Back
                            </button>
                            <button className={`CRButton CRButtonConfirm`} onClick={props.confirmCompleteReviewReady}>
                                Confirm
                            </button>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CompleteReviewPopUp;