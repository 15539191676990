
// This slicer is not being used anywhere


import { createSlice } from '@reduxjs/toolkit'


export const isNodeDraggableSlice = createSlice({
  name: 'isNodeDraggable',
  initialState: {
    value: true,
  },
  reducers: {
    setIsNodeDraggable: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setIsNodeDraggable } = isNodeDraggableSlice.actions

export default isNodeDraggableSlice.reducer
