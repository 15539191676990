import React, { useState, useEffect, useCallback } from "react";
import { useReactFlow } from "reactflow";
import { MdPerson } from "react-icons/md";
import { FaTrash } from "react-icons/fa";
import { FaCirclePlus } from "react-icons/fa6";
import { BiTransfer } from "react-icons/bi";
import "./SuggestedActionElementInfo.css";
import { BsArrowUpSquareFill } from "react-icons/bs";
import { BsArrowDownSquareFill } from "react-icons/bs";
import OnDeleteElementPopUp from "../../StateDropdownComponent/OnDeleteElementPopUp";

function SuggestedActionElementInfo({
  id,
  top,
  left,
  right,
  bottom,
  isEditable,
  ...props
}) {
  const [options, setOptions] = useState([...props.suggestedActions]);
  const [elementId, setElementId] = useState(props.elementId);
  const [elementType, setElementType] = useState(props.elementType);
  const [elementTitle, setElementTitle] = useState(props.elementTitle);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);

  useEffect(() => {
    // Update options when props.suggestedActions changes
    setOptions([...props.suggestedActions]);

    // Update element title
    setElementTitle((prevTitle) => {
      if (prevTitle !== props.elementTitle) {
        return props.elementTitle;
      }
      return prevTitle;
    });

  }, [props.suggestedActions, props.elementTitle]);

  const handleOptionTextChange = (index, newText) => {
    props.setIsTyping(true);
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = { ...updatedOptions[index], text: newText };
      props.onOptionTextChange(updatedOptions); // Call the callback function
      return updatedOptions;
    });
  };

  const handleTitleTextChange = (newText) => {
    props.setIsTyping(true);
    setElementTitle(newText); // Updated to directly set the new text
    props.onTitleTextChange(newText);
  };

  const handleDeleteOption = (index) => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.splice(index, 1);
      props.onDeleteOption(index); // Call the onDeleteOption function
      return updatedOptions;
    });
  };

  const handleAddOption = () => {
    setOptions((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions.push({
        text: "New Suggested Action",
        type: "imBack",
        flowName: "",
      });
      props.onAddOption(); // Call the onAddOption function

      return updatedOptions;
    });
  };

  const onClose = () => {
    setShowDeletePopUp(!showDeletePopUp);
  };

  // function to show the confirmation pop-up when user click on delete element button from suggested actioin info panel
  const handleDeleteElement = () => {
    setShowDeletePopUp(!showDeletePopUp);
  };

  //function to delete the node
  const deleteElement =()=>{
    // Call the onDeleteElement function from props
    props.onDeleteElement(props.elementId);
  }

  const handleMoveOptionUp = (index) => {
    if (index > 0) {
      setOptions((prevOptions) => {
        // Create a deep copy of the prevOptions array
        const updatedOptions = JSON.parse(JSON.stringify(prevOptions));
        // Swap the current option with the one above it
        [updatedOptions[index], updatedOptions[index - 1]] = [
          updatedOptions[index - 1],
          updatedOptions[index],
        ];
        props.onMoveOption(index, index - 1); // Call the onMoveOption function
        return updatedOptions;
      });
    }
  };

  const handleMoveOptionDown = (index) => {
    if (index < options.length - 1) {
      setOptions((prevOptions) => {
        // Create a deep copy of the prevOptions array
        const updatedOptions = JSON.parse(JSON.stringify(prevOptions));
        // Swap the current option with the one below it
        [updatedOptions[index], updatedOptions[index + 1]] = [
          updatedOptions[index + 1],
          updatedOptions[index],
        ];
        props.onMoveOption(index, index + 1); // Call the onMoveOption function
        return updatedOptions;
      });
    }
  };

  const renderOptions = () => {
    return options.map((option, index) => (
      <div
        key={option.id}
        style={{ marginBottom: "10px" }}
        className="SAOptionContent"
      >
        <div className="SAOptionTopContent">
          <div className="SAOptionMoveUpAndDown">
            <button
              onClick={() => handleMoveOptionUp(index)}
              className="SAOptionMoveUp"
            >
              <BsArrowUpSquareFill />
            </button>
            <button
              onClick={() => handleMoveOptionDown(index)}
              className="SAOptionMoveDown"
            >
              <BsArrowDownSquareFill />
            </button>
          </div>
          <textarea
            type="text"
            value={option.text}
            onChange={(e) => handleOptionTextChange(index, e.target.value)}
            className="SAOptionText"
          />
          <button
            onClick={() => handleDeleteOption(index)}
            className="SAOptionDeleteButton"
          >
            <FaTrash />
          </button>
        </div>
      </div>
    ));
  };

  function handleContainerClick() {
    if (props.isTyping) {
      props.addNewPastElement();
      props.setIsTyping(false);
    }
  }

  return (
    <>
    <div className="SAContainer">
      {!isEditable && (
        <div className="NotEditable" style={{ cursor: "not-allowed" }}>
          {" "}
        </div>
      )}
      <div className="SAContainerContent" onClick={handleContainerClick}>
        <div className="SATopTitleSection">
          <p className="SAIcon">
            <MdPerson />
          </p>
          <p className="SAMainTitle">Suggested Actions</p>
        </div>
        <p className="SASectionTitle">General</p>
        <div className="SAInfoSectionContainer">
          <div className="SAInfoSection">
            <div className="SATitle">
              Element ID:
              <div className="SATitleText">{props.elementId}</div>
            </div>
            <div className="SATitle">
              Element Type:
              <div className="SATitleText">{props.elementType}</div>
            </div>
            <div className="SATitle">
              Element Title (optional):
              <input
                type="text"
                value={elementTitle}
                onChange={(e) => handleTitleTextChange(e.target.value)}
                className="SATitleInput"
              />
            </div>
          </div>
        </div>
        <p className="SASectionTitle">Action Menu</p>
        <div className="SAOptionSectionContainer">
          <div className="SAOptionSection">{renderOptions()}</div>
          <button onClick={handleAddOption} className="SAAddOptionButton">
            <FaCirclePlus /> Add Action
          </button>
        </div>
        <p className="SASectionTitle">Manage Element</p>
        <div className="SADeleteAndJSONContainer">
          <div className="SADeleteAndJSON">
            <button onClick={handleDeleteElement} className="SADeleteButton">
              <FaTrash /> Delete Element
            </button>
          </div>
        </div>
      </div>
    </div>
    <OnDeleteElementPopUp show={showDeletePopUp} elementId={elementId} deleteElement={deleteElement} onClose={onClose}></OnDeleteElementPopUp>
    </>
  );
}

export default SuggestedActionElementInfo;
