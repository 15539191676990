import React, { useState, useEffect } from "react";
import { FaTrash } from "react-icons/fa";
import TExitIcon from "../../../Assets/Img/to_Conversation.png"
import "./ExitNodeElementInfo.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { TbMapPinShare } from "react-icons/tb";
import OnDeleteElementPopUp from "../../StateDropdownComponent/OnDeleteElementPopUp";
import axios from 'axios';
import { useMsal } from "@azure/msal-react";

function ExitNodeElementInfo({ id, top, left, right, bottom, isEditable, ...props }) {
    const [elementId, setElementId] = useState(props.elementId);
    const [elementType, setElementType] = useState(props.elementType);
    const [elementTitle, setElementTitle] = useState(props.elementTitle);
    const [externalConversation, setExternalConversation] = useState(props.externalConversation);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [options, setOptions] = useState([]);
    const { instance, inProgress } = useMsal();
    const account = instance.getActiveAccount();

    let flowNames = []
   
      useEffect(() => {
          let pageDataArray = [];
          const collection = sessionStorage.getItem("Last Opened Collection:");
          const database = sessionStorage.getItem("Last Opened Database:");

            axios.get(`/api/data/collection`, {
              params: {
                database: database,
                collection: collection,
              },
            })
              .then(response => {
                if (response.data && typeof response.data.body === 'string') {
                  // Parse the body property to convert it into an array
                  const data = JSON.parse(response.data.body);
                  if (Array.isArray(data)) {   
                    pageDataArray = pageDataArray.concat(data);
                    pageDataArray.map(dataObject=> flowNames.push(dataObject.nodes[0].data.flowName))
                    setOptions(flowNames);
                   
                  } else {
                    console.error('Expected an array but received:', data);
                  }
                } else {
                  console.error('Expected a stringified array but received:', response.data);
                }
              })
              .catch(error => console.error('Error fetching data:', error));
      }, []);
    
    const [error, setError] = useState(false); // New state for error tracking
    useEffect(() => {
        if (!props.externalConversation) {
            setError(true);  // Set error if external conversation is missing
        } else {
            setError(false);
        }
        setExternalConversation(props.externalConversation);
    }, [props.externalConversation]);

    const handleExternalConversationChange = (newText) => {
        setExternalConversation(newText);
        setError(!newText); // Update error based on whether a conversation is selected
        props.onExternalConversationChange(newText);
    }

    useEffect(() => {
        setExternalConversation(props.externalConversation);
    }, [props.externalConversation]);

    const onClose = () => {
        setShowDeletePopUp(!showDeletePopUp);
      };

    const handleDeleteElement = () => {
        setShowDeletePopUp(!showDeletePopUp);
    };

     //function to delete the node
  const deleteElement =()=>{
    // Call the onDeleteElement function from props
    props.onDeleteElement(props.elementId);
    setShowDeletePopUp(!showDeletePopUp);
  }


    return (
        <>
        <div className="ENContainer">
        {error && (
                <div className="error-message">Please select an external conversation.</div>
            )}
        {!isEditable && (
          <div className="NotEditable" style={{ cursor: "not-allowed" }}>
            {" "}
          </div>
        )}
            <div className="ENContainerContent">
                <div className="ENTopTitleSection">
                    <p className="ENIcon">
                        <TbMapPinShare />
                    </p>
                    <p className="ENMainTitle">External Conversation</p>
                </div>
                <p className="ENSectionTitle">General</p>
                <div className="ENInfoSectionContainer">
                    <div className="ENInfoSection">
                        <div className="ENTitle">
                            Element ID:
                            <div className="ENTitleText">{props.elementId}</div>
                        </div>
                        <div className="ENTitle">
                            Element Type:
                            <div className="ENTitleText">{props.elementType}</div>
                        </div>
                        <div className="ENTitle">
                            Element Title (conversation name):
                            <div className="ENTitleText">{externalConversation}</div>
                        </div>
                    </div>
                </div>
                <p className="ENSectionTitle">Conversation</p>
                <div className="ENConversationSectionContainer">
                    <div className="ENConversationSection">
                        <div className="ENSelectTitle">
                            External Conversation Flow:
                            <select className="ENTitleInput" value={externalConversation} onChange={(e) => handleExternalConversationChange(e.target.value)}>                                
                            <option value="" disabled hidden>Select External Conversation</option>
                                {options.map((externalConversation, index) => (
                                    <option key={index} value={externalConversation}>
                                        {externalConversation}
                                    </option>
                                ))}
                            </select>
                            <IoMdArrowDropdown className="ENChevron" />
                        </div>
                    </div>
                </div>
                <p className="ENSectionTitle">Manage Element</p>
                <div className="ENDeleteAndJSONContainer">
                    <div className="ENDeleteAndJSON">
                        <button onClick={handleDeleteElement} className="ENDeleteButton"><FaTrash /> Delete Element</button>
                    </div>
                </div>
            </div>
        </div>
        <OnDeleteElementPopUp show={showDeletePopUp} elementId={elementId} deleteElement={deleteElement} onClose={onClose}></OnDeleteElementPopUp>
        </>
    );
}

export default ExitNodeElementInfo;
